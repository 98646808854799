export const PLAN_TYPES = ["Mobile", "Mobile Broadband", "Enterprise Wireless"];

export const CATEGORY_NAMES = [
  "Handheld",
  "Mobile Broadband",
  "Enterprise Wireless",
];

export const CONFIGURE_BARRINGS_AND_ADDONS = {
  LIST_VIEW: {
    CONFIGURE_PLANS_BUTTON: "Configure all plans",
    DELETE_PLAN_BUTTON: "Delete Plan",
    DELETE_PLANS_BUTTON: "Delete Plans",
    SPLIT: "Split",
    ADDON_HEADER: "Add-ons",
    NETWORK_FEATURE_HEADER: "Network features",
    CONFIGURE_SECTION: {
      ADDONS: {
        HEADING: "Add-ons (optional)",
        SUBTEXT:
          "All plans include default add-ons as standard inclusions. More add-ons are available for an extra charge. Full details for add-ons view our ",
        ADDONS: "Addons",
        DESCRIPTION: "Description",
        CHARGE: "Charge",
      },
    },
  },
  SINGLE_QUANTITY_SPLIT_MODAL:
    "You need at least two of the same plans in order to split them.",
  SPLIT_MODAL: {
    TITLE: "Split plans for separate configurations",
    INFORMATION_MESSAGE_1: "You’ve selected ",
    INFORMATION_MESSAGE_2: " plans to split.",
    INFORMATION_MESSAGE_3:
      "Splitting plans means you can apply different service configurations (network features and add-ons) to each set.",
    SPLIT_SECTION_HEADING: "How do you want to split the plans?",
    NUMBER_OF_PLANS: "Number of plans in the ",
    SPLIT_NUMBERS: {
      0: "first",
      1: "second",
      2: "third",
      3: "fourth",
      4: "fifth",
    },
    SET: "set",
    ADD_SET_BTN: "Add another set",
    CANCEL_BTN: "Cancel",
    SPLIT_BTN: "Split plans",
    REMOVE_SET: "Remove",
    SPLIT_PLANS_INFORMATION_MSG:
      "You can split plans into a maximum of five sets.",
    MAX_SPLIT_WARNING_MSG: "Number exceeds the total plans to be split.",
    GREATER_THAN_ZERO_MSG: "Number must be at least 1.",
    SPLIT_MORE_THAN_SELECTED_PLANS_MSG:
      "Adjust the numbers in the split sets so they equal the total number of selected plans.",
  },
  INFORMATION_MESSAGE:
    "Once you've configured the plans with network features and add-ons, select 'Next' to configure numbers and SIMs.",
};

export const DELETE_PLAN_MODAL = {
  SINGLE_PLAN_TITLE: "Are you sure you want to delete this plan?",
  SINGLE_PLAN_DESCRIPTION: "This plan will be permanently deleted.",
  MULTIPLE_PLAN_TITLE: "Are you sure you want to delete these plans?",
  MULTIPLE_PLAN_DESCRIPTION: "These plans will be permanently deleted.",
  CONFIRM_BUTTON_LABEL: "Delete",
  CANCEL_BUTTON_LABEL: "Cancel",
};
