import "./style.scss";
import { Breadcrumbs } from "@able/react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { BREADCRUMB, PATHS } from "@utils/common-static-data";
import { ableDevelopmentUrl, buildPath } from "@utils/helper";
import { useAppSelector } from "@state/hooks";

export default function Breadcrumb() {
  const { cartId } = useParams();
  const { pathname } = useLocation();
  const isError = useAppSelector((state) => state.error.isError);
  const { normalizedPlansQBS, normalizedDevicesAndAccesories } = useAppSelector(
    (state) => state.cart
  );
  const [queryParams] = useSearchParams();
  const cidn = queryParams.get("cidn");
  const usertype = queryParams.get("usertype");
  const queryParamsSubPath = `?cidn=${cidn}&usertype=${usertype}`;
  const isPlansFlow = normalizedPlansQBS && normalizedPlansQBS.length > 0;
  const isDevicesFlow =
    normalizedDevicesAndAccesories && normalizedDevicesAndAccesories.length > 0;

  if (pathname.includes(PATHS.ORDER_CONFIRMATION) || isError) return <></>;

  const commonInitialBreadcrumbs = [
    { to: PATHS.LANDING_PAGE + queryParamsSubPath, label: BREADCRUMB.ACCOUNT }, // TODO change the url
    {
      to: PATHS.LANDING_PAGE + queryParamsSubPath,
      label: BREADCRUMB.LANDING_PAGE,
    },
    {
      to: PATHS.NEW_ORDER_DETAILS + queryParamsSubPath,
      label: BREADCRUMB.NEW_ORDER_DETAILS,
    },
  ];

  const commonFinalBreadcrumbs = [
    {
      to: buildPath(PATHS.CART, cartId, queryParamsSubPath),
      label: BREADCRUMB.CART_PAGE,
    },
    {
      to: buildPath(PATHS.CHECKOUT, cartId, queryParamsSubPath),
      label: BREADCRUMB.CHECKOUT,
    },
  ];

  const newDevicesBreadcrumbs = [
    ...commonInitialBreadcrumbs,
    {
      to: buildPath(PATHS.SELECT_DEVICES, cartId, queryParamsSubPath),
      label: BREADCRUMB.SELECT_DEVICES,
    },
    {
      to: buildPath(PATHS.CONFIGURE_DEVICES, cartId, queryParamsSubPath),
      label: BREADCRUMB.CONFIGURE_DEVICES,
    },

    ...commonFinalBreadcrumbs,
  ];

  const newPlanBreadcrumbs = [
    ...commonInitialBreadcrumbs,
    {
      to: buildPath(PATHS.SELECT_PLAN, cartId, queryParamsSubPath),
      label: BREADCRUMB.SELECT_PLAN,
    },
    {
      to: buildPath(PATHS.BARRING_ADDONS, cartId, queryParamsSubPath),
      label: BREADCRUMB.BARRING_ADDONS,
    },
    {
      to: buildPath(PATHS.CONFIGURE_SIM, cartId, queryParamsSubPath),
      label: BREADCRUMB.CONFIGURE_SIM,
    },

    ...commonFinalBreadcrumbs,
  ];

  const dynamicBreadcrumbs = [];
  let i = 0;
  let staticBreadcrumbs;

  if (isPlansFlow) {
    staticBreadcrumbs = newPlanBreadcrumbs;
  } else if (isDevicesFlow) {
    staticBreadcrumbs = newDevicesBreadcrumbs;
  } else staticBreadcrumbs = commonInitialBreadcrumbs;

  while (
    i < staticBreadcrumbs.length &&
    pathname + queryParamsSubPath !== staticBreadcrumbs[i].to
  ) {
    dynamicBreadcrumbs.push(staticBreadcrumbs[i]);
    i++;
  }

  return (
    <Breadcrumbs
      data-testid="breadcrumb"
      developmentUrl={ableDevelopmentUrl}
      breadcrumbs={dynamicBreadcrumbs}
      setBackgroundToWhite
      className={`no-animation ${pathname === "/" ? "landing-page-style" : ""}`}
    />
  );
}
