export const LANDING_PAGE = {
  HEADING: "Manage Adaptive Mobility",
  FIRST_TIME_USER: "First-time user? ",
  VIEW_VIDEOS_AND_TUTORIALS: "View our help videos/tutorials",
  ACTIONS: [
    {
      id: "orderNew",
      label: "Order new",
      pictoName: "Accessories",
      description: "Services, devices, accessories or blank SIMs",
      link: "/new-order-details",
    },
    {
      id: "manageAnExisting",
      label: "Manage existing",
      pictoName: "Settings",
      description:
        "Services, devices, accessories, managed services or reactivate cancelled services",
      link: "",
    },
    {
      id: "portIn",
      label: "Port in",
      pictoName: "PortSIM",
      description: "Services",
      link: "",
    },
    {
      id: "changeOfOwnership",
      label: "Change of ownership",
      pictoName: "TransferRequest",
      description: "Services and devices",
      link: "",
    },
    {
      id: "updateUsernames",
      label: "Update usernames",
      pictoName: "Profile",
      description: "Single and bulk usernames",
      link: "",
    },
  ],
};
