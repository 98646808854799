import { InlineLink, Picto, Search56, Spacing, TextStyle } from "@able/react";
import "./style.scss";
import { PATHS } from "@utils/common-static-data";
import { useCustomNavigate } from "@utils/helper";
import { TAB_LIST_EMPTY_MSG } from "./constant";
const EmptyListMessage = (props) => {
  const { category, cartId } = props;
  const navigate = useCustomNavigate();
  const goBack = () => {
    navigate(PATHS.SELECT_PLAN, cartId);
  };
  return (
    <>
      <Picto>
        <Search56 />
      </Picto>
      <TextStyle alias="HeadingD" element="h2">
        {TAB_LIST_EMPTY_MSG.PLAN_SELECTED.replace("{category}", category)}
      </TextStyle>
      <TextStyle alias="Base">{TAB_LIST_EMPTY_MSG.DESCRIPTION}</TextStyle>
      <Spacing top="spacing2x" bottom="spacing10x">
        <InlineLink>
          <TextStyle alias="Base" alignment="Left">
            <span
              onClick={goBack}
              className="no-msg-link-btn"
              data-testid="go-back-button"
            >
              {TAB_LIST_EMPTY_MSG.LINK_TEXT}
            </span>
          </TextStyle>
        </InlineLink>
      </Spacing>
    </>
  );
};

export default EmptyListMessage;
