import "./style.scss";
import { ActionButton, Divider, Spacing, TextStyle } from "@able/react";
import { IBarringAndAddons, IOffer, NormalizedPlanQBS } from "@state/types";
import { AddOnsSection } from "./add-ons";
import { useState } from "react";
import { useAppDispatch } from "@state/hooks";
import { updateCartAddOns } from "@state/cart-slice";

type Props = {
  plan: NormalizedPlanQBS;
  offer: IOffer;
  setConfigureSectionVisible: (a: boolean) => void;
};

export function ConfigureSection(props: Props) {
  const dispatch = useAppDispatch();

  const { plan, offer, setConfigureSectionVisible } = props;

  const [selectedAddons, setSelectedAddons] = useState<IBarringAndAddons>({
    ...plan.addOns,
  });

  const handleCancel = () => setConfigureSectionVisible(false);
  const handleConfirm = () => {
    dispatch(updateCartAddOns({ planId: plan.id, selectedAddons }));
    setConfigureSectionVisible(false);
  };

  return (
    <div id="configure-section">
      <Spacing
        bottom="spacing3x"
        left="spacing4x"
        top="spacing3x"
        className={["heading"]}
      >
        <TextStyle alias="HeadingD" element="h2">
          Configure Plans
        </TextStyle>
      </Spacing>
      <Divider />
      {offer && (
        <AddOnsSection
          offer={offer}
          selectedAddons={selectedAddons}
          setAddons={setSelectedAddons}
        />
      )}
      <Divider />
      <Spacing
        top="spacing4x"
        bottom="spacing4x"
        left="spacing4x"
        right="spacing4x"
        className={["button-section"]}
      >
        <ActionButton
          element="button"
          variant="MediumEmphasis"
          label="Cancel"
          events={{ onClick: handleCancel }}
        />
        <Spacing left="spacing3x" />
        <ActionButton
          element="button"
          variant="HighEmphasis"
          label="Confirm changes"
          events={{ onClick: handleConfirm }}
        />
      </Spacing>
    </div>
  );
}
