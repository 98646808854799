import { baseApiService } from "./base";
import {
  IOffersResponse,
  IOffer,
  DeviceOffersResponse,
  DeviceOffer,
} from "../state/types";
import { setError } from "@state/error";

export const catalogApiService = baseApiService.injectEndpoints({
  endpoints: (builder) => ({
    getOffers: builder.query<IOffer[], void>({
      query: () => "catalog-management/offers?offer=ENTERPRISE_MOBILITY",
      transformResponse(response: IOffersResponse) {
        return response.data.offers;
      },
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          dispatch(setError(true));
        }
      },
    }),
    getDeviceOffers: builder.query<DeviceOffer[], void>({
      query: () =>
        "catalog-management/offers?offer=DEVICES_ACCESSORIES&category=Mobile",
      transformResponse(response: DeviceOffersResponse) {
        return response.data.offers;
      },
    }),
  }),
});

export const { useGetOffersQuery, useGetDeviceOffersQuery } = catalogApiService;
