import { Column, Grid, MessageSection, Spacing } from "@able/react";
import { ColumnProps } from "@able/react/dist/Column";
import { MessageSectionProps } from "@able/react/dist/MessageSection";
import { ableDevelopmentUrl } from "@utils/helper";

export default function AutosaveMessage({
  columnProps = {},
  messageSectionProps,
}: {
  columnProps?: Omit<ColumnProps, "children">;
  messageSectionProps: Omit<MessageSectionProps, "developmentUrl">;
}) {
  return (
    <Grid>
      <Column {...columnProps}>
        <Spacing top="spacing3x" bottom="spacing3x">
          <MessageSection
            developmentUrl={ableDevelopmentUrl}
            {...messageSectionProps}
          />
        </Spacing>
      </Column>
    </Grid>
  );
}
