import QuantityButton from "../components/quantity-button";
import { AdvanceTableV3 } from "telstra-ui/components/table-advance-v3/AdvanceTableV3";
import {
  renderBoldText,
  prefixWithDollarSign,
  renderText,
} from "@utils/helper";
import { SELECT_PLAN } from "@pages/new-plan-order/constant";
import { IOffer } from "@state/types";
import { TextStyle } from "@able/react";
import InclusionsList from "../components/inclusions-list";
import "./style.scss";
type IRowData = {
  original: IOffer;
};
const CONSTANTS = SELECT_PLAN.TABLE_VIEW;
const columns = [
  {
    headerClassName: "table-align-left",
    className: "td-align-left ",
    Header: renderBoldText(CONSTANTS.PLAN_TYPE),
    resizable: false,
    width: 300,
    Cell: (rowData: IRowData) => {
      const offerName = rowData?.original?.offerName.split("-")[0];
      const planType = `${rowData?.original?.planType} ${rowData?.original?.plan}`;
      return (
        <div>
          <TextStyle alias="FinePrint" element="div">
            {offerName}
          </TextStyle>
          <TextStyle alias="HeadingD">{planType}</TextStyle>
        </div>
      );
    },
  },
  {
    headerClassName: "table-align-left",
    className: "td-align-left ",
    Header: renderBoldText(CONSTANTS.DATA_ALLOWANCE),
    resizable: false,
    width: 170,
    Cell: (rowData: IRowData) => {
      return (
        <TextStyle alias="Base" element="div">
          {rowData?.original?.dataAllowance}
        </TextStyle>
      );
    },
  },
  {
    headerClassName: "table-align-left",
    className: "td-align-right ",
    Header: renderBoldText(CONSTANTS.MONTHLY_CHARGES),
    resizable: true,
    width: 260,
    Cell: (rowData: IRowData) =>
      renderText(prefixWithDollarSign(rowData?.original?.monthlyCharge)),
  },
  {
    headerClassName: "table-align-left",
    className: "td-align-left ",
    Header: renderBoldText(CONSTANTS.SHARED_DATA_POOL),
    resizable: true,
    width: 200,
    Cell: (rowData: IRowData) => {
      return (
        <TextStyle alias="Base" element="div">
          {rowData?.original?.sharedDataPool}
        </TextStyle>
      );
    },
  },
  {
    headerClassName: "table-align-left",
    className: "td-align-left",
    Header: renderBoldText(CONSTANTS.INCLUSIONS),
    resizable: true,
    width: 343,
    Cell: (rowData: IRowData) => (
      <InclusionsList inclusions={rowData?.original?.inclusions} />
    ),
  },
];
export default function TableView({ offers }) {
  return (
    <div className="select-plans-table">
      <AdvanceTableV3
        data={offers}
        columns={columns}
        resizable={true}
        className="td-align-left"
        totalRecordLength={offers.length}
        pages={1}
        pageSize={offers.length}
        defaultPageSize={20}
        page={1}
        pageHeaderClassName={"header"}
        isStickyHeader={true}
        sendApi={() => null}
        action={{
          sticky: true,
          column: {
            headerClassName: "table-align-left tl-tableV3-last-sticky",
            className: "td-align-left",
            Header: renderBoldText(CONSTANTS.QUANTITY),
            resizable: true,
            width: 200,
            Cell: (rowData: IRowData) => {
              return <QuantityButton offer={rowData?.original} />;
            },
          },
        }}
        getTrProps={(state, rowInfo, column, instance) => {
          const spacing = rowInfo?.original?.inclusions.length > 1;
          return spacing ? { className: "customSpacing" } : {};
        }}
      />
    </div>
  );
}
