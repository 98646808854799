/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import { useEffect, useState } from "react";
import { TableAccordion } from "telstra-ui/components/table-accordion/AccordionTable";
import { ableDevelopmentUrl, renderBoldText, renderText } from "@utils/helper";
import { SvgIcon } from "telstra-ui/components/svg/Icon";
import {
  ModalDialogConfirmationDestructive,
  TextStyle,
  useToggle,
} from "@able/react";
import { useLocation } from "react-router-dom";
import { PATHS } from "@utils/common-static-data";
import { useAppSelector, useAppDispatch } from "@state/hooks";
import { CART_DEVICES_TABLE, REMOVE_DIALOG } from "../constant";
import ActionColumn from "./action-column";
import { CONFIGURE_DEVICES_AND_ACCESSORIES } from "@pages/devices-and-accessories/constant";
import {
  setCartFlow,
  setDeltaPlans,
  setNormalizedDevicesAndAccesories,
} from "@state/cart-slice";
import { createDeltaItem } from "@services/templates/cart";

const ReviewCartDevicesTable = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [cartDevices, setCartDevices] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [modalIsOpen, toggleModal] = useToggle();
  const [devicesToBeRemove, setDevicesToBeRemove] = useState(new Set());
  const location = useLocation();
  const path = location.pathname;
  const isCheckoutPage = path.includes(PATHS.CHECKOUT);
  const [groupLength, setGroupLength] = useState(0);
  const devices = useAppSelector(
    (state) => state.cart.normalizedDevicesAndAccesories
  );
  const dispatch = useAppDispatch();

  const onExpandedChange = (expandedObj) => {
    setExpanded(expandedObj);
  };

  const headerHandlerClick = (columnName) => {
    if (columnName === "id") {
      const expandRow = pageSize;
      const obj = {};
      for (let i = 0; i < expandRow; i++) {
        obj[i] = {};
      }
      Object.keys(expanded).length === 0 && onExpandedChange(obj);
    }
  };
  const updateGroupLength = (devicesForPagination) => {
    setGroupLength(devicesForPagination.length);
  };
  useEffect(() => {
    setCartDevices(devices);
    updateGroupLength(devices);
    if (!isCheckoutPage) headerHandlerClick("id");
  }, [devices]);

  const renderTitle = (accessor: string) => {
    const onClick = (e) => {
      e.stopPropagation();
      headerHandlerClick(accessor);
    };
    const iconName =
      Object.keys(expanded).length === 0
        ? "icon-system-chevron-down"
        : "icon-system-chevron-up";
    return (
      <div className="tl-table-header-icon-wrapper" onClick={onClick}>
        <SvgIcon name={iconName} />
      </div>
    );
  };

  const onPageChange = (tabName, pageIndex) => {
    setPage(pageIndex);
  };
  const onPageSizeChange = (tabName, currentPageSize) => {
    setPageSize(currentPageSize);
  };

  const getPaymentType = (PaymentType) => {
    const paymentType =
      CONFIGURE_DEVICES_AND_ACCESSORIES.PAYMENT_TYPE_OPTIONS.filter(
        (options) => {
          return options.value === PaymentType;
        }
      );

    return renderText(paymentType[0].label);
  };

  const updateNormalizedDevices = () => {
    const deviceIdToBeRemove = Array.from(devicesToBeRemove);
    const updatedDevices = devices.filter(
      (item) => item.id !== deviceIdToBeRemove[0]
    );
    dispatch(setNormalizedDevicesAndAccesories(updatedDevices));
    updatedDevices.length === 0 && dispatch(setCartFlow("devices"));
  };

  const columnData = [
    {
      headerClassName: "table-align-center",
      className: "td-align-center border-right no-border-bottom",
      style: { paddingLeft: "8px" },
      Header: renderTitle(CART_DEVICES_TABLE.ID),
      accessor: "id",
      resizable: false,
      width: 56,
      Cell: () => null,
    },

    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(CART_DEVICES_TABLE.ITEM),
      accessor: "name",
      resizable: false,
      width: 735,
      Cell: (cellInfo) => renderText(cellInfo.value),
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(CART_DEVICES_TABLE.QUANTITY),
      resizable: false,
      width: 97,
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(CART_DEVICES_TABLE.PAYMENT_TYPE),
      resizable: false,
      minWidth: 237,
      accessor: "paymentType",
      Cell: (cellInfo) => getPaymentType(cellInfo.value),
    },
    {
      Header: renderBoldText(CART_DEVICES_TABLE.SKU),
      accessor: "sku",
      expander: true,
      pivot: true,
      Pivot: (cellInfo) => {
        const subrowsLength = cellInfo?.subRows?.length;
        const className = ["rt-flex", "rt-flex-subheader"].join(" ");
        const device = cellInfo?.subRows[0]?._original || {};
        const { category, name, technologyType, color, sku } = device;
        return (
          <div
            className={`tl-row-expander ${
              cellInfo?.isExpanded ? "tl-row-expanded" : ""
            }`}
          >
            <div className={className}>
              <div className="tl-border-override icon-container">
                <div className="tl-accordion-icon-wrapper">
                  {cellInfo?.isExpanded ? (
                    <SvgIcon name="icon-system-chevron-up" />
                  ) : (
                    <SvgIcon name="icon-system-chevron-down" />
                  )}
                </div>
              </div>
              <div className="device-item" style={{ width: 735 }}>
                <div className="pivot-text-holder">
                  <TextStyle alias="Label">{`${category} | ${name}`}</TextStyle>
                  <TextStyle alias="Tag">
                    {`${technologyType} | ${color} | -- | ${CONFIGURE_DEVICES_AND_ACCESSORIES.SKU} ${sku}`}
                  </TextStyle>
                </div>
              </div>
              <div className="tl-border-override" style={{ width: 97 }}>
                <TextStyle alias="Label" className="tl-accordion-icon-wrapper">
                  {subrowsLength}
                </TextStyle>
              </div>
            </div>
            {!isCheckoutPage && (
              <div
                className="rt-td td-align-left tl-cell-action"
                style={{ width: 86 }}
              >
                <ActionColumn
                  cellInfo={cellInfo}
                  setDevicesToBeRemove={setDevicesToBeRemove}
                  toggleModal={toggleModal}
                />
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="devicesTable">
      <TableAccordion
        data={cartDevices}
        columns={columnData}
        showPagination={groupLength > 10}
        page={page}
        defaultPageSize={pageSize}
        totalRecordLength={cartDevices.length}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        isStickyHeader={true}
        sortable={false}
        pivotBy={["sku"]}
        expanded={expanded}
        onExpandedChange={onExpandedChange}
        freezWhenExpanded={false}
        defaultSortMethod={() => ({})}
        {...(!isCheckoutPage && {
          action: {
            sticky: true,
            column: {
              headerClassName:
                "table-align-left tl-tableV3-last-sticky sticky-column",
              className: "td-align-left",
              Header: renderBoldText("Remove"),
              width: 86,
              Cell: (cellInfo) => (
                <ActionColumn
                  cellInfo={cellInfo}
                  setDevicesToBeRemove={setDevicesToBeRemove}
                  toggleModal={toggleModal}
                />
              ),
            },
          },
        })}
      />
      {!isCheckoutPage && (
        <ModalDialogConfirmationDestructive
          title={
            devicesToBeRemove.size > 1
              ? REMOVE_DIALOG.MULTIPLE_ITEM_TITLE
              : REMOVE_DIALOG.SINGLE_ITEM_TITLE
          }
          description={
            devicesToBeRemove.size > 1
              ? REMOVE_DIALOG.MULTIPLE_ITEM_DESCRIPTION
              : REMOVE_DIALOG.SINGLE_ITEM_DESCRIPTION
          }
          stackButtonOnVXS={false}
          confirmButtonLabel={REMOVE_DIALOG.CONFIRM_BUTTON_LABEL}
          confirmButtonEvents={{
            onClick: () => {
              const deltaDevices = cartDevices.filter((device) =>
                devicesToBeRemove.has(device.id)
              );
              deltaDevices.forEach((deltaDevice) =>
                dispatch(setDeltaPlans(createDeltaItem(deltaDevice, "remove")))
              );
              const updatedDevices = cartDevices.filter(
                (device) => !devicesToBeRemove.has(device.id)
              );
              setCartDevices(updatedDevices);
              updateGroupLength(updatedDevices);
              updateNormalizedDevices();
            },
          }}
          cancelButtonLabel={REMOVE_DIALOG.CANCEL_BUTTON_LABEL}
          cancelButtonEvents={{
            onClick: () => null,
          }}
          isShowing={modalIsOpen}
          setHideDialog={toggleModal}
          developmentUrl={ableDevelopmentUrl}
        />
      )}
    </div>
  );
};

export default ReviewCartDevicesTable;
