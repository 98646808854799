import "./style.scss";
import { Select } from "@able/react";
import { CONFIGURE_DEVICES_AND_ACCESSORIES } from "@pages/devices-and-accessories/constant";
import { CONSTANTS } from "@services/constant";
import { createDeltaItem } from "@services/templates/cart";
import {
  removeDeltaItem,
  setDeltaPlans,
  updateDevicePayment,
} from "@state/cart-slice";
import { useAppDispatch } from "@state/hooks";
import { NormalizedDevice } from "@state/types";
import { ableDevelopmentUrl } from "@utils/helper";
import { CART } from "@utils/common-static-data";

export default function PaymentType(props: { details: NormalizedDevice }) {
  const dispatch = useAppDispatch();
  const paymentTypeOptions = [];
  let option;
  const { details } = props;
  if (details) {
    if ("outright" in details && details.outright > 0) {
      option = CONFIGURE_DEVICES_AND_ACCESSORIES.PAYMENT_TYPE_OPTIONS.find(
        (item) => item.value === "outright"
      );
      paymentTypeOptions.push(option);
    }
    if (
      CART.PRODUCTS.PAYMENT_12_MONTHS in details &&
      details.twelveMonthPayment > 0
    ) {
      option = CONFIGURE_DEVICES_AND_ACCESSORIES.PAYMENT_TYPE_OPTIONS.find(
        (item) => item.value === CART.PRODUCTS.PAYMENT_12_MONTHS
      );
      paymentTypeOptions.push(option);
    }
    if (
      CART.PRODUCTS.PAYMENT_24_MONTHS in details &&
      details.twentyfourMonthPayment > 0
    ) {
      option = CONFIGURE_DEVICES_AND_ACCESSORIES.PAYMENT_TYPE_OPTIONS.find(
        (item) => item.value === CART.PRODUCTS.PAYMENT_24_MONTHS
      );
      paymentTypeOptions.push(option);
    }
  }

  const handlePaymentType = (e) => {
    const { id, value } = e.target;
    const { CHARACTERISTICS, PAYMENT_TYPE } = CONSTANTS;
    const { OUTRIGHT, PURCHASE, HARDWARE_REPAYMENT, TWENTYFOUR_MONTH_PAYMENT } =
      PAYMENT_TYPE;

    if (value === details.initialValues.paymentType) {
      dispatch(removeDeltaItem(details.id));
    } else {
      const characteristics = [
        {
          name: CHARACTERISTICS.CONTRACT_TYPE,
          value: value === OUTRIGHT ? PURCHASE : HARDWARE_REPAYMENT,
        },
      ];
      if (value !== OUTRIGHT) {
        characteristics.push({
          name: CHARACTERISTICS.CONTRACT_TERM,
          value: value === TWENTYFOUR_MONTH_PAYMENT ? "24" : "12",
        });
      }
      const delta = createDeltaItem(details, "update", characteristics);
      dispatch(setDeltaPlans(delta));
    }
    const paymentTypeSelected = { id, value };
    dispatch(updateDevicePayment(paymentTypeSelected));
  };

  return (
    <Select
      id={`paymentType-${details?.id}`}
      className="payment-type"
      label=""
      options={paymentTypeOptions}
      value={details?.paymentType}
      invalid={!details?.isPaymentTypeSelected}
      events={{
        onChange: (e) => {
          handlePaymentType(e);
        },
      }}
      developmentUrl={ableDevelopmentUrl}
    />
  );
}
