export const BILLING_ACCOUNT_CONTSTANTS = {
  BILLING_ACCOUNT: "Billing account",
  BILLING_ACCOUNT_REQUIRED_TEXT: "Required selection.",
  BILLING_ACCOUNT_ASSISTIVE_TEXT:
    "The order will be charged to this billing account. Shareable data is added to this account's data pool.",
  BILLING_ACCOUNT_SELECT_DEVICE_ASSISTIVE_TEXT:
    "The order will be charged to this billing account.",
  NO_BILLING_ACCOUNT_MESSAGE:
    "Billing account/s not found. To proceed, a billing account needs to be added to the system.",
};

export default BILLING_ACCOUNT_CONTSTANTS;
