import { useState } from "react";
import { AdvanceFilter } from "telstra-ui/components/advance-filter";
import { useAppDispatch } from "@state/hooks";
import { updateSelectedFilters } from "@state/common-slice";
import { SELECT_PLAN } from "@pages/new-plan-order/constant";

export const CustomAdvanceFilter = (props) => {
  let orderedFilterPills;
  const defaultPill = {
    id: "",
    label: "",
    isActive: false,
    variant: "primary",
    isDisabled: false,
    ripple: false,
    type: "",
  };
  const initialPills = [];

  if (props.offers) {
    for (const offer of props.offers) {
      initialPills.push({
        ...defaultPill,
        id: offer.planType,
        label: offer.planType,
        type: "plan",
      });
    }
    const uniquePills = new Set(initialPills.map((e) => JSON.stringify(e)));
    const filterPills = Array.from(uniquePills).map((e) => JSON.parse(e));
    orderedFilterPills = JSON.parse(JSON.stringify(filterPills));
    for (const pill of filterPills) {
      switch (pill.id) {
        case SELECT_PLAN.FILTERS.FILTER_MOBILE:
          orderedFilterPills[0] = pill;
          break;
        case SELECT_PLAN.FILTERS.FILTER_BROADBAND:
          orderedFilterPills[1] = pill;
          break;
        case SELECT_PLAN.FILTERS.FILTER_ENTERPRISE_WIRELESS:
          orderedFilterPills[2] = pill;
          break;
        default:
          break;
      }
    }
  }
  const dispatch = useAppDispatch();
  const filterState = {
    pills: orderedFilterPills,
    selectedIds: [],
  };

  const [filters, setFilters] = useState(filterState);
  const onClickItem = (item: any) => {
    let { pills, selectedIds } = filters;
    if (item.isActive) {
      selectedIds = selectedIds.filter((ids) => ids.id !== item.id);
    } else {
      selectedIds.push({
        id: item.id,
        type: item.type,
      });
    }
    pills = pills.map((pill) => {
      if (pill.id === item.id) {
        pill.isActive = !item.isActive;
      }
      return pill;
    });
    setFilters({
      ...filters,
      pills,
      selectedIds,
    });
    dispatch(updateSelectedFilters(selectedIds));
  };

  return <AdvanceFilter filters={filters?.pills} events={{ onClickItem }} />;
};
