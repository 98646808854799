import React from "react";
import { Grid } from "@able/react";
import GridSurface from "./grid-surface";

const GridView = ({ offers }) => {
  return (
    <Grid>
      {offers.map((offer) => (
        <GridSurface key={offer.id} offer={offer} />
      ))}
    </Grid>
  );
};

export default GridView;
