import "./style.scss";
import { Column, Grid, PageStepper, Spacing, useToggle } from "@able/react";
import {
  useReloadHook,
  useGetFeatureFlagHook,
  ableDevelopmentUrl,
  configureDevicesPagePatchCartHandler,
  selectDevicesPatchCartHandler,
} from "@utils/helper";
import {
  CONFIGURE_DEVICES_AND_ACCESSORIES,
  DEVICES_AND_ACCESSORIES,
} from "./constant";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  PATHS,
  CANCEL_DRAFT_ORDER,
  TRY_AGAIN,
  ERRORS,
  AUTOSAVE_MESSAGE,
} from "@utils/common-static-data";
import CancelDraftOrder from "@components/cancel-draft-order";
import AutosaveMessage from "@components/autosave-message";
import MessageSection, {
  MessageSectionProps,
} from "@able/react/dist/MessageSection";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import ErrorScreen from "@components/error-screen";
import { useLazyPatchCartQuery } from "@services/cart";
import { setRequiredForPaymentType, setCart } from "@state/cart-slice";

export default function DevicesAndAccessories() {
  const path = useLocation().pathname;
  const [modalIsOpen, toggleModal] = useToggle();
  const { enableCancelDraft } = useGetFeatureFlagHook();
  const { isError: showFullScreenError } = useAppSelector(
    (state) => state.error
  );
  const { selectedDevices, backOrder } = useAppSelector(
    (state) => state.selectDevices
  );
  const {
    deltaPlans,
    normalizedDevicesAndAccesories,
    billingAccount,
    error: cartError,
  } = useAppSelector((state) => state.cart);
  const itemsInCart = useAppSelector((state) => state.selectPlans);
  const reduxStore = useAppSelector((state) => state);

  const { cartId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [patchCart] = useLazyPatchCartQuery();

  const cancelDraftOrderProps: Partial<MessageSectionProps> = enableCancelDraft
    ? {
        actionText: CANCEL_DRAFT_ORDER.CANCEL_DRAFT_ORDER_BUTTON,
        actionElement: "button",
        actionCallback: () => toggleModal(),
      }
    : {};

  const currentStep = path.includes(PATHS.SELECT_DEVICES)
    ? 1
    : path.includes(PATHS.CONFIGURE_DEVICES)
    ? 2
    : 0;

  useReloadHook();

  const getPageTitle = () => {
    switch (currentStep) {
      case 1:
        return DEVICES_AND_ACCESSORIES.SELECT_DEVICES_TAGLINE;
      case 2:
        return CONFIGURE_DEVICES_AND_ACCESSORIES.CONFIGURE_DEVICES_TAGLINE;
      default:
        return "";
    }
  };

  const tryAgainHandler = (e) => {
    e.preventDefault();
    if (path.includes(PATHS.CONFIGURE_DEVICES)) {
      configureDevicesPagePatchCartHandler({
        cartId,
        deltaPlans,
        patchCart,
        setCart,
        navigate,
        dispatch,
        normalizedDevicesAndAccesories,
        setRequiredForPaymentType,
      });
    }
    if (path.includes(PATHS.SELECT_DEVICES)) {
      selectDevicesPatchCartHandler({
        cartId,
        billingAccount,
        selectedDevices,
        itemsInCart,
        backOrder,
        setCart,
        dispatch,
        patchCart,
        reduxStore,
        navigate,
      });
    }
  };
  if (showFullScreenError && cartError) {
    return (
      <ErrorScreen
        error={cartError}
        customErrorMsg={cartError?.customErrorMsg}
      />
    );
  }

  return (
    <div className="devices-and-accessories">
      {cartError?.status === 500 && (
        <Spacing top="spacing2x">
          <Grid>
            <Column cols={12} bsm={12} bmd={12} blg={12}>
              <MessageSection
                variant="Error"
                developmentUrl={ableDevelopmentUrl}
                description={ERRORS[cartError.status]}
                actionText={TRY_AGAIN}
                actionLink={path}
                className="message-section"
                actionCallback={tryAgainHandler}
              />
            </Column>
          </Grid>
        </Spacing>
      )}
      <AutosaveMessage
        columnProps={{ bmd: 4 }}
        messageSectionProps={{
          description: AUTOSAVE_MESSAGE,
          variant: "Success",
          ...cancelDraftOrderProps,
        }}
      />
      <Spacing top="spacing4x">
        <PageStepper
          variant="Fixed"
          numberOfSteps={2}
          currentStep={currentStep}
          pageTitle={DEVICES_AND_ACCESSORIES.PAGE_TITLE}
          pageTagline={getPageTitle()}
        />
        {enableCancelDraft && (
          <CancelDraftOrder
            modalIsOpen={modalIsOpen}
            toggleModal={toggleModal}
          />
        )}
      </Spacing>
      <Outlet />
    </div>
  );
}
