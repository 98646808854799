// eslint-disable-next-line import/no-extraneous-dependencies
import { isValidDate } from "@able/web/dist/patterns/DayMonthYearGroup/DayMonthYearGroup";

export const isValidCustomerReqDate = (date) => {
  if (isValidDate(date)) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const dateToCheck = new Date(`${date.year}-${date.month}-${date.day}`);
    if (today < dateToCheck || dateToCheck.getTime() == today.getTime()) {
      return "valid";
    }
    return "Invalid entry. Date must be after today's date";
  }

  return "Invalid entry. Enter a valid date.";
};
