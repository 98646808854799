import "./style.scss";
import Combobox from "ted-ui/build/Combobox";
import { Grid, Spacing, Column } from "@able/react";
import { ableDevelopmentUrl } from "@utils/helper";
import { useEffect, useState } from "react";
import { updateSelectedDeviceFilters } from "@state/common-slice";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { SELECT_DEVICES } from "@pages/devices-and-accessories/constant";

export function SearchCombobox({ devices, updateDevices }) {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState("");
  const selectedDeviceFilters = useAppSelector(
    (state) => state.commonStore.selectedDeviceFilters
  );
  const initialOptionsArray = devices.map((device) => device.name);
  const [optionsArray, setOptionsArray] = useState(initialOptionsArray);

  useEffect(() => {
    formSearchResult("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelect = (result: string) => {
    setValue(result);
    if (!result) {
      const filters = selectedDeviceFilters.filter(
        (filter) => filter.type !== "search"
      );
      dispatch(updateSelectedDeviceFilters(filters));
    } else {
      const selectedDevice = devices.find((device) => device.name === result);
      if (selectedDevice) {
        let updatedFilters = JSON.parse(JSON.stringify(selectedDeviceFilters));
        updatedFilters = updatedFilters.map((filter) => {
          filter.selected = false;
          return filter;
        });
        dispatch(
          updateSelectedDeviceFilters([
            ...updatedFilters,
            {
              id: selectedDevice?.id,
              dataId: selectedDevice?.id,
              selected: true,
              type: "search",
            },
          ])
        );
      }
    }
    updateDevices();
  };

  const onChangeSearchText = (searchedValue: string) => {
    formSearchResult(searchedValue);
  };

  const formSearchResult = (searchedValue: string) => {
    const filterByKey = (searchByKey) => {
      return (
        searchByKey
          ?.toString()
          .toLowerCase()
          .indexOf(searchedValue?.toLowerCase()) > -1
      );
    };

    let searchedDevices = [];
    if (searchedValue !== "") {
      searchedDevices = devices.filter((device) => {
        return (
          filterByKey(device?.name) ||
          filterByKey(device?.color) ||
          filterByKey(device?.manufacturer) ||
          filterByKey(device?.storage)
        );
      });
    } else {
      searchedDevices = devices;
    }

    const result = searchedDevices.map((device) => device.name);
    setOptionsArray(result);
  };

  return (
    <Spacing top="spacing4x">
      <Grid>
        <Column cols={12} bsm={12} bmd={12} blg={12}>
          <div className="searchDevices">
            <Combobox
              developmentUrl={ableDevelopmentUrl}
              helpText={SELECT_DEVICES.SEARCH_HELPTEXT}
              id="searchDevices"
              label={SELECT_DEVICES.SEARCH}
              onChangeSearchText={onChangeSearchText}
              onSelect={onSelect}
              options={optionsArray}
              value={value}
            />
          </div>
        </Column>
      </Grid>
    </Spacing>
  );
}
