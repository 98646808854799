import { createSlice } from "@reduxjs/toolkit";
import { resetAction } from "./reset";

export type NewOrderDetailsState = {
  requester: string;
  requesterError: string;
  internalReferenceNumber: string;
  internalReferenceNumberError: string;
  orderReference: string;
};

const initialState: NewOrderDetailsState = {
  requester: "",
  requesterError: "",
  internalReferenceNumber: "",
  internalReferenceNumberError: "",
  orderReference: "",
};

export const newOrderSlice = createSlice({
  name: "new-order",
  initialState,
  reducers: {
    updateNewOrderDetails: (state, action) => {
      return action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(resetAction, (state, action) => {
      return { ...initialState };
    });
  },
});

export const { updateNewOrderDetails } = newOrderSlice.actions;

export default newOrderSlice.reducer;
