import { Grid, Spacing, Column } from "@able/react";
import { useCommonGetCartQuery } from "@services/cart";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { useEffect, useState } from "react";
import BillingAccount from "@components/billing-account";
import { NewPlansByCategory } from "./new-plans-by-category";
import { useParams } from "react-router-dom";
import { useCartAttributes, useShouldEnableGetCartHook } from "@utils/cart";
import ErrorScreen from "@components/error-screen";
import { CART } from "@utils/common-static-data";
import { updateBillingAccount } from "@state/cart-slice";
import EmptyCart from "@components/empty-cart";
import { EMPTY_CART_MESSAGE } from "@components/empty-cart/constants";
import { useGetOffersQuery } from "@services/catalog";
import Loading from "@components/loading-screen";

const ConfigurePlanBarringAddOns = () => {
  const dispatch = useAppDispatch();
  const { cartId } = useParams();
  const customError = useAppSelector((state) => state.error.isError);

  const [billingError, setBillingError] = useState(null);

  useGetOffersQuery();
  const skipGetCart = useShouldEnableGetCartHook();
  const { isError, isFetching } = useCommonGetCartQuery(
    { cartId },
    { skip: !skipGetCart }
  );

  const billingAccountNumber = useCartAttributes().getItemAttribute(
    CART.ATTRIBUTES.BILLING_ACCOUNT_NUMBER
  );

  const emptyCartError = useAppSelector((state) => state.error.emptyCartError);

  useEffect(() => {
    if (billingAccountNumber) {
      dispatch(
        updateBillingAccount({
          billingAccount: billingAccountNumber,
          invalidBillingAccount: false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingAccountNumber]);

  const catchBillingError = (error) => {
    setBillingError(error);
  };

  if (customError && billingError) {
    return <ErrorScreen error={billingError} />;
  }

  return (
    <Grid>
      <Column cols={12} bsm={4} bmd={4} blg={4}>
        <Spacing top="spacing10x">
          <BillingAccount catchBillingError={catchBillingError} />
        </Spacing>
      </Column>
      {emptyCartError && (
        <EmptyCart
          modalIsOpen={emptyCartError}
          title={EMPTY_CART_MESSAGE.TITLE}
          description={EMPTY_CART_MESSAGE.DESCRIPTION}
        />
      )}
      {isFetching ? (
        <Loading />
      ) : isError ? (
        <div>Error</div>
      ) : (
        <NewPlansByCategory />
      )}
    </Grid>
  );
};

export default ConfigurePlanBarringAddOns;
