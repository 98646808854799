import React from "react";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { toggleSelectedNumbers } from "@state/sim-configuration-slice";
import { Checkbox } from "telstra-ui/components/checkbox/Checkbox";

export default function AvailableNumberCheckbox({ item }) {
  const dispatch = useAppDispatch();
  const selectedNumbers = useAppSelector(
    (state) => state.simConfiguration.selectedNumbers
  );

  return (
    <Checkbox
      id={item.number}
      label={item.number}
      name="available-number"
      checked={selectedNumbers.includes(item.number)}
      onChange={(e) => {
        dispatch(toggleSelectedNumbers(e.target.id));
      }}
    />
  );
}
