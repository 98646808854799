import "./style.scss";
import {
  ActionButton,
  MessageSection,
  Spacing,
  SectionHeader,
  Grid,
  useToggle,
  Column,
} from "@able/react";
import React, { useEffect } from "react";
import { CART } from "./constant";
import {
  ableDevelopmentUrl,
  useCustomNavigate,
  cartPagePatchCartHandler,
  useGetFeatureFlagHook,
  useReloadHook,
} from "@utils/helper";
import { useShouldEnableGetCartHook } from "@utils/cart";
import ReviewCartPlansTable from "./plans-table-view";
import CancelDraftOrder from "@components/cancel-draft-order";
import { useCommonGetCartQuery, useLazyPatchCartQuery } from "@services/cart";
import { useParams, useLocation } from "react-router-dom";
import {
  AUTOSAVE_MESSAGE,
  CANCEL_DRAFT_ORDER,
  PATHS,
  TRY_AGAIN,
} from "@utils/common-static-data";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { setCart } from "@state/cart-slice";
import { setError } from "@state/error";
import ErrorScreen from "@components/error-screen";
import ReviewCartDevicesTable from "./devices-table-view";
import AutosaveMessage from "@components/autosave-message";
import { MessageSectionProps } from "@able/react/dist/MessageSection";
import EmptyCart from "@components/empty-cart";
import { EMPTY_CART_MESSAGE } from "@components/empty-cart/constants";
import ReviewCartChargesTable from "./charges-table-view";

export default function Cart() {
  const { cartId } = useParams();
  useReloadHook();
  const navigate = useCustomNavigate();
  const dispatch = useAppDispatch();
  const path = useLocation().pathname;
  const cartError = useAppSelector((state) => state.cart.error);
  const skipGetCart = useShouldEnableGetCartHook();
  const [patchCart] = useLazyPatchCartQuery();
  const { isFetching: getPatchedCartFetching, error: getPatchedCartError } =
    useCommonGetCartQuery(
      { cartId },
      {
        skip: !skipGetCart,
      }
    );
  const { enableCancelDraft } = useGetFeatureFlagHook();
  const [modalIsOpen, toggleModal] = useToggle();
  const {
    deltaPlans,
    normalizedPlansQBS,
    normalizedDevicesAndAccesories,
    cartFlow,
  } = useAppSelector((state) => state.cart);
  const showErrorScreen = useAppSelector((state) => state.error.isError);
  const isPlansFlow = normalizedPlansQBS.length > 0;
  const isDevicesFlow = normalizedDevicesAndAccesories.length > 0;
  const cancelDraftOrderProps: Partial<MessageSectionProps> = enableCancelDraft
    ? {
        actionText: CANCEL_DRAFT_ORDER.CANCEL_DRAFT_ORDER_BUTTON,
        actionElement: "button",
        actionCallback: () => toggleModal(),
      }
    : {};

  const emptyCartError = useAppSelector((state) => state.error.emptyCartError);

  useEffect(() => {
    if (getPatchedCartError && !getPatchedCartFetching) {
      dispatch(setError(true));
    } else {
      dispatch(setError(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatchedCartError, getPatchedCartFetching]);

  const onTryAgain = async (e) => {
    e.preventDefault();
    let product;
    if (isPlansFlow) product = "plan";
    else if (isDevicesFlow) product = "device";

    cartPagePatchCartHandler({
      cartId,
      deltaPlans,
      patchCart,
      navigate,
      dispatch,
      setCart,
      product,
    });
  };

  if (showErrorScreen) {
    let errObj;
    if (cartError?.errorCode && cartError.errorCode !== 500) {
      errObj = {
        status: cartError.errorCode,
      };
    }
    if (getPatchedCartError) {
      errObj = getPatchedCartError;
    }
    return <ErrorScreen error={errObj} />;
  }

  return (
    <Grid>
      <Column cols={12} bsm={12} bmd={12} blg={12}>
        {cartError?.errorCode === 500 && (
          <Spacing top="spacing2x">
            <Grid>
              <Column cols={12} bsm={12} bmd={12} blg={12}>
                <MessageSection
                  variant="Error"
                  developmentUrl={ableDevelopmentUrl}
                  description={cartError.errorDescription}
                  actionText={TRY_AGAIN}
                  actionLink={path}
                  className="message-section"
                  actionCallback={onTryAgain}
                />
              </Column>
            </Grid>
          </Spacing>
        )}
        <Spacing top="spacing3x" bottom="spacing3x">
          <AutosaveMessage
            columnProps={{ bmd: 6 }}
            messageSectionProps={{
              description: AUTOSAVE_MESSAGE,
              variant: "Success",
              className: "message-section",
              ...cancelDraftOrderProps,
            }}
          />
        </Spacing>
        <SectionHeader title={CART.PAGE_TITLE} variantLevel="Level1" />
        {emptyCartError && (
          <EmptyCart
            modalIsOpen={emptyCartError}
            title={
              cartFlow === "devices"
                ? EMPTY_CART_MESSAGE.TITLE_DEVICES
                : EMPTY_CART_MESSAGE.TITLE
            }
            description={
              cartFlow === "devices"
                ? EMPTY_CART_MESSAGE.DESCRIPTION_DEVICES
                : EMPTY_CART_MESSAGE.DESCRIPTION
            }
          />
        )}
        {isPlansFlow && (
          <>
            <Spacing top="spacing4x" bottom="spacing3x">
              <MessageSection
                variant="Information"
                developmentUrl={ableDevelopmentUrl}
                description={CART.INLINE_MESSAGE}
                className="message-section"
              />
            </Spacing>
            <SectionHeader title={CART.PLAN} variantLevel="Level2" />
            <div className="button-row">
              <ActionButton
                element="button"
                label={CART.MODIFY_PLANS_BUTTON}
                variant="MediumEmphasis"
                events={{
                  onClick: () => navigate(PATHS.SELECT_PLAN, cartId),
                }}
              />
              <ActionButton
                element="button"
                label={CART.MODIFY_BARRING_ADDON_BUTTON}
                variant="MediumEmphasis"
                events={{
                  onClick: () => navigate(PATHS.BARRING_ADDONS, cartId),
                }}
              />
              <ActionButton
                element="button"
                label={CART.MODIFY_SIM_CONFIG_BUTTON}
                variant="MediumEmphasis"
                events={{
                  onClick: () => navigate(PATHS.CONFIGURE_SIM, cartId),
                }}
              />
            </div>
            <Spacing top="spacing2x">
              {getPatchedCartFetching && <div>Loading---</div>}
              {<ReviewCartPlansTable />}
            </Spacing>
            <Spacing top="spacing2x">
              <ReviewCartChargesTable />
            </Spacing>
          </>
        )}
      </Column>
      {isDevicesFlow && (
        <>
          <Column cols={7} bsm={7} bmd={7} blg={7}>
            <SectionHeader
              title={CART.DEVICES_AND_ACCESSORIES}
              variantLevel="Level2"
            />
          </Column>
          <Column cols={3} bsm={3} bmd={3} blg={3}>
            <ActionButton
              className="modifyDevices"
              developmentUrl={ableDevelopmentUrl}
              element="button"
              label={CART.MODIFY_DEVICES_AND_ACCESSORIES}
              variant="LowEmphasis"
              events={{
                onClick: () => {
                  navigate(PATHS.SELECT_DEVICES, cartId);
                },
              }}
            />
          </Column>
          <Column cols={2} bsm={2} bmd={2} blg={2}>
            <ActionButton
              developmentUrl={ableDevelopmentUrl}
              element="button"
              label={CART.MODIFY_CONFIGURATION}
              variant="LowEmphasis"
              events={{
                onClick: () => {
                  navigate(PATHS.CONFIGURE_DEVICES, cartId);
                },
              }}
            />
          </Column>
          <Column cols={12} bsm={12} bmd={12} blg={12}>
            <Spacing top="spacing2x">
              <ReviewCartDevicesTable />
            </Spacing>
            <Spacing top="spacing2x">
              <ReviewCartChargesTable />
            </Spacing>
          </Column>
        </>
      )}
      {enableCancelDraft && (
        <CancelDraftOrder modalIsOpen={modalIsOpen} toggleModal={toggleModal} />
      )}
    </Grid>
  );
}
