/* eslint-disable no-console */
import "./style.scss";
import {
  Column,
  Grid,
  SectionHeader,
  Spacing,
  TextStyle,
  useToggle,
  MessageSection,
} from "@able/react";
import {
  AUTOSAVE_MESSAGE,
  TRY_AGAIN,
  ERRORS,
  CANCEL_DRAFT_ORDER,
} from "@utils/common-static-data";
import AutosaveMessage from "@components/autosave-message";
import CustomerDetails from "@pages/checkout/customer-details";
import {
  useReloadHook,
  useGetFeatureFlagHook,
  ableDevelopmentUrl,
  checkoutSubmitHandler,
} from "@utils/helper";
import { YourItems } from "./your-items";
import { CHECKOUT } from "@pages/checkout/constant";
import CancelDraftOrder from "@components/cancel-draft-order";
import { useAppSelector, useAppDispatch } from "@state/hooks";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useCommonGetCartQuery, useLazyPatchCartQuery } from "@services/cart";
import { useShouldEnableGetCartHook, useCartAttributes } from "@utils/cart";
import { useLazyValidateReferenceNumberQuery } from "@services/base";
import ErrorScreen from "@components/error-screen";
import { useEffect } from "react";
import { setError, setCheckoutError } from "@state/error";
import { MessageSectionProps } from "@able/react/dist/MessageSection";

export default function Checkout() {
  useReloadHook();
  const [modalIsOpen, toggleModal] = useToggle();
  const { normalizedPlansQBS, normalizedDevicesAndAccesories } = useAppSelector(
    (state) => state.cart
  );
  const { cartId } = useParams();
  const skipGetCart = useShouldEnableGetCartHook();
  const { isFetching, isError, error } = useCommonGetCartQuery(
    { cartId },
    {
      skip: !skipGetCart,
    }
  );

  const { enableCancelDraft } = useGetFeatureFlagHook();
  const {
    internalReferenceNumber,
    orderReference,
    deliveryAddress,
    contactDetails,
    selectedRelatedParties,
  } = useAppSelector((state) => state.checkout);

  const { checkoutError } = useAppSelector((state) => state.error);
  const { errorObj, errorDescription } = checkoutError || {};
  const path = useLocation().pathname;
  const previousPatchCartResponse = useAppSelector(
    (state) => state.cart.patchCartResponse
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [triggerValidateRefNumberQuery] = useLazyValidateReferenceNumberQuery();
  const [patchCart, patchCartResponse] = useLazyPatchCartQuery();
  const getInternalReferenceNumber = () => {
    const { cartAttributes } = previousPatchCartResponse;
    const cartAttributesObj = cartAttributes?.find(
      (obj) => obj.name === "telstraInternalOrderReference"
    );
    return cartAttributesObj?.value;
  };
  const isDevicesFlow = normalizedDevicesAndAccesories.length > 0;
  const isPlansFlow = normalizedPlansQBS.length > 0;
  const showErrorScreen = useAppSelector((state) => state.error.isError);

  const previousPatchCartAttributes = useCartAttributes().getAllAttributes();

  const requestObjForCheckout = {
    previousPatchCartResponse,
    deliveryAddress,
    contactDetails,
    internalReferenceNumber,
    orderReference,
    previousPatchCartAttributes,
    selectedRelatedParties,
  };

  const cancelDraftOrderProps: Partial<MessageSectionProps> = enableCancelDraft
    ? {
        actionText: CANCEL_DRAFT_ORDER.CANCEL_DRAFT_ORDER_BUTTON,
        actionElement: "button",
        actionCallback: () => toggleModal(),
      }
    : {};

  const onTryAgain = (e) => {
    e.preventDefault();
    checkoutSubmitHandler(
      internalReferenceNumber,
      getInternalReferenceNumber,
      dispatch,
      triggerValidateRefNumberQuery,
      patchCart,
      requestObjForCheckout,
      navigate,
      cartId
    );
  };

  useEffect(() => {
    let errObj,
      errDescription = "";
    if (
      (patchCartResponse?.isError && !patchCartResponse?.isFetching) ||
      (isError && !isFetching)
    ) {
      if (
        patchCartResponse &&
        patchCartResponse.error &&
        "status" in patchCartResponse.error
      ) {
        const errorCode = patchCartResponse.error.status;
        errObj = patchCartResponse.error;
        if (errorCode === 500) {
          errDescription = ERRORS[errorCode];
          dispatch(setError(false));
        } else {
          dispatch(setError(true));
        }
      }

      if (isError && error && "status" in error) {
        errObj = error;
        dispatch(setError(true));
      }
      dispatch(
        setCheckoutError({
          errorObj: errObj,
          errorDescription: errDescription,
        })
      );
    } else {
      dispatch(setError(false));
      dispatch(setCheckoutError(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isFetching, error, patchCartResponse]);

  if (showErrorScreen) {
    return (
      <ErrorScreen
        error={errorObj}
        showTryAgain={errorDescription ? true : false}
        customErrorMsg={errorDescription}
      />
    );
  }
  return (
    <>
      {errorObj && errorObj?.status === 500 && (
        <Spacing top="spacing2x">
          <Grid>
            <Column cols={12} bsm={12} bmd={12} blg={12}>
              <MessageSection
                variant="Error"
                developmentUrl={ableDevelopmentUrl}
                description={errorDescription}
                actionText={TRY_AGAIN}
                actionLink={path}
                className="message-section"
                actionCallback={onTryAgain}
              />
            </Column>
          </Grid>
        </Spacing>
      )}
      <AutosaveMessage
        columnProps={{ bmd: 6 }}
        messageSectionProps={{
          description: AUTOSAVE_MESSAGE,
          variant: "Success",
          className: "message-section",
          ...cancelDraftOrderProps,
        }}
      />
      <Grid>
        <Column>
          <TextStyle alias="HeadingB">{CHECKOUT.TITLE}</TextStyle>
          <Spacing top="spacing3x">
            <SectionHeader
              title={CHECKOUT.CUSTOMER_DETAILS.TITLE}
              variantLevel="Level2"
              headingElement="h2"
            />
            {(isPlansFlow || isDevicesFlow) && <CustomerDetails />}
          </Spacing>
        </Column>
        {isFetching && <div>Loading---</div>}
        <Column>{(isPlansFlow || isDevicesFlow) && <YourItems />}</Column>
        {enableCancelDraft && (
          <CancelDraftOrder
            modalIsOpen={modalIsOpen}
            toggleModal={toggleModal}
          />
        )}
      </Grid>
    </>
  );
}
