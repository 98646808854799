import {
  ActionButton,
  Spacing,
  TextStyle,
  ModalContent,
  SIM56,
  useToggle,
} from "@able/react";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { setSimConfigurationError } from "@state/error";
import { ableDevelopmentUrl, useGetFeatureFlagHook } from "@utils/helper";
import { useEffect, useState } from "react";
import {
  BULK_CONFIG,
  SIM_TYPE_CONFIG,
} from "@pages/new-plan-order/steps/sim-configuration/bulk-config/constants";
import SimType from "@pages/new-plan-order/steps/sim-configuration/bulk-config/sim-type";
import "./style.scss";
import { ERROR_TYPES } from "@pages/new-plan-order/constant";
import CustomPhoneNumberModal from "../custom-phone-number";

export default function BulkConfig({ showLabel = false }) {
  const [modalIsOpen, toggleModal] = useToggle();
  const [showSimTypeModal, setShowSimTypeModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { enableCustomSearch } = useGetFeatureFlagHook();
  const selectedPlans = useAppSelector(
    (store) => store.commonStore.selectedPlans
  );
  const simConfigError = useAppSelector(
    (state) => state.error.simConfigurationError
  );
  const { errorType } = simConfigError || {};

  useEffect(() => {
    if (
      errorType === ERROR_TYPES.SIM_CONFIG_NO_PLANS_SELECTED &&
      selectedPlans.length > 0
    ) {
      dispatch(setSimConfigurationError(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlans]);

  const closeSimTypeModal = () => {
    setShowSimTypeModal(false);
  };

  const showNoPlansError = () => {
    dispatch(
      setSimConfigurationError({
        errorType: ERROR_TYPES.SIM_CONFIG_NO_PLANS_SELECTED,
        errorDescription: BULK_CONFIG.NO_PLANS_SELECTED_ERROR,
      })
    );
  };
  return (
    <>
      <Spacing top="spacing5x" bottom="spacing1x">
        <TextStyle alias="HeadingD">{BULK_CONFIG.TITLE}</TextStyle>
      </Spacing>
      {showLabel && (
        <Spacing bottom="spacing3x">
          <TextStyle alias="FinePrint">{BULK_CONFIG.SUBTEXT}</TextStyle>
        </Spacing>
      )}
      <Spacing bottom="spacing5x">
        <div className="button-rows">
          <ActionButton
            developmentUrl={ableDevelopmentUrl}
            element="button"
            label={BULK_CONFIG.SIM_TYPE}
            type="button"
            variant="MediumEmphasis"
            events={{
              onClick: () => {
                if (selectedPlans.length < 1) {
                  showNoPlansError();
                } else {
                  setShowSimTypeModal(!showSimTypeModal);
                }
              },
            }}
          />
          {enableCustomSearch && (
            <Spacing left="spacing2x">
              <ActionButton
                developmentUrl={ableDevelopmentUrl}
                element="button"
                label={BULK_CONFIG.ASSIGNED_NUMBER}
                type="button"
                variant="MediumEmphasis"
                data-testid="customPhoneNumber"
                events={{
                  onClick: () => {
                    if (selectedPlans.length < 1) {
                      showNoPlansError();
                    } else {
                      toggleModal();
                    }
                  },
                }}
              />
            </Spacing>
          )}
        </div>
      </Spacing>

      <ModalContent
        title={SIM_TYPE_CONFIG.TITLE}
        variant="Comfortable"
        bodyContent={<SimType onClose={closeSimTypeModal} />}
        footerContent={null}
        picto={<SIM56 />}
        isShowing={showSimTypeModal}
        setHideDialog={closeSimTypeModal}
      />
      {modalIsOpen && (
        <CustomPhoneNumberModal
          modalIsOpen={modalIsOpen}
          toggleModal={toggleModal}
        />
      )}
    </>
  );
}
