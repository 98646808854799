import { TextStyle } from "@able/react";
import { IPlan } from "@state/types";
import { renderText } from "@utils/helper";

interface IProps {
  plan: IPlan;
  field: "barrings" | "addOns";
}

export default function Selections(props: IProps) {
  const { plan, field } = props;
  const selectionsObject = plan[field] || {};
  const selections = Object.values(selectionsObject);

  if (selections.length === 0) return <>- -</>;

  if (selections.length === 1) {
    const item = selections[0];
    return (
      <div className="selection">
        {item.value
          ? renderText(`${item.name} - ${item.value}`)
          : renderText(item.name)}
      </div>
    );
  }

  return (
    <TextStyle element="ul">
      {selections.map((item) => (
        <div key={item.name}>
          <li>{item.value ? `${item.name} - ${item.value}` : item.name}</li>
        </div>
      ))}
    </TextStyle>
  );
}
