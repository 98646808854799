import { createSlice } from "@reduxjs/toolkit";
import { CANCEL_DRAFT_ORDER } from "@utils/common-static-data";
import { resetAction } from "./reset";

type IFilterNames = {
  id: string;
  type: string;
};
type IFilterPills = {
  id: string;
  dataId: string;
  selected: boolean;
  type: string;
};
export type ICommonProps = {
  loading: boolean;
  selectedPlans: any[];
  selectedFilters: IFilterNames[];
  selectedDeviceFilters: IFilterPills[];
  otherReason: string;
  cancellationReason: string;
  requiredReason: boolean;
  showTextBox: boolean;
};

const initialState: ICommonProps = {
  loading: false,
  selectedPlans: [],
  selectedFilters: [],
  selectedDeviceFilters: [],
  otherReason: "",
  cancellationReason: CANCEL_DRAFT_ORDER.DUPLICATE_ENTRY,
  requiredReason: false,
  showTextBox: false,
};

export const commonSlice = createSlice({
  name: "common-slice",
  initialState,
  reducers: {
    toggleSelection: (state, action) => {
      const id = action.payload;
      const position = state.selectedPlans.indexOf(id);
      if (position === -1) {
        state.selectedPlans.push(id);
      } else {
        state.selectedPlans.splice(position, 1);
      }
    },
    toggleAll: (state, action) => {
      if (action.payload.length === 0) {
        return { ...state, selectedPlans: [] };
      }
      return { ...state, selectedPlans: [...action.payload] };
    },
    updateSelectedFilters: (state, action) => {
      return { ...state, selectedFilters: [...action.payload] };
    },
    updateSelectedDeviceFilters: (state, action) => {
      return {
        ...state,
        selectedDeviceFilters: [...action.payload],
      };
    },
    setOtherReason: (state, action) => {
      return { ...state, otherReason: action.payload };
    },
    setCancellationReason: (state, action) => {
      return { ...state, cancellationReason: action.payload };
    },
    setRequiredReason: (state, action) => {
      return { ...state, requiredReason: action.payload };
    },
    setShowTextBox: (state, action) => {
      return { ...state, showTextBox: action.payload };
    },
    setDefaultState: (state) => {
      return {
        ...state,
        cancellationReason: CANCEL_DRAFT_ORDER.DUPLICATE_ENTRY,
        otherReason: "",
        showTextBox: false,
      };
    },
    setGlobalLoader: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(resetAction, (state, action) => {
      return { ...initialState };
    });
  },
});

export const {
  toggleSelection,
  toggleAll,
  updateSelectedFilters,
  updateSelectedDeviceFilters,
  setOtherReason,
  setCancellationReason,
  setRequiredReason,
  setShowTextBox,
  setDefaultState,
  setGlobalLoader,
} = commonSlice.actions;

export default commonSlice.reducer;
