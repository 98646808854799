import { CART } from "@utils/common-static-data";

export const DEVICES_AND_ACCESSORIES = {
  PAGE_TITLE: "New devices and accessories order",
  SELECT_DEVICES_TAGLINE: "Select devices and accessories",
  CATEGORY_LABEL: "Browse by category",
  FILTER_LABEL: "Filter by",
  CLEAR_FILTER_LABEL: "Clear filters",
};

export const SELECT_DEVICES = {
  TABLE_COLUMN_HEADERS: {
    CATEGORY: "Category",
    MODEL: "Model",
    COLOUR: "Colour",
    STORAGE: "Storage",
    AVAILABILITY: "Availability",
    UNIT_CHARGE: "Unit charge (ex GST)",
    QUANTITY: "Quantity",
    REMOVE: "Remove",
  },
  ITEMS_ADDED: "item(s) added",
  VIEW_ITEMS_BUTTON: "View item(s)",
  DEVICE_PAYMENT_MSG1: "Device repayment options from",
  DEVICE_PAYMENT_MSG2: "per month",
  OUTRIGHT: "Outright",
  VIEWS: [
    {
      id: "table",
      label: "Table view",
      value: "table",
      active: false,
      disabled: false,
      icon: "icon-table-view",
    },
    {
      id: "grid",
      label: "Grid view",
      value: "grid",
      active: false,
      disabled: false,
      icon: "icon-grid-view",
    },
  ],
  SEARCH: "Search",
  SEARCH_HELPTEXT: "E.g. Apple iPhone 15 128GB",
  ITEM_COUNT_DISPLAY: (items, totalItems) =>
    `Showing ${items} of ${totalItems} Mobile devices in Mobile category`,
  LOAD_MORE: "Load more",
  GRID_INITIAL_COUNT: 6,
  CHARACTERISTICS: {
    SKU: "SKU",
  },
};

export const BACK_ORDER_MODAL = {
  TITLE: "One or more selected items are out of stock",
  DESCRIPTION:
    "Submitting an order with out-of-stock items will put the entire order in backorder. You can either change items now or proceed with out-of-stock items.",
  PRIMARY_BTN_LABEL: "Proceed with backorder",
  SECONDARY_BTN_LABEL: "Change items",
};

export const STOCK_STATUS = {
  IN_STOCK: "In stock",
  LIMITED_STOCK: "Low stock",
  OUT_OF_STOCK: "Back order",
};

export const CONFIGURE_DEVICES_AND_ACCESSORIES = {
  CONFIGURE_DEVICES_TAGLINE: "Configure devices and accessories",
  CONFIGURE_DEVICES_ACCESSORIES_TABLE: {
    ITEM: "Item",
    QUANTITY: "Quantity",
    PAYMENT_TYPE: "Payment type",
    UNIT_CHARGE: "Unit charge (ex GST)",
    REMOVE: "Remove",
    REMOVE_DEVICE_MODAL: {
      REMOVE: "Remove",
      CANCEL: "Cancel",
      TITLE: "Are you sure you want to remove this item?",
      DESCRIPTION: "This item will be permanently removed.",
    },
  },
  SKU: "SKU: ",
  OUTRIGHT: "Outright",
  GROUP_PAYMENT_TYPE: {
    LABEL: "Group payment type",
    MODAL: {
      ITEM_SELECTED_MSG: " items are selected to configure.",
      INFORMATION_MESSAGE:
        "The option of '24 Month repayment' is not available for selection for all selected items, because some of the selected items don't qualify for '24 Month repayments'.",
      CANCEL_BTN: "Cancel",
      CONFIRM_BTN: "Confirm",
    },
    DEVICE_TO_BE_SELECTED_INFORMATION: "Select at least one item to configure.",
  },
  PAYMENT_TYPE_OPTIONS: [
    {
      label: "Outright",
      value: "outright",
    },
    {
      label: "12 month repayment",
      value: CART.PRODUCTS.PAYMENT_12_MONTHS,
    },
    {
      label: "24 month repayment",
      value: CART.PRODUCTS.PAYMENT_24_MONTHS,
    },
  ],
};
