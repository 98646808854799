import "./style.scss";
import { Spacing, TextStyle, ActionButton } from "@able/react";
import { useNavigate } from "react-router-dom";

import { setCart } from "@state/cart-slice";
import { useAppDispatch } from "@state/hooks";
import { getPageDetails } from "./constant";
import { setError } from "@state/error";

interface ErrorScreenProps {
  error?: any;
  customErrorMsg?: string;
  showTryAgain?: boolean;
}

const ErrorScreen = (props: ErrorScreenProps) => {
  const {
    customErrorMsg,
    showTryAgain,
    error: { status: errorCode = 500 },
  } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const pageDetails = getPageDetails(errorCode);
  const { title, image, body, btnLabel } = pageDetails || {};
  const onRetry = () => {
    dispatch(setCart({ error: null }));
    dispatch(setError(false));
    navigate(0);
  };

  return (
    <div className="error-screen">
      <div className="column">
        <TextStyle alias="HeadingA" data-testid="error-title">
          {title}
        </TextStyle>
        <Spacing top="spacing2x" />
        <TextStyle alias="Base" data-testid="error-body">
          {customErrorMsg || body}
        </TextStyle>
        <Spacing top="spacing4x" />
        {showTryAgain && (
          <ActionButton
            variant="MediumEmphasis"
            label={btnLabel}
            element="button"
            events={{
              onClick: onRetry,
            }}
          />
        )}
      </div>
      <figure className="image" style={{ backgroundImage: `url(${image})` }} />
    </div>
  );
};

ErrorScreen.defaultProps = {
  error: {
    status: 500,
  },
  customErrorMsg: "",
  showTryAgain: true,
};

export default ErrorScreen;
