/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import { useEffect, useRef, useState } from "react";
import {
  TextField,
  TextStyle,
  Spacing,
  Select,
  ActionButton,
  ModalContent,
  MessageSection,
  Mobile56,
  MessageInline,
} from "@able/react";
import { ableDevelopmentUrl, REGULAR_EXPRESSIONS } from "@utils/helper";
import Countdown from "./count-down";
import AvailableNumberTable from "./phone-numbers-table";
import { useAppSelector, useAppDispatch } from "@state/hooks";
import {
  deleteSelectedNumbers,
  resetSearchNumbersFormState,
  setSearchNumbersFormState,
  setSearchResultsState,
  setValidationDetails,
} from "@state/sim-configuration-slice";
import { setSimConfigurationError } from "@state/error";
import { ERROR_TYPES, NEW_PLAN_ORDER } from "@pages/new-plan-order/constant";
import {
  useLazySwapNumbersQuery,
  useLazyUnlockNumbersQuery,
  useLazyFetchNumberQuery,
} from "@services/numbers";
import { SIM_CONFIG_ERRORS, TRY_AGAIN } from "@utils/common-static-data";

import { CONSTANTS } from "@services/constant";

const {
  ERROR_MESSAGES,
  INPUT_DETAILS: { SEARCH_NUMBER_QUANTITY, PATTERN_TYPE, PATTERN },
  BUTTON_LABELS,
  SECTION_MESSAGES,
  PATTEN_TYPE_OPTIONS_LABELS,
} = NEW_PLAN_ORDER.CUSTOM_NUMBER;

const BodyContent = ({
  handleAssignment,
  searchNumbersHandler,
  resetCounter,
  searchedInputValues,
  clickedOnAssign,
}) => {
  const dispatch = useAppDispatch();
  const paragraphRef = useRef<null | HTMLParagraphElement>(null);
  const { SIM_CONFIG_NUMBER_SEARCH, SIM_CONFIG_NUMBER_ASSIGNMENT } =
    ERROR_TYPES;
  const selectedPlans = useAppSelector(
    (state) => state.commonStore.selectedPlans
  );

  const {
    searchResultsState: { availableNumbers, fetchedNumbers },
    searchNumbersFormState,
    validationDetails,
    selectedNumbers,
  } = useAppSelector((state) => state.simConfiguration);

  const selectedPlansLength = selectedPlans.length;
  const totalPlansSelected = selectedPlansLength.toString();

  const simConfigError = useAppSelector(
    (state) => state.error.simConfigurationError
  );

  const { errorCode, errorDescription, errorType } = simConfigError || {};

  useEffect(() => {
    if (errorType === SIM_CONFIG_NUMBER_ASSIGNMENT && paragraphRef.current) {
      paragraphRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorType]);

  const actionCallbackHandler = (e) => {
    e.preventDefault();
    if (errorType === SIM_CONFIG_NUMBER_ASSIGNMENT) {
      handleAssignment();
    }
  };

  return (
    <>
      <Spacing bottom="spacing2x">
        <TextStyle alias="Base" element="div">
          <p ref={paragraphRef}>
            {NEW_PLAN_ORDER.CUSTOM_NUMBER.MESSAGE_1(totalPlansSelected)}
          </p>
        </TextStyle>
      </Spacing>
      <Spacing bottom="spacing2x">
        <TextStyle alias="Base" element="div">
          {NEW_PLAN_ORDER.CUSTOM_NUMBER.MESSAGE_2}
        </TextStyle>
      </Spacing>
      <Spacing bottom="spacing2x">
        <TextStyle alias="Base" element="div">
          {NEW_PLAN_ORDER.CUSTOM_NUMBER.MESSAGE_3}
        </TextStyle>
      </Spacing>
      <Spacing top="spacing2x" bottom="spacing5x">
        <div id="searchForm">
          <div className="form-field">
            <TextField
              label={SEARCH_NUMBER_QUANTITY.LABEL}
              id={SEARCH_NUMBER_QUANTITY.ID}
              name={SEARCH_NUMBER_QUANTITY.NAME}
              developmentUrl={ableDevelopmentUrl}
              events={{
                onChange: (element) => {
                  dispatch(
                    setSearchNumbersFormState({
                      ...searchNumbersFormState,
                      searchQuantity: element.target.value,
                    })
                  );
                },
              }}
              required={true}
              value={searchNumbersFormState.searchQuantity}
              invalid={validationDetails.searchQuantity.isInvalid}
              invalidInputText={validationDetails.searchQuantity.message}
            />
          </div>
          <div className="form-field">
            <Select
              label={PATTERN_TYPE.LABEL}
              id={PATTERN_TYPE.ID}
              options={NEW_PLAN_ORDER.CUSTOM_NUMBER.PATTERN_TYPE_OPTIONS}
              required={
                searchNumbersFormState.patternType !== "" ||
                searchNumbersFormState.pattern !== ""
              }
              developmentUrl={ableDevelopmentUrl}
              events={{
                onChange: (element) => {
                  dispatch(
                    setSearchNumbersFormState({
                      ...searchNumbersFormState,
                      patternType: element.target.value,
                    })
                  );
                },
              }}
              value={searchNumbersFormState.patternType}
              invalid={validationDetails.patternType.isInvalid}
            />
          </div>
          <div className="form-field">
            <TextField
              label={PATTERN.LABEL}
              id={PATTERN.ID}
              name={PATTERN.NAME}
              maxLength={searchNumbersFormState.patternType !== "" ? 7 : 8}
              required={
                searchNumbersFormState.patternType !== "" ||
                searchNumbersFormState.pattern !== ""
              }
              events={{
                onChange: (element) => {
                  dispatch(
                    setSearchNumbersFormState({
                      ...searchNumbersFormState,
                      pattern: element.target.value,
                    })
                  );
                },
              }}
              developmentUrl={ableDevelopmentUrl}
              value={searchNumbersFormState.pattern}
              invalid={validationDetails.pattern.isInvalid}
              invalidInputText={validationDetails.pattern.message}
            />
          </div>
          {fetchedNumbers && (
            <div className="form-field">
              <ActionButton
                element="button"
                label={BUTTON_LABELS.SEARCH_BUTTON}
                className="search-number-button"
                variant="MediumEmphasis"
                events={{
                  onClick: searchNumbersHandler,
                }}
              />
            </div>
          )}
        </div>
      </Spacing>
      {errorCode === 500 &&
        (errorType === SIM_CONFIG_NUMBER_ASSIGNMENT ||
          errorType === SIM_CONFIG_NUMBER_SEARCH) && (
          <Spacing bottom="spacing2x">
            <MessageSection
              variant="Error"
              developmentUrl={ableDevelopmentUrl}
              description={errorDescription}
              actionText={
                errorType === SIM_CONFIG_NUMBER_ASSIGNMENT ? TRY_AGAIN : ""
              }
              actionElement="button"
              className="message-section"
              actionCallback={actionCallbackHandler}
            />
          </Spacing>
        )}
      <Spacing bottom="spacing5x">
        {fetchedNumbers ? (
          availableNumbers.length > 0 ? (
            <>
              <Countdown resetCounter={resetCounter} />
              {availableNumbers.length <
                Number(searchedInputValues.searchQuantity) && (
                <Spacing top="spacing3x">
                  <MessageSection
                    variant="Attention"
                    developmentUrl={ableDevelopmentUrl}
                    className="messagebox"
                    description={SECTION_MESSAGES.LESS_THAN_REQUESTED(
                      availableNumbers.length,
                      PATTEN_TYPE_OPTIONS_LABELS[
                        searchedInputValues.patternType
                      ],
                      searchedInputValues.pattern
                    )}
                  />
                </Spacing>
              )}
              <div id="selected-numbers-error">
                {(selectedNumbers.length > selectedPlans.length ||
                  (clickedOnAssign && selectedNumbers.length === 0)) && (
                  <Spacing top="spacing3x">
                    <MessageInline
                      variant="Error"
                      developmentUrl={ableDevelopmentUrl}
                      className="messagebox"
                      description={
                        selectedNumbers.length === 0
                          ? SECTION_MESSAGES.SELECTED_NONE
                          : SECTION_MESSAGES.SELECTED_MORE(selectedPlans.length)
                      }
                    />
                  </Spacing>
                )}
              </div>
              <Spacing top="spacing3x">
                <AvailableNumberTable />
              </Spacing>
            </>
          ) : (
            <MessageInline
              variant="Error"
              developmentUrl={ableDevelopmentUrl}
              className="messagebox"
              description={SECTION_MESSAGES.NO_NUMBERS}
            />
          )
        ) : null}
      </Spacing>
    </>
  );
};

const FooterContent = ({
  handleAssignment,
  searchNumbersHandler,
  handleClose,
  toggleModal,
}) => {
  const {
    searchResultsState: { availableNumbers, fetchedNumbers },
  } = useAppSelector((state) => state.simConfiguration);

  return (
    <>
      <ActionButton
        developmentUrl={ableDevelopmentUrl}
        element="button"
        variant="MediumEmphasis"
        label={BUTTON_LABELS.CANCEL_BUTTON}
        events={{
          onClick: () => {
            handleClose();
            toggleModal();
          },
        }}
      />
      {fetchedNumbers ? (
        availableNumbers.length > 0 ? (
          <ActionButton
            developmentUrl={ableDevelopmentUrl}
            element="button"
            variant="HighEmphasis"
            label={BUTTON_LABELS.ASSIGN_BUTTON}
            events={{ onClick: handleAssignment }}
          />
        ) : null
      ) : (
        <ActionButton
          developmentUrl={ableDevelopmentUrl}
          element="button"
          variant="HighEmphasis"
          label={BUTTON_LABELS.SEARCH_NUMBERS_BUTTON}
          events={{ onClick: searchNumbersHandler }}
        />
      )}
    </>
  );
};

const CustomPhoneNumberModal = (props) => {
  const { modalIsOpen, toggleModal } = props;
  const dispatch = useAppDispatch();
  const {
    SIM_CONFIG_NUMBER,
    SIM_CONFIG_NUMBER_ASSIGNMENT,
    SIM_CONFIG_NUMBER_PARTIAL_SUCCESS,
    SIM_CONFIG_NUMBER_SEARCH,
  } = ERROR_TYPES;
  const [fetchNumbers] = useLazyFetchNumberQuery();
  const [swapNumbers] = useLazySwapNumbersQuery();
  const [unlockNumbers] = useLazyUnlockNumbersQuery();

  const selectedPlans = useAppSelector(
    (state) => state.commonStore.selectedPlans
  );
  const plans = useAppSelector((state) => state.cart.normalizedPlansQBS);
  const {
    selectedNumbers,
    searchResultsState: { availableNumbers },
    searchNumbersFormState,
    validationDetails,
  } = useAppSelector((state) => state.simConfiguration);

  const [validateFields, setValidateFields] = useState(false);
  const [resetCounter, setResetCounter] = useState(false);
  const [searchedInputValues, setSearchedInputValues] = useState({
    searchQuantity: "",
    patternType: "",
    pattern: "",
  });
  const [clickedOnAssign, setClickedOnAssign] = useState(false);

  const handleClose = async () => {
    if (availableNumbers.length !== 0) {
      const numbersForUnassignment = availableNumbers.map(
        (currentNumber) => currentNumber.number
      );
      try {
        await unlockNumbers(numbersForUnassignment).unwrap();
        dispatch(deleteSelectedNumbers());
      } catch (err: any) {
        const { status } = err;
        dispatch(
          setSimConfigurationError({
            errorType: SIM_CONFIG_NUMBER,
            errorCode: status,
            errorDescription: SIM_CONFIG_ERRORS[status]?.ASSIGN_NUMBERS,
          })
        );
      }
    }
    dispatch(deleteSelectedNumbers());
    dispatch(resetSearchNumbersFormState());
    dispatch(
      setSearchResultsState({
        availableNumbers: [],
        fetchedNumbers: false,
      })
    );
  };

  const handleAssignment = async () => {
    setClickedOnAssign(true);
    if (
      selectedNumbers.length === 0 ||
      selectedNumbers.length > selectedPlans.length
    ) {
      document
        .getElementById("selected-numbers-error")
        .scrollIntoView({ behavior: "smooth" });
      return;
    }

    const plansToBeUpdated = [];
    const numbersForUnassignment = [];
    const selectedIds = [];

    const allSetIds = {};

    plans.forEach((plan, i) => {
      Object.keys(plan.setIds).forEach((setId) => {
        allSetIds[setId] = plan.setIds[setId];
      });
    });

    selectedPlans.forEach((plan) => {
      const [setId, planId] = plan.split(":");
      selectedIds.push({ setId, planId });
      plansToBeUpdated.push(plan);
      numbersForUnassignment.push(allSetIds[setId].phoneNumber);
    });

    const newSelectedPlans = selectedPlans.filter(
      (planId) => !plansToBeUpdated.includes(planId)
    );

    try {
      const res = await swapNumbers({
        selectedNumbers,
        numbersForUnassignment,
        newSelectedPlans,
        selectedIds,
      }).unwrap();
      if (res.code === 200) {
        dispatch(setSimConfigurationError(null));
        if (res.responseCode === "PARTIAL_SUCCESS") {
          dispatch(
            setSimConfigurationError({
              errorType: SIM_CONFIG_NUMBER_PARTIAL_SUCCESS,
              errorCode: res.code,
              errorDescription: SIM_CONFIG_ERRORS[res.code].PARTIAL_SUCCESS,
            })
          );
        }
        dispatch(deleteSelectedNumbers());
        dispatch(
          setSearchResultsState({
            availableNumbers: [],
            fetchedNumbers: false,
          })
        );
        dispatch(resetSearchNumbersFormState());
        toggleModal();
      }
    } catch (err: any) {
      const { status } = err;
      dispatch(
        setSimConfigurationError({
          errorType: SIM_CONFIG_NUMBER_ASSIGNMENT,
          errorCode: status,
          errorDescription: SIM_CONFIG_ERRORS[status]?.ASSIGN_NUMBERS,
        })
      );
    }
  };

  const searchNumbersHandler = async () => {
    setValidateFields(true);
    setClickedOnAssign(false);
    dispatch(
      setSearchResultsState({
        availableNumbers: [],
        fetchedNumbers: false,
      })
    );
    const { searchQuantity, patternType, pattern } = searchNumbersFormState;
    if (
      validationDetails.searchQuantity.isInvalid ||
      searchQuantity === "" ||
      Number(searchQuantity) === 0 ||
      validationDetails.pattern.isInvalid ||
      (pattern !== "" && patternType === "") ||
      (patternType !== "" && pattern === "")
    ) {
      return;
    } else {
      try {
        const params = { ...searchNumbersFormState };

        if (availableNumbers.length !== 0) {
          const numbersForUnassignment = availableNumbers.map(
            (currentNumber) => currentNumber.number
          );
          params.numbersForUnassignment = numbersForUnassignment;
          params.action = CONSTANTS.NUMBERS.LOCK_UNLOCK;
        }
        dispatch(deleteSelectedNumbers());
        const { data } = await fetchNumbers(params).unwrap();
        if (data?.numbers.length > 0) {
          setResetCounter((prev) => !prev);
        }
        setSearchedInputValues({ searchQuantity, patternType, pattern });
        dispatch(
          setSearchResultsState({
            availableNumbers: data?.numbers,
            fetchedNumbers: true,
          })
        );
        dispatch(setSimConfigurationError(null));
      } catch (err: any) {
        const { status } = err;
        if (status === 404) {
          dispatch(
            setSearchResultsState({
              availableNumbers: [],
              fetchedNumbers: true,
            })
          );
        }

        dispatch(
          setSimConfigurationError({
            errorType: SIM_CONFIG_NUMBER_SEARCH,
            errorCode: status,
            errorDescription: SIM_CONFIG_ERRORS[status]?.FETCH_NUMBERS,
          })
        );
      }
    }
  };

  const validateAllInputs = ({ searchQuantity, patternType, pattern }) => {
    let isQuantityInvalid = false;
    let quantityInvalidmessage = "";
    if (searchQuantity !== "") {
      if (!REGULAR_EXPRESSIONS.NUMBERS_ONLY.test(searchQuantity)) {
        isQuantityInvalid = true;
        quantityInvalidmessage = ERROR_MESSAGES.NUMBERS_ONLY;
      } else if (validateFields && Number(searchQuantity) === 0) {
        isQuantityInvalid = true;
        quantityInvalidmessage = ERROR_MESSAGES.QUANTITY_MORE_THAN_ZERO;
      }
    } else if (validateFields) {
      isQuantityInvalid = true;
    }

    let isPatternTypeInvalid = false;
    if (validateFields) {
      if (pattern !== "" && patternType === "") {
        isPatternTypeInvalid = true;
      }
    }

    let isPatternInvalid = false;
    let patternInValidmessage = "";
    if (pattern !== "") {
      if (!REGULAR_EXPRESSIONS.NUMBERS_ONLY.test(pattern)) {
        isPatternInvalid = true;
        patternInValidmessage = ERROR_MESSAGES.NUMBERS_ONLY;
      }
    } else {
      if (validateFields && patternType !== "") {
        isPatternInvalid = true;
      }
    }

    dispatch(
      setValidationDetails({
        searchQuantity: {
          isInvalid: isQuantityInvalid,
          message: quantityInvalidmessage,
        },
        patternType: {
          isInvalid: isPatternTypeInvalid,
          message: "",
        },
        pattern: {
          isInvalid: isPatternInvalid,
          message: patternInValidmessage,
        },
      })
    );
  };

  useEffect(() => {
    dispatch(
      setSearchNumbersFormState({
        ...searchNumbersFormState,
        searchQuantity: selectedPlans.length.toString(),
      })
    );
  }, []);

  useEffect(() => {
    validateAllInputs(searchNumbersFormState);
  }, [searchNumbersFormState, validateFields]);

  useEffect(() => {
    setValidateFields(false);
    setClickedOnAssign(false);
  }, [searchNumbersFormState]);

  useEffect(() => {
    if (
      selectedNumbers.length > selectedPlans.length ||
      (clickedOnAssign && selectedNumbers.length === 0)
    ) {
      document
        .getElementById("selected-numbers-error")
        .scrollIntoView({ behavior: "smooth" });
    }
    if (selectedNumbers.length > 0) {
      setClickedOnAssign(false);
    }
  }, [selectedPlans, selectedNumbers, clickedOnAssign]);

  return (
    <ModalContent
      developmentUrl={ableDevelopmentUrl}
      picto={<Mobile56 />}
      title={NEW_PLAN_ORDER.CUSTOM_NUMBER.SEARCH_TEXT}
      variant="Expansive"
      bodyContent={
        <BodyContent
          handleAssignment={handleAssignment}
          searchNumbersHandler={searchNumbersHandler}
          resetCounter={resetCounter}
          searchedInputValues={searchedInputValues}
          clickedOnAssign={clickedOnAssign}
        />
      }
      footerContent={
        <FooterContent
          handleAssignment={handleAssignment}
          searchNumbersHandler={searchNumbersHandler}
          handleClose={handleClose}
          toggleModal={toggleModal}
        />
      }
      isShowing={modalIsOpen}
      setHideDialog={toggleModal}
      onClose={handleClose}
    />
  );
};

export default CustomPhoneNumberModal;
