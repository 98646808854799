import {
  ActionButton,
  Column,
  Grid,
  Select,
  Spacing,
  TextStyle,
} from "@able/react";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { ableDevelopmentUrl } from "@utils/helper";
import { SIM_TYPE_CONFIG } from "@pages/new-plan-order/steps/sim-configuration/bulk-config/constants";
import { useGetConfigsQuery } from "@services/config";
import { useState } from "react";
import { updateSimType } from "@state/cart-slice";
import { toggleAll } from "@state/common-slice";

export default function SimType({ onClose }) {
  const [selectedSimType, setSelectedSimType] = useState<string>("");
  const [invalid, setInvalid] = useState<boolean>(false);
  const selectedPlans = useAppSelector(
    (store) => store.commonStore.selectedPlans
  );
  const dispatch = useAppDispatch();

  const { data: configs = { SIM_TYPE_OPTIONS: [] } } = useGetConfigsQuery();
  const onSimTypeChange = (e) => {
    setSelectedSimType(e.target.value);
    setInvalid(false);
  };
  const setSimTypeToPlans = () => {
    if (selectedSimType.length < 1) {
      setInvalid(true);
    } else {
      const selectedIds = [];
      selectedPlans.forEach((plan) => {
        const [setId, planId] = plan.split(":");
        selectedIds.push({ setId, planId });
      });
      const payload = {
        selectedIds,
        fields: {
          simSerialNumber: "",
          invalid_SIM_number: false,
          invalid_UNMS_SIM_number: false,
          invalid_SIM_type: false,
          simType: selectedSimType,
          planUpdated: true,
        },
      };
      dispatch(updateSimType(payload));
      dispatch(toggleAll([]));
      onClose();
    }
  };
  return (
    <Grid padding={false} className="sim-type-grid">
      <Column cols={12} bsm={12} bmd={12} blg={12}>
        <TextStyle alias="FinePrint">
          {SIM_TYPE_CONFIG.PLANS_SELECTED(selectedPlans.length)}
        </TextStyle>
      </Column>
      <Column cols={12} bsm={4} bmd={4} blg={4}>
        <Spacing top="spacing4x">
          <Select
            id={"simType-bulk-modal"}
            label={SIM_TYPE_CONFIG.LABEL}
            options={configs?.SIM_TYPE_OPTIONS}
            value={selectedSimType}
            invalid={invalid}
            events={{
              onChange: onSimTypeChange,
            }}
            developmentUrl={ableDevelopmentUrl}
          />
        </Spacing>
      </Column>
      <Spacing top="spacing2x" />

      <Column className="sim-type-modal-footer">
        <ActionButton
          label={SIM_TYPE_CONFIG.CANCEL}
          variant="MediumEmphasis"
          element="button"
          className="sim-type-modal-btn"
          events={{
            onClick: onClose,
          }}
        />
        <Spacing left="spacing2x" />

        <ActionButton
          label={SIM_TYPE_CONFIG.CONFIRM}
          variant="HighEmphasis"
          element="button"
          className="sim-type-modal-btn"
          events={{
            onClick: setSimTypeToPlans,
          }}
        />
      </Column>
    </Grid>
  );
}
