const eligibleRequesterRoles = ["full authority", "limited authority"];

export const getEligibleRequesterContacts = (contactsList) => {
  let eligibleContacts = [];
  if (contactsList && contactsList.length) {
    eligibleContacts = contactsList.filter(
      (el) => eligibleRequesterRoles.indexOf(el.role.toLowerCase()) >= 0
    );
  }
  return eligibleContacts;
};
