// eslint-disable-next-line import/no-extraneous-dependencies
import ableIconUrl from "@able/web/dist/symbol/able-sprites.svg";
import { TextStyle } from "@able/react";
import { useEffect, useState } from "react";
import {
  useLocation,
  useSearchParams,
  createSearchParams,
  useNavigate,
} from "react-router-dom";
import { ICartState } from "@state/select-plans-slice";
import { CUSTOM_ERRORS, ERRORS, PATHS } from "./common-static-data";
import {
  updateBillingAccount,
  updateQBSPlansArrUsingIndex,
} from "@state/cart-slice";
import {
  setIsPlanFieldsInvalid,
  setPagination,
} from "@state/sim-configuration-slice";
import { ERROR_TYPES } from "@pages/new-plan-order/constant";
import { setPhoenixNumber } from "@state/checkout-slice";
import {
  setError,
  setErrorMessage,
  setCheckoutError,
  setIsValidateRefNumberError,
  setSimConfigurationError,
} from "@state/error";
import { NEW_ORDER_DETAILS } from "@pages/new-order-details/constant";
import { CartItem, PatchCartParams } from "@state/types";
import {
  generatePatchCartOnCheckout,
  generatePatchCartOnSimConfiguration,
  generateSelectDevicesPatchCartBody,
  generateSelectPlansPatchCartBody,
} from "@services/templates/cart";
import { updateBackOrderStatus } from "@state/select-devices-slice";
import { useGetFeatureFlagsQuery } from "@services/base";
import { getLocalFeatureFlags } from "@config/index";

export const ableDevelopmentUrl = ableIconUrl;
const { SIM_CONFIG_NUMBER_REFRESH, SIM_CONFIG_PATCH_CART } = ERROR_TYPES;

export const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

export const renderBoldText = (label: string) => {
  return (
    <div className="text-icon-wrapper">
      <TextStyle alias="Label">{label}</TextStyle>
    </div>
  );
};

export const renderLabel = (value: string, className?: string) => {
  return (
    <TextStyle alias="Label" className={className}>
      {value}
    </TextStyle>
  );
};

export const renderText = (value: string, className?: string) => {
  return <TextStyle className={className}>{value}</TextStyle>;
};

export const REGULAR_EXPRESSIONS = {
  NUMBERS_ONLY: /^[0-9\b]+$/,
  USERNAME: /^[0-9a-zA-Z'-]*$/,
  PHOENIX: /^[0-9]{8}$/,
};

export const isInternalReferenceNumberInvalid = (
  watchInternalReferenceNumber: any
) => {
  const pattern = /^TRN[0-9]{10}$|TNC[0-9]{9}$|GESPO[0-9]{9}$|TBAPO[0-9]{9}$/;
  const phoenixPattern = /^[0-9]{8}$/;
  if (
    !(
      (watchInternalReferenceNumber.length === 8 &&
        phoenixPattern.test(watchInternalReferenceNumber)) ||
      pattern.test(watchInternalReferenceNumber)
    )
  ) {
    return true;
  }
  return false;
};

export const useReloadHook = () => {
  useEffect(() => {
    const onUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", onUnload);

    return () => window.removeEventListener("beforeunload", onUnload);
  }, []);
};

export const useGetFeatureFlagHook = () => {
  const { data, isSuccess } = useGetFeatureFlagsQuery();
  return isSuccess ? data?.featureFlags : getLocalFeatureFlags().featureFlags;
};

export function buildPath(path: string, cartId: string, queryParams?: string) {
  return queryParams ? `${path}/${cartId}${queryParams}` : `${path}/${cartId}`;
}

export const dispatchRequiredBillingError = (dispatch, billingAccount) => {
  window.scrollTo({
    top: 180,
    behavior: "smooth",
  });
  dispatch(
    updateBillingAccount({
      billingAccount,
      invalidBillingAccount: false,
      requiredBillingError: true,
    })
  );
};

const getPatchCartAndNavigate = async (
  patchCart,
  requestObjForCheckout,
  navigate,
  cartId,
  dispatch
) => {
  const params: PatchCartParams = {
    payload: generatePatchCartOnCheckout(requestObjForCheckout),
    product: "all",
    tapasAction: "SO",
  };
  try {
    const patchCartRes = await patchCart(params).unwrap();
    if (patchCartRes.code === 200) {
      dispatch(setCheckoutError(null));
      dispatch(setError(false));
      navigate(buildPath(PATHS.ORDER_CONFIRMATION, cartId));
    }
  } catch (error: any) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let errorDescription = "";

    let status;
    if (error && "status" in error) {
      status = error?.status;
      errorDescription = ERRORS[status];
    }
    dispatch(
      setCheckoutError({
        errorObj: error,
        errorDescription,
      })
    );
    if (status && status !== 500) {
      dispatch(setError(true));
    }
  }
};

export async function checkoutSubmitHandler(
  internalReferenceNumber: string,
  getInternalReferenceNumber: Function,
  dispatch: Function,
  triggerValidateRefNumberQuery: Function,
  patchCart: Function,
  requestObjForCheckout,
  navigate: Function,
  cartId: string
) {
  if (!isInternalReferenceNumberInvalid(internalReferenceNumber)) {
    if (
      getInternalReferenceNumber() != internalReferenceNumber &&
      internalReferenceNumber.length === 8
    ) {
      dispatch(setPhoenixNumber(internalReferenceNumber));
      try {
        const res = await triggerValidateRefNumberQuery(
          internalReferenceNumber
        ).unwrap();
        if (res.isValid) {
          getPatchCartAndNavigate(
            patchCart,
            requestObjForCheckout,
            navigate,
            cartId,
            dispatch
          );
        } else {
          dispatch(setErrorMessage(NEW_ORDER_DETAILS.PHOENIX_ERROR));
          dispatch(setIsValidateRefNumberError(true));
        }
        dispatch(setCheckoutError(null));
        dispatch(setError(false));
      } catch (error: any) {
        dispatch(
          setCheckoutError({
            errorObj: error,
          })
        );
        dispatch(setError(true));
      }
    } else {
      getPatchCartAndNavigate(
        patchCart,
        requestObjForCheckout,
        navigate,
        cartId,
        dispatch
      );
    }
  } else if (internalReferenceNumber.length === 0) {
    dispatch(setIsValidateRefNumberError(true));
  }
}

export async function patchCartHandler(
  patchCart: Function,
  itemsInCart: ICartState,
  dispatch: Function,
  setCart: Function,
  navigate: Function,
  nextPagePath: string,
  cartId: string,
  billingAccount: string
) {
  const isFormInvalid =
    itemsInCart?.selectedOffers?.length <= 0 || billingAccount?.length <= 0;
  if (isFormInvalid) {
    if (itemsInCart?.selectedOffers?.length <= 0) {
      dispatch(setCart({ error: CUSTOM_ERRORS[1000] }));
      const selectedItemsDiv = document.getElementsByClassName(
        "selected-items-text"
      )[0];
      selectedItemsDiv?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
    if (billingAccount?.length <= 0)
      dispatchRequiredBillingError(dispatch, billingAccount);
    return;
  } else {
    const params: PatchCartParams = {
      payload: generateSelectPlansPatchCartBody({
        ...itemsInCart,
        billingAccount,
        cartId,
      }),
      product: "plan",
    };
    try {
      const res = await patchCart(params).unwrap();
      if (res.code === 200) {
        dispatch(setCart({ error: null }));
        dispatch(setError(false));
        navigate(buildPath(nextPagePath, cartId));
      }
    } catch (error: any) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      let errorDescription = "";
      let errorCode: number;

      if ("status" in error) {
        const { status } = error;
        errorCode = status;
        errorDescription = ERRORS[status];
      }

      dispatch(
        setCart({
          error: {
            errorCode,
            errorDescription,
          },
        })
      );
      dispatch(setError(true));
    }
  }
}

export const prefixWithDollarSign = (value) => {
  return "$" + value;
};

const invalidSimSerialData = (res, dispatch) => {
  const errors = res.errors;
  if (errors && errors.length) {
    errors.forEach((e) => {
      if (e.invalidSimSerialNumbers.length) {
        dispatch(setIsPlanFieldsInvalid(true));
      }
    });
    window.scrollTo({
      top: 500,
      behavior: "smooth",
    });
  }
};

const setPageOneAndNavigate = async (
  navigation,
  dispatch,
  navigate,
  paginationState,
  requestObjForSimConfiguration,
  patchCart
) => {
  const { plansInfo, cartId } = requestObjForSimConfiguration;
  if (paginationState.page !== 1) {
    await dispatch(
      setPagination({
        data: plansInfo.slice(0, paginationState.pageSize),
        pageSize: paginationState.pageSize,
        totalRecordLength: plansInfo.length,
        pages: Math.ceil(plansInfo.length / paginationState.pageSize),
        page: 1,
        recordsPerApi: paginationState.pageSize,
      })
    );
  }
  if (navigation) {
    const params: PatchCartParams = {
      payload: generatePatchCartOnSimConfiguration(
        requestObjForSimConfiguration
      ),
      product: "plan",
      tapasAction: "VS",
    };
    try {
      const res = await patchCart(params).unwrap();
      if (res.code === 200) {
        navigate(buildPath(PATHS.CART, cartId));
      } else if (res.code === 206 && res.responseCode === "PARTIAL_SUCCESS") {
        invalidSimSerialData(res, dispatch);
      }
    } catch (err: any) {
      const { status } = err;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      dispatch(
        setSimConfigurationError({
          errorType: SIM_CONFIG_PATCH_CART,
          errorCode: status,
          errorDescription: ERRORS[status],
        })
      );
    }
  }
};

export const simConfigPatchCartHandler = (
  dispatch,
  navigate,
  paginationState,
  requestObjForSimConfiguration,
  patchCart
) => {
  const { plansInfo } = requestObjForSimConfiguration;
  const invalidSIMPlans = [];
  const invalidSIMPlansSetId = [];
  const invalidSIMTypePlans = [];
  const invalidSIMTypePlansSetId = [];
  const SIMSerialNumbers = {};
  let duplicatesSIMNumbersIndexes = [];
  let duplicatesSIMNumbersSetIds = [];
  let nonDuplicateSIMNumbersIndexes = [];
  let nonDuplicateSIMNumbersSetIds = [];
  let invalidSimorUsernameorUNMSSim = false;

  plansInfo.forEach((plan, index) => {
    const { setIds } = plan;
    Object.keys(setIds).forEach((setId) => {
      if (plan.setIds[setId].simType === "") {
        invalidSIMTypePlans.push(index);
        invalidSIMTypePlansSetId.push(setId);
      } else if (
        plan.setIds[setId].simType !== undefined &&
        plan.setIds[setId].simType !== "SIM Card" &&
        plan.setIds[setId].simType !== "eSIM" &&
        plan.setIds[setId].simType !== "" &&
        plan.setIds[setId].simSerialNumber === ""
      ) {
        invalidSIMPlans.push(index);
        invalidSIMPlansSetId.push(setId);
      } else if (plan.setIds[setId].simSerialNumber.length === 13) {
        if (!SIMSerialNumbers[plan.setIds[setId].simSerialNumber]) {
          SIMSerialNumbers[plan.setIds[setId].simSerialNumber] = [];
        }
        SIMSerialNumbers[plan.setIds[setId].simSerialNumber].push({
          index,
          setId,
        });
      }
      if (plan.setIds[setId].invalid_username === true) {
        invalidSimorUsernameorUNMSSim = true;
      }
      if (plan.setIds[setId].invalid_UNMS_SIM_number === true) {
        invalidSimorUsernameorUNMSSim = true;
      }
      if (
        plan.setIds[setId].invalid_SIM_number &&
        plan.simSerialNumber?.length !== 13
      ) {
        invalidSimorUsernameorUNMSSim = true;
      }
    });
  });

  if (Object.keys(SIMSerialNumbers).length) {
    Object.values(SIMSerialNumbers).forEach((SIMsIndex: any) => {
      if (SIMsIndex.length > 1) {
        duplicatesSIMNumbersIndexes = SIMsIndex.map((item) => item.index);
        duplicatesSIMNumbersSetIds = SIMsIndex.map((item) => item.setId);
      } else {
        nonDuplicateSIMNumbersIndexes = SIMsIndex.map((item) => item.index);
        nonDuplicateSIMNumbersSetIds = SIMsIndex.map((item) => item.setId);
      }
    });
  }

  if (invalidSIMTypePlans.length) {
    const payload = {
      planIndexes: invalidSIMTypePlans,
      setIds: invalidSIMTypePlansSetId,
      fields: {
        invalid_SIM_type: true,
      },
      isAnyFieldInSetInvalid: true,
    };
    dispatch(updateQBSPlansArrUsingIndex(payload));
  }

  if (
    invalidSIMPlans.length ||
    duplicatesSIMNumbersIndexes.length ||
    invalidSIMPlansSetId.length
  ) {
    const invalidSIMs = invalidSIMPlans.concat(duplicatesSIMNumbersIndexes);
    const invalidSIMSetIds = invalidSIMPlansSetId.concat(
      duplicatesSIMNumbersSetIds
    );
    const payload = {
      planIndexes: invalidSIMs,
      setIds: invalidSIMSetIds,
      fields: {
        invalid_SIM_number: true,
      },
      isAnyFieldInSetInvalid: true,
    };
    dispatch(updateQBSPlansArrUsingIndex(payload));
  }

  if (nonDuplicateSIMNumbersIndexes.length) {
    const payload = {
      planIndexes: nonDuplicateSIMNumbersIndexes,
      setIds: nonDuplicateSIMNumbersSetIds,
      fields: {
        invalid_SIM_number: false,
      },
    };
    dispatch(updateQBSPlansArrUsingIndex(payload));
  }

  if (
    invalidSIMPlans.length ||
    invalidSIMTypePlans.length ||
    duplicatesSIMNumbersIndexes.length ||
    invalidSimorUsernameorUNMSSim
  ) {
    window.scrollTo({
      top: 500,
      behavior: "smooth",
    });
    dispatch(setIsPlanFieldsInvalid(true));
    dispatch(updateQBSPlansArrUsingIndex({ sort: true }));
    setPageOneAndNavigate(
      false,
      dispatch,
      navigate,
      paginationState,
      requestObjForSimConfiguration,
      patchCart
    );
  } else {
    dispatch(setIsPlanFieldsInvalid(false));
    setPageOneAndNavigate(
      true,
      dispatch,
      navigate,
      paginationState,
      requestObjForSimConfiguration,
      patchCart
    );
  }
};

export async function refreshPhoneNumber(
  dispatch,
  setPlanId,
  planId,
  refreshNumber,
  plan,
  planIndex,
  SIM_CONFIG_ERRORS
) {
  const params = {
    currentNumber: plan.phoneNumber,
    refreshCount: plan.refreshCount || 0,
    planIndex,
  };

  try {
    await refreshNumber(params).unwrap();
    dispatch(setSimConfigurationError(null));
  } catch (err: any) {
    const { status } = err;
    dispatch(
      setSimConfigurationError({
        errorType: SIM_CONFIG_NUMBER_REFRESH,
        errorCode: status,
        errorDescription: SIM_CONFIG_ERRORS[status]?.ASSIGN_NUMBERS,
      })
    );
    dispatch(setPlanId(planId));
  }
}

export const changeColorCase = (color) => {
  if (color) {
    return `${color[0]}${color.substring(1).toLowerCase()}`;
  }
  return "";
};

export const useIntersectionObserver = (ref, options) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  useEffect(() => {
    const element = ref.current;
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    if (element) {
      observer.observe(element);
    }
    return () => {
      if (element) observer.unobserve(element);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isIntersecting;
};

export async function configureDevicesPagePatchCartHandler({
  cartId,
  deltaPlans,
  patchCart,
  setCart,
  navigate,
  dispatch,
  normalizedDevicesAndAccesories,
  setRequiredForPaymentType,
}) {
  const deviceIds = [];
  normalizedDevicesAndAccesories.forEach((item) => {
    if (item.paymentType === "") {
      deviceIds.push(item.id);
    }
  });
  if (deviceIds.length > 0) {
    dispatch(setRequiredForPaymentType(deviceIds));
  } else {
    const cartItems: CartItem[] = Object.values(deltaPlans);
    if (cartItems.length) {
      const params: PatchCartParams = {
        product: "device",
        payload: {
          cartId,
          cartItems,
        },
        resetDelta: true,
      };
      try {
        const res = await patchCart(params).unwrap();
        if (res.code === 200) {
          dispatch(setCart({ error: null }));
          dispatch(setError(false));
          navigate(buildPath(PATHS.CART, cartId));
        }
      } catch (error: any) {
        if (error.status === 500) {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          dispatch(setError(false));
        } else {
          dispatch(setError(true));
        }
        dispatch(setCart({ error }));
      }
    } else {
      navigate(buildPath(PATHS.CART, cartId));
    }
  }
}

export async function cartPagePatchCartHandler({
  cartId,
  deltaPlans,
  normalizedPlansQBS = [],
  initialPlans = [],
  patchCart,
  navigate,
  dispatch,
  setCart,
  product,
}) {
  const cartItems: CartItem[] =
    product === "plan"
      ? getCartItems(initialPlans, normalizedPlansQBS)
      : Object.values(deltaPlans);

  if (cartItems.length) {
    const params: PatchCartParams = {
      product,
      payload: { cartId, cartItems },
      resetDelta: true,
    };

    try {
      const res = await patchCart(params).unwrap();
      if (res.code === 200) {
        dispatch(setCart({ error: null }));
        dispatch(setError(false));
        navigate(buildPath(PATHS.CHECKOUT, cartId));
      }
    } catch (error: any) {
      const errorCode = error.status;
      let errorDescription = "";

      if (errorCode === 500) {
        errorDescription = ERRORS[errorCode];
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        dispatch(setError(false));
      } else {
        dispatch(setError(true));
      }
      dispatch(
        setCart({
          error: {
            errorCode,
            errorDescription,
          },
        })
      );
    }
  } else navigate(buildPath(PATHS.CHECKOUT, cartId));
}

export async function selectDevicesPatchCartHandler({
  cartId,
  billingAccount,
  selectedDevices,
  itemsInCart,
  backOrder,
  setCart,
  dispatch,
  patchCart,
  reduxStore,
  navigate,
}) {
  const isFormInvalid =
    billingAccount?.length <= 0 || selectedDevices.length <= 0;
  if (isFormInvalid) {
    if (selectedDevices.length <= 0) {
      dispatch(setCart({ error: CUSTOM_ERRORS[1000] }));
      const errorMessageElement = document.querySelector(".add-devices-error");
      errorMessageElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
    if (billingAccount?.length <= 0) {
      dispatchRequiredBillingError(dispatch, itemsInCart);
    }
  } else {
    const backorders = selectedDevices.filter(
      (el) => el.stockStatus === "OUT_OF_STOCK"
    );
    if (backorders.length > 0 && !backOrder) {
      dispatch(updateBackOrderStatus(true));
    } else {
      try {
        const res = await patchCart({
          product: "device",
          payload: generateSelectDevicesPatchCartBody(reduxStore),
        }).unwrap();
        if (res.code === 200) {
          navigate(buildPath(PATHS.CONFIGURE_DEVICES, cartId));
        }
      } catch (error: any) {
        if (error && "status" in error && error.status === 500) {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          dispatch(setError(false));
        } else {
          dispatch(setError(true));
        }
        dispatch(
          setCart({
            error,
          })
        );
      }
    }
  }
}

export const useCustomNavigate = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const cidn = queryParams.get("cidn");
  const usertype = queryParams.get("usertype");

  return (LINK: string, cartId?: string) => {
    let path;
    if (cartId) path = buildPath(LINK, cartId);
    else path = LINK;
    return navigate({
      pathname: path,
      search: createSearchParams({
        usertype,
        cidn,
      }).toString(),
    });
  };
};

export function generateTableData(
  items,
  pageSize,
  dispatch,
  setPaginationDetails
) {
  return function (pageNum, sorted = [{}], pageSizeVal = 0, event = "") {
    const totalItems = items?.length;

    if (totalItems) {
      let pSize = pageSize;
      const resultData = [];
      let lastIndex, startIndex;

      pSize = pageSizeVal > 0 ? pageSizeVal : pSize;

      lastIndex = pageNum * pSize;

      if (lastIndex > totalItems) {
        lastIndex = totalItems;
      }

      startIndex = lastIndex - pSize;

      if (pSize * pageNum > totalItems) {
        startIndex = pSize * (pageNum - 1);
      }

      if (pSize * pageNum > totalItems) {
        startIndex = pSize * (pageNum - 1);
        if (startIndex === lastIndex) {
          startIndex -= pSize;
          pageNum--;
        }
      }

      if (pageNum === 1 && pSize > totalItems) {
        lastIndex = totalItems;
        startIndex = 0;
      }

      for (let i = startIndex; i < lastIndex; i++) {
        resultData.push(items[i]);
      }

      dispatch(
        setPaginationDetails({
          data: resultData,
          pageSize: pSize,
          totalRecordLength: totalItems,
          pages: Math.ceil(totalItems / pSize),
          page: pageNum,
          recordsPerApi: pSize,
          startIndex,
          lastIndex,
        })
      );
    }
  };
}

export function serviceTermsLink() {
  return (
    <a href="https://www.telstra.com.au/customer-terms/digitalterms">
      Service Terms
    </a>
  );
}

const getCartItems = (initialPlans, normalizedPlansQBS) => {
  const currentPlansInCart = {};
  normalizedPlansQBS.forEach((plan) => {
    const { id, quantity, setIds } = plan;
    currentPlansInCart[id] = {
      quantity,
      setIds: new Set(Object.keys(setIds)),
    };
  });

  const cartItems: CartItem[] = [];
  Object.keys(initialPlans).forEach((planId) => {
    const initialPlan = initialPlans[planId];
    const {
      quantity: initialQuantity,
      itemAttributes,
      itemRelationships,
      productOfferingId,
      marketSegments,
    } = initialPlan;

    const currentQuantity =
      planId in currentPlansInCart ? currentPlansInCart[planId].quantity : 0;

    if (currentQuantity === 0 || currentQuantity < initialQuantity) {
      let globalAction;
      let updatedItemAttributes = [];

      if (currentQuantity === 0) {
        globalAction = "remove";
      } else if (currentQuantity < initialQuantity) {
        globalAction = "update";
        updatedItemAttributes = itemAttributes.map((itemAttribute) => {
          const itemAction = currentPlansInCart[planId].setIds.has(
            itemAttribute.setId
          )
            ? "noChange"
            : "remove";
          return { ...itemAttribute, action: itemAction };
        });
      }

      const cartItem = {
        id: planId,
        action: globalAction,
        quantity: currentQuantity,
        itemRelationships,
        ...(globalAction === "update" && {
          itemAttributes: updatedItemAttributes,
        }),
        productOffering: {
          id: productOfferingId,
          marketSegments,
        },
      };
      cartItems.push(cartItem);
    }
  });

  return cartItems;
};
