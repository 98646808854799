export const FOOTER = {
  CART_PAGE_SLUG: "/cart",
  BACK_BUTTON: "Back",
  ADD_PLANS_BUTTON: "Add plans",
  ADD_DEVICES_BUTTON: "Add devices / accessories",
  ADD_BLANK_SIM_BUTTON: "Add blank SIMs",
  CHECKOUT_BUTTON: "Checkout",
  NEXT: "Next",
  SUBMIT: "Submit order",
  ADD_TO_CART: "Add to cart",
};
