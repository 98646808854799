import { setCart } from "@state/cart-slice";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { updateSelectedDevices } from "@state/select-devices-slice";
import { QuantityInput } from "telstra-ui/components/quantity/quantity";

const QuantityButton = ({ device }) => {
  const dispatch = useAppDispatch();
  const selectedDevices = useAppSelector(
    (state) => state.selectDevices.selectedDevices
  );
  const isSelected = selectedDevices.find(
    (selectedDevice) => selectedDevice.id === device.id
  );
  const handleChange = (newQuantity) => {
    dispatch(updateSelectedDevices({ device, newQuantity }));
    dispatch(setCart({ error: null }));
  };
  return (
    <QuantityInput
      className="quantity-button"
      id={device.id}
      index={0}
      value={isSelected ? isSelected.quantity : 0}
      min={0}
      enable={true}
      step={1}
      onChange={(_index, quantity) => {
        handleChange(quantity);
      }}
    />
  );
};
export default QuantityButton;
