import { Addon, IBarringAndAddons } from "@state/types";
import { TextStyle, Icon } from "@able/react";
import { Checkbox } from "telstra-ui/components/checkbox/Checkbox";
import { RadioButton } from "telstra-ui/components/radio-button/RadioButton";
import { ableDevelopmentUrl, renderLabel, renderText } from "@utils/helper";

type Props = {
  addon: Addon;
  selectedAddons: IBarringAndAddons;
  setAddons: (a: IBarringAndAddons) => void;
};

const renderDescAndCharge = (addon: Addon) => (
  <>
    {renderText(addon.description, "desc-cell cell")}
    <div className="charge-cell cell">
      <TextStyle alias="Label">
        <>{addon.chargeType ? `$${addon.charge}` : addon.charge}</>
      </TextStyle>
      <TextStyle alias="FinePrint">{addon.chargeType}</TextStyle>
    </div>
  </>
);

export function AddonItem(props: Props) {
  const { addon, selectedAddons, setAddons } = props;

  function handleAddonChange() {
    if (selectedAddons[addon.id]) {
      const newSelectedAddons = { ...selectedAddons };
      delete newSelectedAddons[addon.id];
      setAddons({ ...newSelectedAddons });
    } else {
      if (addon.values?.length) {
        const defaultOption = addon.values[0];
        setAddons({
          ...selectedAddons,
          [addon.id]: { name: addon.name, value: defaultOption.name },
        });
      } else {
        setAddons({ ...selectedAddons, [addon.id]: { name: addon.name } });
      }
    }
  }

  if (!addon.id) return null;

  return (
    <div className="addon-item">
      <div className="row">
        <div className="addon-cell cell">
          {addon.bestowed ? (
            <div className="bestowed">
              <Icon icon="Tick" size="24" developmentUrl={ableDevelopmentUrl} />
              {renderLabel(addon.name)}
            </div>
          ) : (
            <Checkbox
              id={addon.id}
              name={addon.id}
              label={renderLabel(addon.name)}
              checked={!!selectedAddons[addon.id]}
              onChange={handleAddonChange}
            />
          )}
        </div>
        {renderDescAndCharge(addon)}
      </div>
      {addon.values?.map((subAddon) => {
        function handleSubAddonChange() {
          setAddons({
            ...selectedAddons,
            [addon.id]: { name: addon.name, value: subAddon.name },
          });
        }

        return (
          <div className="row" key={subAddon.name}>
            <div className="empty-block" />
            <RadioButton
              className="sub-addon cell"
              checked={
                selectedAddons[addon.id]
                  ? subAddon.name.toLowerCase() ===
                    selectedAddons[addon.id].value?.toLowerCase()
                  : false
              }
              onChange={handleSubAddonChange}
              label={subAddon.name}
              name={subAddon.name}
              id={subAddon.name}
            />
            {renderDescAndCharge(subAddon)}
          </div>
        );
      })}
    </div>
  );
}
