import "./style.scss";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import {
  deleteSelectedNumbers,
  setSearchNumbersFormState,
  setSearchResultsState,
} from "@state/sim-configuration-slice";
import { MessageSection } from "@able/react";
import { ableDevelopmentUrl } from "@utils/helper";
import { NEW_PLAN_ORDER } from "@pages/new-plan-order/constant";

const countDownSeconds = 300;
const { COUNT_DOWN } = NEW_PLAN_ORDER.CUSTOM_NUMBER.SECTION_MESSAGES;

const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time - minutes * 60);
  const minute = minutes < 10 ? "0" + minutes : minutes;
  const second = seconds < 10 ? "0" + seconds : seconds;
  return minute + ":" + second;
};

export default function Countdown({ resetCounter }) {
  const dispatch = useAppDispatch();
  const [countdown, setCountdown] = useState(countDownSeconds);
  const timerId = useRef(null);
  const selectedPlans = useAppSelector(
    (state) => state.commonStore.selectedPlans
  );
  const totalPlansSelected = selectedPlans.length.toString();

  useEffect(() => {
    timerId.current = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(timerId.current);
  }, []);

  useEffect(() => {
    if (countdown <= 0) {
      clearInterval(timerId.current);
      dispatch(
        setSearchNumbersFormState({
          searchQuantity: totalPlansSelected,
          patternType: "",
          pattern: "",
          action: "",
          numbersForUnassignment: [],
        })
      );
      dispatch(
        setSearchResultsState({
          availableNumbers: [],
          fetchedNumbers: false,
        })
      );
      dispatch(deleteSelectedNumbers());
    }
  });

  useEffect(() => {
    setCountdown(countDownSeconds);
  }, [resetCounter]);

  return (
    <MessageSection
      variant="Information"
      developmentUrl={ableDevelopmentUrl}
      className="messagebox"
      description={COUNT_DOWN(formatTime(countdown))}
    />
  );
}
