import React from "react";
import { Grid } from "@able/react";
import ListSurface from "./list-surface";

const ListView = ({ offers }) => {
  return (
    <Grid>
      {offers.map((item) => (
        <ListSurface key={item.id} offer={item} />
      ))}
    </Grid>
  );
};
export default ListView;
