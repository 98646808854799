import { Spacing } from "@able/react";
import { ConfigurePlansListSurface } from "./list-surface";

export function ConfigurePlansListView(props) {
  const { plansByCategoryActiveCategory } = props;
  return (
    <>
      {plansByCategoryActiveCategory.map((plan) => (
        <Spacing bottom="spacing3x" key={"spacing" + plan.id}>
          <ConfigurePlansListSurface plan={plan} />
        </Spacing>
      ))}
    </>
  );
}
