import { CONSTANTS } from "@services/constant";
export const createRefreshNumberRequestBody = (params) => ({
  action: CONSTANTS.NUMBERS.LOCK_UNLOCK,
  quantity: CONSTANTS.NUMBERS.QUANTITY_ONE,
  lockDurationInDays: CONSTANTS.NUMBERS.LOCK_DURATION_DAYS,
  patternType: CONSTANTS.NUMBERS.NONE,
  patternCriteria: CONSTANTS.NUMBERS.NONE,
  premiumType: CONSTANTS.NUMBERS.REGULAR,
  numbersForUnassignment: [params.currentNumber],
});

export const createFetchNumbersRequestBody = (searchFormState) => ({
  action: searchFormState.action || CONSTANTS.NUMBERS.LOCK,
  quantity: searchFormState.searchQuantity,
  lockDurationInMinutes: CONSTANTS.NUMBERS.LOCK_DURATION,
  premiumType: CONSTANTS.NUMBERS.REGULAR,
  searchType: CONSTANTS.NUMBERS.NON_CONTIGUOUS,
  patternType: !searchFormState.patternType
    ? CONSTANTS.NUMBERS.NONE
    : searchFormState.patternType,
  patternCriteria: !searchFormState.pattern
    ? CONSTANTS.NUMBERS.NONE
    : searchFormState.pattern,
  numbersForUnassignment: searchFormState.numbersForUnassignment,
});
