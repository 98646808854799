/* eslint-disable react-hooks/exhaustive-deps */
import {
  ActionButton,
  Column,
  DayMonthYearGroup,
  Grid,
  Select,
  Spacing,
  TextField,
  TextStyle,
} from "@able/react";
import { CHECKOUT } from "@pages/checkout/constant";
import { NEW_ORDER_DETAILS } from "@pages/new-order-details/constant";
import { useEffect, useState } from "react";
import { setLoggedInCIDN } from "@state/logged-in-cidn-slice";
import { useGetContactsQuery } from "@services/base";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { useCartAttributes } from "@utils/cart";
import {
  ableDevelopmentUrl,
  isInternalReferenceNumberInvalid,
  renderLabel,
  renderText,
} from "@utils/helper";
import {
  updateInternalReferenceNumber,
  setContactDetails,
  setContactDetailsRequired,
  updateOrderReference,
  setRequesterDetails,
} from "@state/checkout-slice";
import {
  setError,
  setErrorMessage,
  setCheckoutError,
  setIsValidateRefNumberError,
} from "@state/error";
import { customErrorMessages } from "@components/error-screen/constant";
import { isValidCustomerReqDate } from "@utils/dateValidation";
import DeliveryDetails from "../delivery-details";
import { getEligibleRequesterContacts } from "@utils/contactsValidation";

const generateOptions = (requesters: any) => {
  const requestersArray =
    getEligibleRequesterContacts(requesters?.contacts)?.map(
      (requester: any) => {
        return {
          label: requester.fullName,
          value: requester.id,
        };
      }
    ) || [];
  return requestersArray;
};
const generateContactOptions = (requesters: any) => {
  let requestersArray = [];
  requestersArray = requesters?.contacts?.map((requester: any) => {
    let label = requester?.fullName;
    if (requester?.email) {
      label += " - " + requester?.email;
    }
    if (requester?.phone) {
      label += " - " + requester?.phone;
    }
    return {
      label,
      value: requester?.id,
    };
  });
  return requestersArray;
};
const CustomerDetails = () => {
  const dispatch = useAppDispatch();

  const {
    customerName,
    cidn,
    billingAccount,
    orderReferenceNumber,
    internalReferenceNumber,
    requesterId,
  } = useCartAttributes().getAllAttributes();
  const {
    data: requesters,
    isError,
    isFetching,
    error: contactsQueryError,
    isLoading: contactQueryLoading,
  } = useGetContactsQuery();

  const { isValidateRefNumberError } = useAppSelector((state) => state.error);

  const {
    phoenixNumber,
    contactDetails,
    internalReferenceNumber: telstraReferenceFromStore,
    orderReference,
  } = useAppSelector((state) => state.checkout);

  const { errorMessage } = useAppSelector((state) => state.error);

  const userDate = new Date();
  const [requester, setRequester] = useState("");
  const [customerReqDate, setCustomerReqDate] = useState({
    day: userDate.getDate().toString(),
    month: (userDate.getMonth() + 1).toString(),
    year: userDate.getFullYear().toString(),
  });
  const [dateErr, setDateErr] = useState("");
  useEffect(() => {
    if (contactsQueryError && !isFetching) {
      checkError();
    } else {
      dispatch(setError(false));
      dispatch(setCheckoutError({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactsQueryError, isFetching]);

  const checkError = () => {
    let errorDescription = "";
    if (contactsQueryError && "status" in contactsQueryError) {
      errorDescription =
        customErrorMessages?.contactAPI[contactsQueryError.status];
    }
    dispatch(setError(true));
    dispatch(
      setCheckoutError({
        errorObj: contactsQueryError,
        errorDescription,
      })
    );
  };

  useEffect(() => {
    // reset states to ensure that when internal ref number is changed
    if (phoenixNumber && telstraReferenceFromStore !== phoenixNumber) {
      dispatch(setIsValidateRefNumberError(false));
      dispatch(setErrorMessage(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [telstraReferenceFromStore]);

  useEffect(() => {
    if (requesters && requesters.cidn) {
      dispatch(setLoggedInCIDN(requesters));
    }
    if (
      requesters &&
      requesters?.contacts &&
      requesters?.contacts.length === 1
    ) {
      dispatch(setContactDetails(requesters.contacts[0]));
      dispatch(setContactDetailsRequired(false));
    }
  }, [requesters]);

  useEffect(() => {
    dispatch(updateInternalReferenceNumber(internalReferenceNumber));
    setRequester(requesterId);
    getSelectedRequestorDetails(requesterId);
    dispatch(updateOrderReference(orderReferenceNumber));
    dispatch(setContactDetailsRequired(false));
  }, []);

  const getErrorMessage = () => {
    if (errorMessage.length > 0) return errorMessage;
    else return NEW_ORDER_DETAILS.INTERNAL_REFERENCE_NUMBER_ERROR;
  };

  const onDateChange = (date) => {
    const errMsg = isValidCustomerReqDate(date);
    setCustomerReqDate(date);
    setDateErr(errMsg === "valid" ? "" : errMsg);
  };

  const addNewCustomerContact = (
    <>
      <Spacing bottom="spacing2x">
        {renderLabel(CHECKOUT.DELIVERY_DETAILS.LABEL.CONTACT_DETAILS)}
        {renderText(CHECKOUT.DELIVERY_DETAILS.LABEL.CONTACT_DETAILS_ADD_NEW)}
      </Spacing>
      <Spacing bottom="spacing5x">
        <ActionButton
          developmentUrl={ableDevelopmentUrl}
          element="button"
          label={CHECKOUT.DELIVERY_DETAILS.LABEL.ADD_NEW_CONTACT}
          variant="MediumEmphasis"
        />
      </Spacing>
    </>
  );
  const selectedContactDetails = (
    <>
      <Spacing bottom="spacing5x">
        <Select
          id="contactDetails"
          className="contact-details-dropdown"
          label={CHECKOUT.DELIVERY_DETAILS.LABEL.CONTACT_DETAILS}
          helpText={CHECKOUT.DELIVERY_DETAILS.LABEL.CONTACT_DETAILS_SUBTEXT}
          developmentUrl={ableDevelopmentUrl}
          required={false}
          invalid={false}
          options={
            contactQueryLoading || isError
              ? []
              : generateContactOptions(requesters)
          }
          value={contactDetails?.id ?? ""}
          events={{
            onChange: (e) => {
              const data = requesters.contacts.filter(
                (el) => el.id == e.target.value
              );
              dispatch(setContactDetails(data[0]));
              dispatch(setContactDetailsRequired(false));
            },
          }}
        />
      </Spacing>
      {contactDetails?.firstName && (
        <Spacing bottom="spacing5x">
          <TextStyle alias="Label">
            {CHECKOUT.DELIVERY_DETAILS.LABEL.GIVEN_NAME}
          </TextStyle>
          <TextStyle alias="Base">{contactDetails?.firstName}</TextStyle>
        </Spacing>
      )}
      {contactDetails?.lastName && (
        <Spacing bottom="spacing5x">
          <TextStyle alias="Label">
            {CHECKOUT.DELIVERY_DETAILS.LABEL.FAMILY_NAME}
          </TextStyle>
          <TextStyle alias="Base">{contactDetails?.lastName}</TextStyle>
        </Spacing>
      )}
      {contactDetails?.email && (
        <Spacing bottom="spacing5x">
          <TextStyle alias="Label">
            {CHECKOUT.DELIVERY_DETAILS.LABEL.EMAIL}
          </TextStyle>
          <TextStyle alias="Base">{contactDetails?.email}</TextStyle>
        </Spacing>
      )}
      {contactDetails?.phone && (
        <Spacing bottom="spacing5x">
          <TextStyle alias="Label">
            {CHECKOUT.DELIVERY_DETAILS.LABEL.MOBILE_NUMBER}
          </TextStyle>
          <TextStyle alias="Base">{contactDetails?.phone}</TextStyle>
        </Spacing>
      )}
      <Spacing bottom="spacing5x">
        <ActionButton
          developmentUrl={ableDevelopmentUrl}
          element="button"
          label={`${
            contactDetails?.id &&
            CHECKOUT.DELIVERY_DETAILS.LABEL.CONTACT_NOT_RIGHT
          } ${CHECKOUT.DELIVERY_DETAILS.LABEL.ADD_CONTACT}`}
          variant="LowEmphasis"
        />
      </Spacing>
    </>
  );

  const getSelectedRequestorDetails = (id) => {
    setRequester(id);
    const selectedOptionObject = requesters?.contacts.find(
      (option) => option.id == id
    );
    const requestorObj = {
      id: selectedOptionObject?.id,
      name: selectedOptionObject?.fullName,
    };
    dispatch(setRequesterDetails(requestorObj));
  };

  return (
    <>
      <Grid>
        <Column cols={12} bsm={3} bmd={3} blg={3}>
          <Spacing bottom="spacing5x">
            <TextStyle alias="Label">
              {CHECKOUT.CUSTOMER_DETAILS.LABEL.NAME}
            </TextStyle>
            <TextStyle alias="Base">{customerName}</TextStyle>
          </Spacing>
          <Spacing bottom="spacing5x">
            <TextStyle alias="Label">
              {CHECKOUT.CUSTOMER_DETAILS.LABEL.CIDN}
            </TextStyle>
            <TextStyle alias="Base">{cidn}</TextStyle>
          </Spacing>
          <Spacing bottom="spacing5x">
            <TextStyle alias="Label">
              {CHECKOUT.CUSTOMER_DETAILS.LABEL.BILLING_ACCOUNT}
            </TextStyle>
            <TextStyle alias="Base">{billingAccount}</TextStyle>
          </Spacing>
          <Spacing bottom="spacing5x">
            <Select
              id="requester"
              label={CHECKOUT.CUSTOMER_DETAILS.LABEL.REQUESTER}
              developmentUrl={ableDevelopmentUrl}
              required={true}
              allowEmpty={false}
              options={
                contactQueryLoading || isError
                  ? []
                  : generateOptions(requesters)
              }
              value={requester ?? ""}
              events={{
                onChange: (e) => {
                  getSelectedRequestorDetails(e.target.value);
                },
              }}
            />
          </Spacing>
          <Spacing bottom="spacing5x">
            <TextField
              id="internalReferenceNumber"
              developmentUrl={ableDevelopmentUrl}
              label={CHECKOUT.CUSTOMER_DETAILS.LABEL.TELSTRA_REFERENCE_NUMBER}
              required={true}
              name="internalReferenceNumber"
              invalid={
                isValidateRefNumberError ||
                (telstraReferenceFromStore &&
                  telstraReferenceFromStore.length > 0 &&
                  isInternalReferenceNumberInvalid(telstraReferenceFromStore))
              }
              invalidInputText={getErrorMessage()}
              value={telstraReferenceFromStore ?? ""}
              events={{
                onChange: (e) => {
                  dispatch(updateInternalReferenceNumber(e.target.value));
                  dispatch(setIsValidateRefNumberError(false));
                },
              }}
            />
          </Spacing>
          <Spacing bottom="spacing10x">
            <TextField
              id="orderReference"
              label={CHECKOUT.CUSTOMER_DETAILS.LABEL.ORDER_REFERENCE}
              developmentUrl={ableDevelopmentUrl}
              required={false}
              name="orderReference"
              value={orderReference ?? ""}
              events={{
                onChange: (e) => {
                  dispatch(updateOrderReference(e.target.value));
                },
              }}
            />
          </Spacing>
        </Column>
      </Grid>

      <Grid>
        <Column cols={12} bsm={6} bmd={4} blg={4}>
          <DeliveryDetails />
          {requesters?.contacts?.length
            ? selectedContactDetails
            : addNewCustomerContact}
        </Column>
      </Grid>
      <Spacing bottom="spacing5x">
        <DayMonthYearGroup
          developmentUrl={ableDevelopmentUrl}
          required
          data-testid="customer-req-date"
          label={CHECKOUT.DELIVERY_DETAILS.LABEL.CUSTOMER_REQ_DATE}
          minYear={userDate.getFullYear().toString()}
          externalOnChange={onDateChange}
          externalOnBlur={onDateChange}
          value={customerReqDate}
          isInvalid={dateErr.length > 0}
          invalidInputText={dateErr}
        />
      </Spacing>

      <Spacing bottom="spacing5x" />
    </>
  );
};

export default CustomerDetails;
