import "./style.scss";
import { IconButton } from "@able/react";
import { ableDevelopmentUrl, renderText } from "@utils/helper";

export default function AssignedPhoneNumber({ plan }) {
  return (
    <div className="assigned-phone-number">
      {renderText(plan.phoneNumber)}
      <IconButton
        icon="Refresh"
        size="Big"
        developmentUrl={ableDevelopmentUrl}
        screenReaderContent="Refresh number"
        events={{}}
      />
    </div>
  );
}
