import {
  ActionButton,
  MessageInline,
  ModalContent,
  Payment56,
  RadioButton,
  RadioGroup,
  Spacing,
} from "@able/react";
import { CONFIGURE_DEVICES_AND_ACCESSORIES } from "@pages/devices-and-accessories/constant";
import { updatePaymentTypeUsingGroupPayment } from "@state/cart-slice";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { deleteSelectedConfiguredDevice } from "@state/select-devices-slice";
import { ableDevelopmentUrl } from "@utils/helper";
import { useState } from "react";
import { CART } from "@utils/common-static-data";

const BodyContent = (props) => {
  const {
    selectedOption,
    setSelectedOption,
    radioOptions,
    isAllPaymentAvailable,
    isAllPaymentWithoutTwentyFourMonth,
  } = props;
  const configuredDeviceSelected = useAppSelector(
    (state) => state.selectDevices.configuredDeviceSelected
  );

  return (
    <>
      {!isAllPaymentAvailable && !isAllPaymentWithoutTwentyFourMonth && (
        <>
          <Spacing top="spacing4x" bottom="spacing4x">
            <MessageInline
              variant="Information"
              developmentUrl={ableDevelopmentUrl}
              description={
                CONFIGURE_DEVICES_AND_ACCESSORIES.GROUP_PAYMENT_TYPE.MODAL
                  .INFORMATION_MESSAGE
              }
              className="message-section"
            />
          </Spacing>
        </>
      )}
      <RadioGroup
        groupLabel={`${configuredDeviceSelected.length} ${CONFIGURE_DEVICES_AND_ACCESSORIES.GROUP_PAYMENT_TYPE.MODAL.ITEM_SELECTED_MSG}`}
        name="group-payment"
        variant="Default"
      >
        {radioOptions.map((option) => (
          <RadioButton
            label={option.label}
            id={`radio-${option.value}`}
            value={option.value}
            key={option.value}
            checked={selectedOption === option.value}
            events={{
              onChange: (e) => {
                setSelectedOption(e.target.value);
              },
            }}
          />
        ))}
      </RadioGroup>
    </>
  );
};

const FooterContent = (props) => {
  const { toggleModal, selectedOption, setSelectedOption } = props;
  const dispatch = useAppDispatch();
  const configuredDeviceSelected = useAppSelector(
    (state) => state.selectDevices.configuredDeviceSelected
  );
  return (
    <>
      <ActionButton
        developmentUrl={ableDevelopmentUrl}
        element="button"
        label={
          CONFIGURE_DEVICES_AND_ACCESSORIES.GROUP_PAYMENT_TYPE.MODAL.CANCEL_BTN
        }
        variant="MediumEmphasis"
        events={{
          onClick: () => {
            toggleModal();
            setSelectedOption("outright");
          },
        }}
      />
      <ActionButton
        developmentUrl={ableDevelopmentUrl}
        element="button"
        label={
          CONFIGURE_DEVICES_AND_ACCESSORIES.GROUP_PAYMENT_TYPE.MODAL.CONFIRM_BTN
        }
        variant="HighEmphasis"
        events={{
          onClick: () => {
            dispatch(
              updatePaymentTypeUsingGroupPayment({
                selectedOption,
                configuredDeviceSelected,
              })
            );
            dispatch(deleteSelectedConfiguredDevice([]));
            toggleModal();
            setSelectedOption("outright");
          },
        }}
      />
    </>
  );
};

const GroupPaymentTypeModal = (props) => {
  const { modalIsOpen, toggleModal } = props;
  const configuredDeviceSelected = useAppSelector(
    (state) => state.selectDevices.configuredDeviceSelected
  );
  const normalizedDevicesAndAccesories = useAppSelector(
    (state) => state.cart.normalizedDevicesAndAccesories
  );
  const [selectedOption, setSelectedOption] = useState("outright");

  const deviceSelectedForPayment = [];

  configuredDeviceSelected.forEach((itemId) => {
    normalizedDevicesAndAccesories.forEach((item) => {
      if (item.id === itemId) deviceSelectedForPayment.push(item);
    });
  });

  const radioOptions = [
    {
      label: "Outright",
      value: "outright",
    },
    {
      label: "12 month repayment",
      value: CART.PRODUCTS.PAYMENT_12_MONTHS,
    },
  ];
  const isAllPaymentAvailable = deviceSelectedForPayment.every(
    (item) => item.twentyfourMonthPayment > 0
  );
  const isAllPaymentWithoutTwentyFourMonth = deviceSelectedForPayment.every(
    (item) => item.twentyfourMonthPayment === 0 && item.twelveMonthPayment > 0
  );

  if (isAllPaymentAvailable) {
    radioOptions.push({
      label: "24 month repayment",
      value: CART.PRODUCTS.PAYMENT_24_MONTHS,
    });
  }
  return (
    <ModalContent
      developmentUrl={ableDevelopmentUrl}
      title={CONFIGURE_DEVICES_AND_ACCESSORIES.GROUP_PAYMENT_TYPE.LABEL}
      variant={
        isAllPaymentAvailable || isAllPaymentWithoutTwentyFourMonth
          ? "Compact"
          : "Comfortable"
      }
      picto={<Payment56 />}
      onClose={() => {
        setSelectedOption("outright");
      }}
      bodyContent={
        <BodyContent
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          radioOptions={radioOptions}
          isAllPaymentAvailable={isAllPaymentAvailable}
          isAllPaymentWithoutTwentyFourMonth={
            isAllPaymentWithoutTwentyFourMonth
          }
        />
      }
      footerContent={
        <FooterContent
          toggleModal={toggleModal}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          deviceSelectedForPayment={deviceSelectedForPayment}
        />
      }
      isShowing={modalIsOpen}
      setHideDialog={toggleModal}
    />
  );
};

export default GroupPaymentTypeModal;
