/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import { useEffect, useState } from "react";
import {
  prefixWithDollarSign,
  renderBoldText,
  renderText,
} from "@utils/helper";
import { CART, CART_CHARGES_TABLE } from "../constant";
import { SectionHeader, Spacing, Surface, TextStyle } from "@able/react";
import { useAppSelector } from "@state/hooks";
import { AdvanceTableV3 } from "telstra-ui/components/table-advance-v3/AdvanceTableV3";
import { CONFIGURE_DEVICES_AND_ACCESSORIES } from "@pages/devices-and-accessories/constant";
import { CHECKOUT } from "@pages/checkout/constant";

const ReviewCartChargesTable = () => {
  const [oneOffChargesSummary, setOneOffChargesSummary] = useState([]);
  const [recurringChargesSummary, setRecurringChargesSummary] = useState([]);

  const patchCartResponse = useAppSelector(
    (state) => state.cart.patchCartResponse
  );
  const devices = useAppSelector(
    (state) => state.cart.normalizedDevicesAndAccesories
  );
  const plans = useAppSelector((state) => state.cart.normalizedPlansQBS);

  const {
    cartItems,
    cartTotalPrice: {
      recurringPrice = 0,
      onceOffPrice = 0,
      recurringPriceWithGst = 0,
      onceOffPriceWithGst = 0,
      totalCartPrice = 0,
      totalCartPriceWithGst = 0,
    } = {},
  } = patchCartResponse;

  const generateChargesSummary = () => {
    const oneOffChargesSummaryData = [];
    const recurringChargesSummaryData = [];
    function pushItem(cartItem, device) {
      const {
        itemPrices,
        productOffering: { name = "" },
        quantity,
      } = cartItem;
      const { category, sku, color, technologyType, paymentType, addOns } =
        device;
      if (itemPrices?.length > 0) {
        for (const itemPrice of itemPrices) {
          const item = JSON.parse(JSON.stringify(itemPrice));
          item.name = name;
          item.quantity = quantity;
          item.category = category;
          item.sku = sku;
          item.color = color;
          item.technologyType = technologyType;
          item.paymentType = paymentType;
          item.addOns = addOns;
          if (
            onceOffPrice > 0 &&
            item.priceType === CART_CHARGES_TABLE.ONCE_OFF
          ) {
            oneOffChargesSummaryData.push(item);
          } else if (
            recurringPrice > 0 &&
            item.priceType === CART_CHARGES_TABLE.RECURRING
          ) {
            recurringChargesSummaryData.push(item);
          }
        }
      }
    }
    if (plans.length > 0) {
      for (const plan of plans) {
        const planItem = cartItems.find((cartItem) => cartItem.id === plan.id);
        /* push plan in charges summary */
        pushItem(planItem, plan);
      }
    } else {
      for (const device of devices) {
        const deviceItem = cartItems.find(
          (cartItem) => cartItem.id === device.id
        );
        /* push plan in charges summary */
        pushItem(deviceItem, device);
      }
    }
    if (oneOffChargesSummaryData.length > 0) {
      oneOffChargesSummaryData.push({
        name: CART_CHARGES_TABLE.TOTAL_ONE_OFF,
        priceType: CART_CHARGES_TABLE.TOTAL_CHARGE,
        totalPriceWithGst: onceOffPriceWithGst,
        totalPrice: onceOffPrice,
        code: CART_CHARGES_TABLE.TOTAL,
      });
    }
    if (recurringChargesSummaryData.length > 0) {
      recurringChargesSummaryData.push({
        name: CART_CHARGES_TABLE.TOTAL_RECURRING,
        priceType: CART_CHARGES_TABLE.TOTAL_CHARGE,
        totalPriceWithGst: recurringPriceWithGst,
        totalPrice: recurringPrice,
        code: CART_CHARGES_TABLE.TOTAL,
      });
    }
    setOneOffChargesSummary(oneOffChargesSummaryData);
    setRecurringChargesSummary(recurringChargesSummaryData);
  };

  useEffect(() => {
    generateChargesSummary();
  }, [patchCartResponse]);

  const renderItem = (rowData) => {
    return (
      <div>
        {rowData.original.code === CART_CHARGES_TABLE.TOTAL ? (
          <TextStyle alias="HeadingD" element="h4">
            {rowData.original.name}
          </TextStyle>
        ) : (
          <>
            <TextStyle alias="Tag">{rowData.original.category}</TextStyle>
            <TextStyle alias="Label">{rowData.original.name}</TextStyle>
            {plans.length > 0 ? (
              <>
                {Object.keys(rowData.original.addOns).map((value) => (
                  <TextStyle alias="Tag" key={value}>
                    {`${rowData.original.addOns[value].name}`}
                  </TextStyle>
                ))}
              </>
            ) : (
              <>
                <TextStyle alias="Tag">
                  {`${rowData.original.technologyType} | ${rowData.original.color} |
                  --`}
                </TextStyle>
                <TextStyle alias="Tag">
                  {`${CONFIGURE_DEVICES_AND_ACCESSORIES.SKU} ${rowData.original.sku}`}
                </TextStyle>
              </>
            )}
          </>
        )}
      </div>
    );
  };

  const renderUnitCharge = (rowData) => {
    return (
      <div className="charges-unit-charges">
        <TextStyle>{prefixWithDollarSign(rowData.value)}</TextStyle>
        {/* <TextStyle alias="FinePrint">{rowData.original.paymentType}</TextStyle> */}
      </div>
    );
  };

  const columnData = [
    {
      headerClassName: "table-align-left",
      className: "td-align-left",
      Header: renderBoldText(CART_CHARGES_TABLE.ITEM),
      accessor: "name",
      resizable: false,
      Cell: (cellInfo) => renderItem(cellInfo),
      minWidth: 200,
    },
    {
      headerClassName: "table-align-right",
      className: "td-align-right",
      Header: renderBoldText(CART_CHARGES_TABLE.UNIT_CHARGES),
      accessor: "unitPriceWithGst",
      resizable: false,
      Cell: (cellInfo) => cellInfo.value && renderUnitCharge(cellInfo),
    },
    {
      headerClassName: "table-align-right",
      className: "td-align-right",
      Header: renderBoldText(CART_CHARGES_TABLE.QUANTITY),
      accessor: "quantity",
      resizable: false,
      Cell: (cellInfo) => cellInfo.value && renderText(cellInfo.value),
    },
    {
      headerClassName: "table-align-right",
      className: "td-align-right",
      Header: renderBoldText(CART_CHARGES_TABLE.CHARGES_EX_GST),
      accessor: "totalPrice",
      resizable: false,
      Cell: (cellInfo) => renderText(prefixWithDollarSign(cellInfo.value)),
    },
    {
      headerClassName: "table-align-right",
      className: "td-align-right",
      Header: renderBoldText(CART_CHARGES_TABLE.CHARGES_INC_GST),
      accessor: "totalPriceWithGst",
      resizable: false,
      Cell: (cellInfo) => renderText(prefixWithDollarSign(cellInfo.value)),
    },
  ];

  return (
    <div className="devices-charges-table">
      <Spacing top="spacing4x">
        <SectionHeader title={CART.CHARGES} variantLevel="Level2" />
      </Spacing>
      {recurringChargesSummary.length > 0 && (
        <>
          <Spacing top="spacing2x">
            <TextStyle alias="HeadingD" element="h4">
              {CHECKOUT.MONTHLY_RECURRING_CHARGE}
            </TextStyle>
          </Spacing>
          <Spacing top="spacing2x">
            <AdvanceTableV3
              columns={columnData}
              data={recurringChargesSummary}
              showPagination={false}
              totalRecordLength={recurringChargesSummary.length}
              pages={1}
              page={1}
              pageSize={recurringChargesSummary.length}
              defaultPageSize={10}
              sendApi={() => null}
            />
          </Spacing>
        </>
      )}
      {oneOffChargesSummary.length > 0 && (
        <>
          <Spacing top="spacing2x">
            <TextStyle alias="HeadingD" element="h4">
              {CHECKOUT.ONE_OFF_CHARGE}
            </TextStyle>
          </Spacing>
          <Spacing top="spacing2x">
            <AdvanceTableV3
              columns={columnData}
              data={oneOffChargesSummary}
              showPagination={false}
              totalRecordLength={oneOffChargesSummary.length}
              pages={1}
              page={1}
              pageSize={oneOffChargesSummary.length}
              defaultPageSize={10}
              sendApi={() => null}
            />
          </Spacing>
        </>
      )}
      <Spacing top="spacing4x">
        <Surface
          background="materialBaseBrandQuaternary"
          className="eg-surface"
          cornerRadius="cornerRadiusSmooth"
          interactive={false}
          variant="SurfaceFlat"
        >
          <Spacing
            bottom="spacing4x"
            left="spacing3x"
            right="spacing3x"
            top="spacing4x"
            className={["total-charges-footer"]}
          >
            <div className="total-heading">
              <TextStyle alias="HeadingD" element="h4" colour="Inverted">
                Total charges
              </TextStyle>
            </div>
            <div className="total-price">
              <TextStyle alias="HeadingD" element="h4" colour="Inverted">
                {prefixWithDollarSign(totalCartPrice)}
              </TextStyle>
              <TextStyle alias="Base" colour="Inverted">
                {CART_CHARGES_TABLE.EX_GST}
              </TextStyle>
            </div>
            <div className="total-price">
              <TextStyle alias="HeadingD" element="h4" colour="Inverted">
                {prefixWithDollarSign(totalCartPriceWithGst)}
              </TextStyle>
              <TextStyle alias="Base" colour="Inverted">
                {CART_CHARGES_TABLE.INC_GST}
              </TextStyle>
            </div>
          </Spacing>
        </Surface>
      </Spacing>
    </div>
  );
};

export default ReviewCartChargesTable;
