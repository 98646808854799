import "./style.scss";
import { ActionButton, FilterToggle } from "@able/react";
import { updateSelectedDeviceFilters } from "@state/common-slice";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { DEVICES_AND_ACCESSORIES } from "@pages/devices-and-accessories/constant";
import { ableDevelopmentUrl } from "@utils/helper";
import { useEffect } from "react";

const DeviceFilters = (props) => {
  const dispatch = useAppDispatch();
  const selectedDeviceFilters = useAppSelector(
    (state) => state.commonStore.selectedDeviceFilters
  );
  const pills = JSON.parse(JSON.stringify(selectedDeviceFilters));
  const filters = pills.filter((filter) => {
    return filter.type === "filter";
  });
  const selectedFilter = filters?.some((filter) => filter?.selected === true);

  const initialFilter = [];
  if (filters.length === 0) {
    const mobileCategory = props?.offers?.find(
      (offer) => offer?.name === "Mobile"
    );
    if (mobileCategory) {
      const categoryFilters = mobileCategory.filter;
      if (categoryFilters.length > 0) {
        for (const filter of categoryFilters) {
          initialFilter.push({
            id: filter,
            dataId: filter,
            selected: false,
            type: "filter",
          });
        }
      }
    }
  }

  useEffect(() => {
    if (initialFilter.length > 0) {
      dispatch(updateSelectedDeviceFilters(initialFilter));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickItem = (item) => {
    const selectedIndex = filters.findIndex(
      (obj) => obj.id == item.filterIdentifier
    );
    filters[selectedIndex].selected = !filters[selectedIndex].selected;
    dispatch(updateSelectedDeviceFilters([...filters]));
  };

  const onClearFilterClick = () => {
    for (const pill of filters) {
      pill.selected = false;
    }
    dispatch(updateSelectedDeviceFilters([...filters]));
  };
  return (
    <>
      <div className="filter-column">
        <FilterToggle
          filters={filters}
          onAbleFilterTogglePressed={onClickItem}
        />
      </div>
      {selectedFilter && (
        <div className="filter-column">
          <ActionButton
            developmentUrl={ableDevelopmentUrl}
            element="button"
            label={DEVICES_AND_ACCESSORIES.CLEAR_FILTER_LABEL}
            variant="LowEmphasis"
            events={{
              onClick: onClearFilterClick,
            }}
          />
        </div>
      )}
    </>
  );
};
export default DeviceFilters;
