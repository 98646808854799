import { Select } from "@able/react";
import { DEVICES_AND_ACCESSORIES } from "@pages/devices-and-accessories/constant";

const Category = (props) => {
  const categoryOptions = [];
  for (const category of props.category) {
    if (category.id.toLowerCase() === "mobile") {
      categoryOptions.push({ label: category.name, value: category.name });
    }
  }
  return (
    <Select
      label={DEVICES_AND_ACCESSORIES.CATEGORY_LABEL}
      options={categoryOptions}
      value={categoryOptions[0].value}
      id="category"
    />
  );
};

export default Category;
