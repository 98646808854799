export const NEW_PLAN_ORDER = {
  PAGE_TITLE: "New plan order",
  SELECT_PLAN_TAGLINE: "Select plan/s",
  BARRING_ADDONS_TAGLINE: "Configure network features and add-ons",
  SIM_CONFIGURATION_TAGLINE: "Configure SIMs and numbers",
  HEADING: "Select plan",
  FILTERS: "Filters",
  UNLIMITED_CALLS_INCLUSION: "Unlimited calls and texts in Australia",
  PLAN_SHARING: "Shared data pool",
  PLAN_PRICE_TEXT: "ex GST",
  PLAN_PER_MONTH: "/mth",
  SIM_CONFIGURATION_ASSISTIVE_TEXT:
    "You can update SIM serial numbers and usernames via the table below or the template. To search for custom phone numbers, select the plans you want to update. Phone numbers will stay reserved for up to 29 days. After this time, they will return to the number reservation pool.",

  CUSTOM_NUMBER: {
    SEARCH_TEXT: "Change assigned numbers",
    MESSAGE_1: (totalPlansSelected) =>
      `You’ve selected ${totalPlansSelected} plans to change assigned mobile numbers to your preferred ones.`,
    MESSAGE_2:
      "You can search for up to 100 mobile numbers with your preferred pattern type (e.g. ‘contains’) and number pattern (e.g. 555).",
    MESSAGE_3:
      "You have five minutes to assign the available numbers or you will have to search again.",
    BUTTON_LABELS: {
      ASSIGN_BUTTON: "Assign numbers",
      SEARCH_NUMBERS_BUTTON: "Search numbers",
      SEARCH_BUTTON: "Search",
      CANCEL_BUTTON: "Cancel",
    },
    INPUT_DETAILS: {
      SEARCH_NUMBER_QUANTITY: {
        LABEL: "Number search quantity",
        ID: "quantity",
        NAME: "quantity",
      },
      PATTERN_TYPE: {
        LABEL: "Pattern type",
        ID: "patternType",
      },
      PATTERN: {
        LABEL: "Number pattern",
        ID: "pattern",
        NAME: "pattern",
      },
    },
    PATTERN_TYPE_OPTIONS: [
      {
        label: "Starts With",
        value: "Begins",
      },
      {
        label: "Ends With",
        value: "Ends",
      },
      {
        label: "Contains",
        value: "Contains",
      },
    ],
    PATTEN_TYPE_OPTIONS_LABELS: {
      Begins: "Starts With",
      Ends: "Ends With",
      Contains: "Contains",
    },
    DEFAULT_PAGINATION_STATE: {
      data: [],
      pageSize: 10,
      totalRecordLength: 0,
      pages: 0,
      page: 0,
      recordsPerApi: 10,
    },
    ERROR_MESSAGES: {
      NUMBERS_ONLY: "Enter numbers only.",
      QUANTITY_MORE_THAN_ZERO: "Quantity must be greater than 0.",
    },
    SECTION_MESSAGES: {
      LESS_THAN_REQUESTED: (numberCount, patternType, pattern) =>
        `We only found ${numberCount} available numbers with the pattern: ${patternType} ${pattern}`,
      SELECTED_MORE: (plansCount) =>
        `You can only select ${plansCount} numbers for your selected plans.`,
      SELECTED_NONE: `Select available numbers to assign numbers to your chosen plans.`,
      NO_NUMBERS: `There are no numbers with your chosen pattern type and number pattern. Please try another combination.`,
      COUNT_DOWN: (countDown) =>
        `You have ${countDown} minutes remaining to assign your numbers.`,
    },
    AVAILABLE_NUMBERS: "Available numbers",
    ITEMS_SELECTED: (numbersCount) => `${numbersCount} items selected`,
    PATTERN_INVALID_INPUT: "Pattern is required",
    PAGE_SIZE_OPTIONS: ["5", "10", "20", "25", "50", "100", "200"],
  },
};

export const SELECT_PLAN = {
  TABLE_VIEW: {
    QUANTITY: "Quantity",
    PLAN_TYPE: "Plan type - Plan",
    MONTHLY_CHARGES: "Monthly charge (ex GST)",
    DATA_ALLOWANCE: "Data allowance",
    SHARED_DATA_POOL: "Shared data pool",
    NETWORK: "Network",
    INCLUSIONS: "Inclusions",
  },
  FILTERS: {
    FILTER_MOBILE: "Mobile",
    FILTER_BROADBAND: "Broadband",
    FILTER_ENTERPRISE_WIRELESS: "Enterprise Wireless",
  },
  INCLUSIONS_LIST: {
    NORMAL: "Normal",
    READ_MORE: "Read more about plan inclusion",
  },
};

export const SELECT_PLAN_VIEWS = [
  {
    id: "table",
    label: "Table view",
    value: "table",
    active: false,
    disabled: false,
    icon: "icon-table-view",
  },
  {
    id: "list",
    label: "List view",
    value: "list",
    active: false,
    disabled: false,
    icon: "icon-system-list-view",
  },
  {
    id: "grid",
    label: "Grid view",
    value: "grid",
    active: false,
    disabled: false,
    icon: "icon-grid-view",
  },
];

export const BARRING_ADDONS_TABLE = {
  PLAN: "Plan",
  BARRING: "Barring",
  ADD_ONS: "Add-ons",
  CLONE: "Clone",
  REMOVE: "Remove",
  REMOVE_PLAN: {
    LABEL: "Remove Plan",
    CANCEL: "Cancel",
    TITLE: "Are you sure you want to remove this item?",
    DESCRIPTION: "This item will be permanently removed.",
  },
};

export const SIM_CONFIG_TABLE = {
  PLAN_TYPE: "Plan type - Plan",
  BARRING: "Barring",
  ADD_ONS: "Add-ons",
  SIM_TYPE: "SIM type",
  SIM_SERIAL_NUMBER: "SIM serial number",
  ADD_USERNAME: "Add username (optional)",
  SIM_SERIAL_NUMBER_13DIGIT_ERROR:
    "Invalid SIM number. A SIM number is 13 digits.",
  SIM_SERIAL_NUMBER_INVALID_UNMS: "Invalid SIM serial number.",
  ALL_SIM_SERIAL_NUMBER_VALIDATION:
    "All SIM serial numbers must be validated before adding to the cart. Please fix the following errors.",
  SIM_SERIAL_NUMBER_DUPLICATE:
    "Duplicate entry. Enter a unique SIM serial number.",
  INVALID_USERNAME:
    "Incorrect format. Use letters, numbers, apostrophes (') and hyphens (-) only.",
  ASSIGNED_PHONE_NUMBER: {
    HEADING: "Assigned phone number",
    TOOLTIP: {
      TITLE: "Refresh limited",
      DESCRIPTION:
        "You may only refresh to get a different phone number 2 times.",
    },
  },
};

export const NEW_SIM_CONFIG_TABLE = {
  TABLE_HEADERS: {
    ID: "id",
    EXPAND: "Expand",
    PLAN: "Plan",
    QUANTITY: "Quantity",
    ASSIGNED_PHONE_NUMBER: "Assigned number",
    SIM_TYPE: "SIM type",
    SIM_SERIAL_NUMBER: "SIM serial number",
    ADD_USERNAME: "Add username (optional)",
    GROUP_BY_PLANNAME: "groupByPlanName",
  },
  SIM_INFORMATION_MESSAGE_1: "We'll deliver this to you.",
  SIM_INFORMATION_MESSAGE_2: "eSIM+ (opens new tab) ",
  SIM_INFORMATION_MESSAGE_3: "is only compatible with newer mobile devices.",
  SIM_SERIAL_NUMBER_13DIGIT_ERROR:
    "Invalid SIM number. A SIM number is 13 digits.",
  SIM_SERIAL_NUMBER_INVALID_UNMS: "Invalid SIM serial number.",
  ALL_SIM_SERIAL_NUMBER_VALIDATION:
    "All SIM serial numbers must be validated before adding to the cart. Please fix the following errors.",
  SIM_SERIAL_NUMBER_DUPLICATE:
    "Duplicate entry. Enter a unique SIM serial number.",
  INVALID_USERNAME:
    "Incorrect format. Use letters, numbers, apostrophes (') and hyphens (-) only.",
  VIEW_DETAILS: {
    BTN: "View details",
    ADDONS: "Add-ons",
    NETWORK_FEATURES: "Network features",
    OK_BTN: "OK",
  },
};

export const BULK_CONFIG = {
  TITLE: "Configure in bulk",
  SIM_TYPE: "SIM type",
  ASSIGNED_NUMBER: "Change assigned number",
  PLANS_SELECTED: (num) => `${num} plans selected to configure.`,
};

export const ERROR_TYPES = {
  SIM_CONFIG_NUMBER: "SIM_CONFIG_NUMBER",
  SIM_CONFIG_NUMBER_PARTIAL_SUCCESS: "SIM_CONFIG_NUMBER_PARTIAL_SUCCESS",
  SIM_CONFIG_NUMBER_REFRESH: "SIM_CONFIG_NUMBER_REFRESH",
  SIM_CONFIG_NUMBER_SEARCH: "SIM_CONFIG_NUMBER_SEARCH",
  SIM_CONFIG_NUMBER_ASSIGNMENT: "SIM_CONFIG_NUMBER_ASSIGNMENT",
  SIM_CONFIG_PATCH_CART: "SIM_CONFIG_PATCH_CART",
  SIM_CONFIG_NO_PLANS_SELECTED: "SIM_CONFIG_NO_PLANS_SELECTED",
};

export const CHARACTERISTICS = {
  PLAN_TYPE: "PlanType",
  MOBILITY_PLAN: "MobilityPlan",
};
