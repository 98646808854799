import "./style.scss";
import { TextStyle, Spacing, InlineLink } from "@able/react";
import { renderLabel, serviceTermsLink } from "@utils/helper";
import { IBarringAndAddons, IOffer } from "@state/types";
import { AddonItem } from "./addon";
import { CONFIGURE_BARRINGS_AND_ADDONS } from "@pages/new-plan-order/steps/configure-plan-barring-add-ons/constant";

type Props = {
  offer: IOffer;
  selectedAddons: IBarringAndAddons;
  setAddons: (a: IBarringAndAddons) => void;
};

const { HEADING, SUBTEXT, ADDONS, DESCRIPTION, CHARGE } =
  CONFIGURE_BARRINGS_AND_ADDONS.LIST_VIEW.CONFIGURE_SECTION.ADDONS;

export function AddOnsSection(props: Props) {
  const { offer, selectedAddons, setAddons } = props;

  return (
    <Spacing
      top="spacing5x"
      left="spacing4x"
      right="spacing4x"
      bottom="spacing3x"
      className={["addons-section"]}
    >
      <TextStyle alias="HeadingD">{HEADING}</TextStyle>
      <Spacing top="spacing1nHalf" bottom="spacing3x">
        <InlineLink>
          <TextStyle alias="Base">
            <span>
              {SUBTEXT}
              {serviceTermsLink()}.
            </span>
          </TextStyle>
        </InlineLink>
      </Spacing>
      <div className="custom-addons">
        <div className="heading">
          {renderLabel(ADDONS, "addon-cell")}
          {renderLabel(DESCRIPTION, "desc-cell")}
          {renderLabel(CHARGE, "charge-cell")}
        </div>
        {offer.addons?.map((addon) => (
          <AddonItem
            key={addon.name}
            addon={addon}
            selectedAddons={selectedAddons}
            setAddons={setAddons}
          />
        ))}
      </div>
    </Spacing>
  );
}
