import { CART } from "@utils/common-static-data";

export const CONSTANTS = {
  METHODS: {
    PATCH: "PATCH",
    POST: "POST",
    GET: "GET",
  },
  NUMBERS: {
    LOCK_UNLOCK: "LOCK_UNLOCK",
    LOCK: "LOCK",
    RESERVE_UNLOCK: "RESERVE_UNLOCK",
    UNLOCK: "UNLOCK",
    NONE: "None",
    REGULAR: "Regular",
    NON_CONTIGUOUS: "Non_Contiguous",
    QUANTITY_ONE: 1,
    LOCK_DURATION: 5,
    LOCK_DURATION_DAYS: 29,
  },
  CART_ACTIONS: {
    ADD: "add",
    REMOVE: "remove",
    NO_CHANGE: "nochange",
    MODIFY: "modify",
    UPDATE: "update",
  },
  HEADERS: {
    AUTHORIZATION: "Authorization",
    DS_CORRELATION_ID: "ds-correlation-id",
    DS_APPLICATION_LABEL: "ds-application-label",
    DS_SOURCE_SYSTEM: "ds-source-system",
    CONTENT_TYPE: "content-type",
    CIDN: "cidn",
    CONTENT_LANGUAGE: "content-language",
    ACCEPT: "accept",
    REIMAGINE_SPA: "REIMAGINE-SPA",
    STRATEGIC_ORDERING_UI: "STRATEGIC-ORDERING-UI",
    APPLICATION_JSON: "application/json",
    EN_AU: "en-au",
  },
  CHARACTERISTICS: {
    CONTRACT_TYPE: "ContractType",
    CONTRACT_TERM: "contractTerm",
    TECHNOLOGY_TYPE: "TechnologyType",
    DEVICE_COLOR: "MobileHandsetColour",
    DEVICE_MANUFACTURER: "MobileHandsetManufacturer",
    ACCESSORIES_COLOR: "Colour",
    ACCESSORIES_MANUFACTURER: "Manufacturer",
    SKU: "SKU",
  },
  PAYMENT_TYPE: {
    OUTRIGHT: "outright",
    PURCHASE: "Purchase",
    HARDWARE_REPAYMENT: "Hardware Repayment",
    TWENTYFOUR_MONTH_PAYMENT: CART.PRODUCTS.PAYMENT_24_MONTHS,
  },
  PRODUCT_IDS: {
    AM_OFFER: "DMCAT_Offer_001233",
    AM_PLAN: "O_001233_DMCAT_ProductSpecification_001196_PO",
    DEVICES: "O_001231_DMCAT_ProductSpecification_001211_PO",
    DEVICES_AND_ACCESSORIES_OFFER: "DMCAT_Offer_001231",
    ACCESSORIES: "O_001231_DMCAT_ProductSpecification_001389_PO",
  },
};
