/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import { ableDevelopmentUrl } from "@utils/helper";
import { Column, MessageSection, Spacing } from "@able/react";
import { useEffect, useState } from "react";
import { useAppSelector } from "@state/hooks";
import { ConfigurePlansListView } from "./components/list-view";
import {
  CONFIGURE_BARRINGS_AND_ADDONS,
  PLAN_TYPES,
  CATEGORY_NAMES,
} from "../constant";
import { Tab, TabList } from "telstra-ui/components/tab/Tabs";
import EmptyListMessage from "./components/tab-list-empty";

export function NewPlansByCategory() {
  const { cartId, normalizedPlansQBS: plans } = useAppSelector(
    (store) => store.cart
  );
  const plansByCategory = new Map();
  const categories = [];
  plans.forEach((currentPlan) => {
    const category = currentPlan.category;
    if (plansByCategory.has(category)) {
      plansByCategory.get(category).push(currentPlan);
    } else {
      categories.push(category);
      plansByCategory.set(category, [currentPlan]);
    }
  });

  const [activeCategory, setActiveCategory] = useState("");
  const [planType, setPlanType] = useState(PLAN_TYPES[0]);

  useEffect(() => {
    const categoryArray = Array.from(plansByCategory.keys());
    const initialCategory = CATEGORY_NAMES.find((item) =>
      categoryArray.includes(item)
    );
    setActiveCategory(initialCategory);
  }, [plans.length]);

  const onPlansTabChange = (index) => {
    setActiveCategory(CATEGORY_NAMES[index]);
    setPlanType(PLAN_TYPES[index]);
  };

  return (
    <>
      <Column cols={12} bsm={12} bmd={12} blg={12}>
        <Spacing top="spacing5x">
          <TabList className="category-tabs">
            {PLAN_TYPES.map((category, index) => {
              const activeSelCategory =
                activeCategory === CATEGORY_NAMES[0]
                  ? PLAN_TYPES[0]
                  : activeCategory;
              return (
                <Tab
                  key={category}
                  id={category}
                  tabUrl={window.location.href}
                  active={activeSelCategory === category}
                  onClick={() => onPlansTabChange(index)}
                >
                  {category}
                </Tab>
              );
            })}
          </TabList>
          <Spacing top="spacing3x">
            {plansByCategory.get(activeCategory) ? (
              <>
                <ConfigurePlansListView
                  plansByCategoryActiveCategory={plansByCategory.get(
                    activeCategory
                  )}
                  key={activeCategory}
                />
                <Spacing bottom="spacing10x">
                  <div tabIndex={0}>
                    <MessageSection
                      description={
                        CONFIGURE_BARRINGS_AND_ADDONS.INFORMATION_MESSAGE
                      }
                      variant="Information"
                      developmentUrl={ableDevelopmentUrl}
                      className="message-section"
                    />
                  </div>
                </Spacing>
              </>
            ) : (
              <EmptyListMessage category={planType} cartId={cartId} />
            )}
          </Spacing>
        </Spacing>
      </Column>
    </>
  );
}
