import "./style.scss";
import { Icon, Spacing, TextStyle } from "@able/react";
import { ableDevelopmentUrl } from "@utils/helper";
import { NEW_PLAN_ORDER } from "@pages/new-plan-order/constant";

export default function Inclusions({ inclusions }: { inclusions: string[] }) {
  return (
    <div className="inclusions">
      {inclusions.map((inclusion) => (
        <Spacing className={["inclusion"]} key={inclusion} top="spacing1x">
          {inclusion === NEW_PLAN_ORDER.UNLIMITED_CALLS_INCLUSION ? (
            <Icon
              developmentUrl={ableDevelopmentUrl}
              icon="DataUsage"
              size="24"
            />
          ) : (
            <Icon
              developmentUrl={ableDevelopmentUrl}
              icon="NetworkSpeed"
              size="24"
            />
          )}
          <Spacing left="spacing1x" />
          <TextStyle alias="Base">{inclusion}</TextStyle>
        </Spacing>
      ))}
    </div>
  );
}
