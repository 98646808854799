import "./style.scss";
import {
  ActionButton,
  Column,
  IconButton,
  Spacing,
  Surface,
  Grid,
  TextStyle,
  ModalDialogCritical,
  useToggle,
  ModalDialogConfirmationDestructive,
} from "@able/react";
import {
  CONFIGURE_BARRINGS_AND_ADDONS,
  DELETE_PLAN_MODAL,
} from "@pages/new-plan-order/steps/configure-plan-barring-add-ons/constant";
import { ableDevelopmentUrl } from "@utils/helper";
import { QuantityInput } from "telstra-ui/components/quantity/quantity";
import Selections from "../../selections";
import { useState } from "react";
import SplitModal from "./components/split-modal";
import { useAppDispatch } from "@state/hooks";
import { createDeltaItem } from "@services/templates/cart";
import { deletePlan, setDeltaPlans, updateQBSPlan } from "@state/cart-slice";
import { useGetOffersQuery } from "@services/catalog";
import { ConfigureSection } from "./components/configure-section";

export function ConfigurePlansListSurface({ plan }) {
  const [modalIsOpen, toggleModal] = useToggle();
  const [modalForMultipleQuantity, setStateModalForMultipleQuantity] =
    useState(false);
  const [configureSectionVisible, setConfigureSectionVisible] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { data: offers } = useGetOffersQuery();

  const { planName, productOfferingId } = plan;
  const matchingOffer = offers?.find((offer) => {
    const { offerId, offerName } = offer;
    if (offerName === planName && offerId === productOfferingId) return true;
    return false;
  });

  return (
    <Grid>
      <Column cols={12}>
        <Surface
          background="materialBasePrimary"
          cornerRadius="cornerRadiusSlight"
          variant="SurfaceSlight"
          key={"surface" + plan.id}
        >
          <>
            <div className="listViewSurface">
              <Spacing
                bottom="spacing1nHalf"
                left="spacing4x"
                right="spacing4x"
                top="spacing1nHalf"
              >
                <Grid className="planHeader">
                  <Column cols={7}>
                    <div tabIndex={0}>
                      <TextStyle alias="HeadingD" element="h2">
                        {`${plan.planName}`}
                      </TextStyle>
                    </div>
                  </Column>
                  <Column cols={5}>
                    <div className="headingSectionButtons">
                      <QuantityInput
                        className="quantity-button"
                        id={plan.id}
                        key={"quantity" + plan.id}
                        value={plan.quantity}
                        min={1}
                        max={100}
                        enable={true}
                        step={1}
                        onChange={(_index, quantity) => {
                          dispatch(updateQBSPlan({ plan, quantity }));
                        }}
                      />
                      <ActionButton
                        element="button"
                        id={plan.id}
                        key={"split" + plan.id}
                        label={CONFIGURE_BARRINGS_AND_ADDONS.LIST_VIEW.SPLIT}
                        icon="Directions"
                        variant="LowEmphasis"
                        developmentUrl={ableDevelopmentUrl}
                        events={{
                          onClick: () => {
                            plan.quantity === 1
                              ? toggleModal()
                              : setStateModalForMultipleQuantity(true);
                          },
                        }}
                      />
                      <ModalDialogConfirmationDestructive
                        developmentUrl={ableDevelopmentUrl}
                        title={
                          plan.quantity > 1
                            ? DELETE_PLAN_MODAL.MULTIPLE_PLAN_TITLE
                            : DELETE_PLAN_MODAL.SINGLE_PLAN_TITLE
                        }
                        description={
                          plan.quantity > 1
                            ? DELETE_PLAN_MODAL.MULTIPLE_PLAN_DESCRIPTION
                            : DELETE_PLAN_MODAL.SINGLE_PLAN_DESCRIPTION
                        }
                        stackButtonOnVXS={false}
                        confirmButtonLabel={
                          DELETE_PLAN_MODAL.CONFIRM_BUTTON_LABEL
                        }
                        confirmButtonEvents={{
                          onClick: () => {
                            dispatch(deletePlan(plan.id));
                            dispatch(
                              setDeltaPlans(createDeltaItem(plan, "remove"))
                            );
                          },
                        }}
                        cancelButtonLabel={
                          DELETE_PLAN_MODAL.CANCEL_BUTTON_LABEL
                        }
                        cancelButtonEvents={{
                          onClick: () => null,
                        }}
                      >
                        <IconButton
                          icon="Calendar"
                          screenReaderContent={
                            plan.quantity > 1
                              ? CONFIGURE_BARRINGS_AND_ADDONS.LIST_VIEW
                                  .DELETE_PLANS_BUTTON
                              : CONFIGURE_BARRINGS_AND_ADDONS.LIST_VIEW
                                  .DELETE_PLAN_BUTTON
                          }
                          variant="Destructive"
                          developmentUrl={ableDevelopmentUrl}
                          size="Big"
                        />
                      </ModalDialogConfirmationDestructive>
                    </div>
                  </Column>
                </Grid>
              </Spacing>
            </div>
            <Spacing
              bottom="spacing3x"
              left="spacing4x"
              right="spacing4x"
              top="spacing3x"
            >
              <Grid>
                <div className="able-column cols-8" tabIndex={0}>
                  <Grid>
                    <Column cols={6}>
                      <TextStyle alias="Label" element="h3">
                        {
                          CONFIGURE_BARRINGS_AND_ADDONS.LIST_VIEW
                            .NETWORK_FEATURE_HEADER
                        }
                      </TextStyle>
                      <Selections plan={plan} field="barrings" />
                    </Column>
                    <Column cols={6}>
                      <TextStyle alias="Label" element="h3">
                        {CONFIGURE_BARRINGS_AND_ADDONS.LIST_VIEW.ADDON_HEADER}
                      </TextStyle>
                      <Selections plan={plan} field="addOns" />
                    </Column>
                  </Grid>
                </div>
                <Column cols={4}>
                  <div className="configureBtn">
                    <ActionButton
                      element="button"
                      label={
                        CONFIGURE_BARRINGS_AND_ADDONS.LIST_VIEW
                          .CONFIGURE_PLANS_BUTTON
                      }
                      icon="ActivateService"
                      variant="MediumEmphasis"
                      className="configureBtn"
                      events={{
                        onClick: () =>
                          setConfigureSectionVisible(!configureSectionVisible),
                      }}
                    />
                  </div>
                </Column>
              </Grid>
            </Spacing>
            {configureSectionVisible && (
              <ConfigureSection
                plan={plan}
                offer={matchingOffer}
                setConfigureSectionVisible={setConfigureSectionVisible}
              />
            )}
          </>
        </Surface>
      </Column>
      <ModalDialogCritical
        isShowing={modalIsOpen}
        developmentUrl={ableDevelopmentUrl}
        setHideDialog={toggleModal}
        OKButtonEvents={{ onClick: () => toggleModal }}
        title={CONFIGURE_BARRINGS_AND_ADDONS.SINGLE_QUANTITY_SPLIT_MODAL}
      />
      <SplitModal
        modalForMultipleQuantity={modalForMultipleQuantity}
        setStateModalForMultipleQuantity={setStateModalForMultipleQuantity}
        plan={plan}
      />
    </Grid>
  );
}
