/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import {
  IconButton,
  ModalDialogConfirmationDestructive,
  TextStyle,
} from "@able/react";
import { CONFIGURE_DEVICES_AND_ACCESSORIES } from "@pages/devices-and-accessories/constant";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import {
  ableDevelopmentUrl,
  prefixWithDollarSign,
  renderBoldText,
} from "@utils/helper";
import { AdvanceTableV3 } from "telstra-ui/components/table-advance-v3/AdvanceTableV3";
import { Checkbox } from "telstra-ui/components/checkbox/Checkbox";
import { useEffect } from "react";
import { toggleAllDevices } from "@state/select-devices-slice";
import DevicesCheckbox from "./components/devices-checkbox";
import PaymentType from "./components/payment-type";
import { deleteDevice, setDeltaPlans } from "@state/cart-slice";
import { NormalizedDevice } from "@state/types";
import { createDeltaItem } from "@services/templates/cart";
import { CART } from "@utils/common-static-data";

type IRowData = {
  original: NormalizedDevice;
};
const ConfigureDeviceAndAccessoriesTable = () => {
  const { normalizedDevicesAndAccesories: devicesAndAccesories } =
    useAppSelector((store) => store.cart);
  const configuredDeviceSelected = useAppSelector(
    (store) => store.selectDevices.configuredDeviceSelected
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleAllDevices([]));
  }, []);

  const devicewithOutrightPrice = () => {
    let count = 0;
    devicesAndAccesories.forEach((item) => {
      if (item.twelveMonthPayment === 0 && item.twentyfourMonthPayment === 0) {
        count++;
      }
    });
    return count;
  };

  const handleToggleAll = () => {
    if (
      configuredDeviceSelected.length + devicewithOutrightPrice() ===
      devicesAndAccesories.length
    ) {
      dispatch(toggleAllDevices([]));
    } else {
      const devicesAndAccesoryIds = [];
      for (const item of devicesAndAccesories) {
        if (
          item.twelveMonthPayment === 0 &&
          item.twentyfourMonthPayment === 0
        ) {
          continue;
        } else devicesAndAccesoryIds.push(item.id);
      }
      dispatch(toggleAllDevices(devicesAndAccesoryIds));
    }
  };

  const getPricingDetails = (rowData: IRowData) => {
    const pricing = rowData?.original;
    return (
      <>
        {pricing?.paymentType === "" && <></>}
        {pricing?.paymentType === "outright" && (
          <TextStyle alias="Label">
            {prefixWithDollarSign(pricing?.outright)}
          </TextStyle>
        )}
        {pricing?.paymentType === CART.PRODUCTS.PAYMENT_12_MONTHS && (
          <TextStyle alias="Label">
            {prefixWithDollarSign(pricing?.twelveMonthPayment)}
          </TextStyle>
        )}
        {pricing?.paymentType === CART.PRODUCTS.PAYMENT_24_MONTHS && (
          <TextStyle alias="Label">
            {prefixWithDollarSign(pricing?.twentyfourMonthPayment)}
          </TextStyle>
        )}
      </>
    );
  };
  const columnData = [
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right no-border-bottom",
      style: { paddingLeft: "8px" },
      accessor: "itemsdevices",
      Header:
        devicewithOutrightPrice() === devicesAndAccesories.length ? (
          <TextStyle alias="Label">
            {
              CONFIGURE_DEVICES_AND_ACCESSORIES
                .CONFIGURE_DEVICES_ACCESSORIES_TABLE.ITEM
            }
          </TextStyle>
        ) : (
          <Checkbox
            id="toggle-all"
            checked={
              devicewithOutrightPrice() === 0
                ? devicesAndAccesories.length ===
                  configuredDeviceSelected.length
                : devicesAndAccesories.length ===
                  configuredDeviceSelected.length + devicewithOutrightPrice()
            }
            label={renderBoldText(
              CONFIGURE_DEVICES_AND_ACCESSORIES
                .CONFIGURE_DEVICES_ACCESSORIES_TABLE.ITEM
            )}
            onChange={() => {
              handleToggleAll();
            }}
          />
        ),
      sortable: true,
      resizable: false,
      minWidth: 100,
      Cell: (rowData: IRowData) => (
        <div
          className={`${
            rowData.original.twelveMonthPayment === 0 &&
            rowData.original.twentyfourMonthPayment === 0
              ? "item-title"
              : ""
          }`}
        >
          {rowData.original.twelveMonthPayment === 0 &&
          rowData.original.twentyfourMonthPayment === 0 ? (
            <>
              <TextStyle alias="Tag">{rowData.original.category}</TextStyle>
              <TextStyle alias="Label">{rowData.original.name}</TextStyle>
              <TextStyle alias="Tag">
                {`${rowData.original.technologyType} | ${rowData.original.color} |
                --`}
              </TextStyle>
              <TextStyle alias="Tag">
                {`${CONFIGURE_DEVICES_AND_ACCESSORIES.SKU} ${rowData.original.sku}`}
              </TextStyle>
            </>
          ) : (
            <DevicesCheckbox device={rowData.original} />
          )}
        </div>
      ),
    },
    {
      headerClassName: "table-align-left quantity-header",
      className: "td-align-center border-right no-border-bottom",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(
        CONFIGURE_DEVICES_AND_ACCESSORIES.CONFIGURE_DEVICES_ACCESSORIES_TABLE
          .QUANTITY
      ),
      sortable: true,
      resizable: false,
      minWidth: 25,
      Cell: (data: IRowData) => (
        <TextStyle alias="Base">{`${data.original.quantity}`}</TextStyle>
      ),
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right no-border-bottom",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(
        CONFIGURE_DEVICES_AND_ACCESSORIES.CONFIGURE_DEVICES_ACCESSORIES_TABLE
          .PAYMENT_TYPE
      ),
      sortable: true,
      resizable: false,
      minWidth: 56,
      Cell: (rowData: IRowData) =>
        rowData.original.twelveMonthPayment === 0 &&
        rowData.original.twentyfourMonthPayment === 0 ? (
          <TextStyle alias="Base">
            {CONFIGURE_DEVICES_AND_ACCESSORIES.OUTRIGHT}
          </TextStyle>
        ) : (
          <PaymentType details={rowData.original} />
        ),
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-right border-right no-border-bottom",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(
        CONFIGURE_DEVICES_AND_ACCESSORIES.CONFIGURE_DEVICES_ACCESSORIES_TABLE
          .UNIT_CHARGE
      ),
      sortable: true,
      resizable: false,
      minWidth: 50,
      Cell: (rowData: IRowData) => getPricingDetails(rowData),
    },
    {
      headerClassName: "table-align-left remove-column",
      className: "td-align-center border-right no-border-bottom ",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(
        CONFIGURE_DEVICES_AND_ACCESSORIES.CONFIGURE_DEVICES_ACCESSORIES_TABLE
          .REMOVE
      ),
      sortable: true,
      resizable: false,
      minWidth: 20,
      Cell: (rowData: IRowData) => {
        return (
          <ModalDialogConfirmationDestructive
            developmentUrl={ableDevelopmentUrl}
            title={
              CONFIGURE_DEVICES_AND_ACCESSORIES
                .CONFIGURE_DEVICES_ACCESSORIES_TABLE.REMOVE_DEVICE_MODAL.TITLE
            }
            description={
              CONFIGURE_DEVICES_AND_ACCESSORIES
                .CONFIGURE_DEVICES_ACCESSORIES_TABLE.REMOVE_DEVICE_MODAL
                .DESCRIPTION
            }
            stackButtonOnVXS={false}
            confirmButtonLabel={
              CONFIGURE_DEVICES_AND_ACCESSORIES
                .CONFIGURE_DEVICES_ACCESSORIES_TABLE.REMOVE_DEVICE_MODAL.REMOVE
            }
            confirmButtonEvents={{
              onClick: () => {
                dispatch(
                  setDeltaPlans(createDeltaItem(rowData.original, "remove"))
                );
                dispatch(deleteDevice(rowData.original.id));
              },
            }}
            cancelButtonLabel={
              CONFIGURE_DEVICES_AND_ACCESSORIES
                .CONFIGURE_DEVICES_ACCESSORIES_TABLE.REMOVE_DEVICE_MODAL.CANCEL
            }
            cancelButtonEvents={{}}
          >
            <IconButton
              icon="Calendar"
              screenReaderContent={
                CONFIGURE_DEVICES_AND_ACCESSORIES
                  .CONFIGURE_DEVICES_ACCESSORIES_TABLE.REMOVE_DEVICE_MODAL
                  .REMOVE
              }
              variant="Destructive"
              developmentUrl={ableDevelopmentUrl}
              size="Big"
            />
          </ModalDialogConfirmationDestructive>
        );
      },
    },
  ];

  return (
    <div className="configure-devices-table">
      <AdvanceTableV3
        columns={columnData}
        data={devicesAndAccesories}
        resizable={true}
        className="td-align-left"
        totalRecordLength={devicesAndAccesories.length}
        showPagination={false}
        pages={1}
        pageSize={devicesAndAccesories.length}
        defaultPageSize={10}
        page={1}
        pageHeaderClassName={"header"}
        isStickyHeader={true}
        sendApi={() => null}
      />
    </div>
  );
};

export default ConfigureDeviceAndAccessoriesTable;
