import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { v4 as uuidv4 } from "uuid";
import {
  IRequester,
  IAssociatedBillingAccounts,
  IValidateReferenceNumberRes,
  IGetFeatureFlagsAEM,
  RecommendedAddresses,
} from "../state/types";
import { Configurations } from "@config/index";
import { CONSTANTS } from "./constant";
import { saveContactsResponse } from "@state/cart-slice";

const getEnvironment = () => {
  return window?.env || "MOCK";
};

export const baseUrl = Configurations[getEnvironment()]?.apiHostUrl;
export const caseManagementHostUrl =
  Configurations[getEnvironment()]?.caseManagementHostUrl;
export const numberManagementHostURL =
  Configurations[getEnvironment()]?.numberManagementHostURL;
export const aemHostURL =
  Configurations[getEnvironment()]?.aemDynamicContentHostURL; // change to 'http://localhost:5050' when pointing to mock.

interface IWindow {
  authProvider: any;
  cidn: string;
  env: string;
}
declare const window: IWindow;

export const baseApiService = createApi({
  reducerPath: "baseApiService",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers, { getState }) => {
      const authorityUrl = Configurations[getEnvironment()]?.authority;
      const clientId = Configurations[getEnvironment()]?.client_id;
      const tokenKey = `oidc.user:${authorityUrl}:${clientId}`;
      const token = JSON.parse(sessionStorage.getItem(tokenKey));
      const correlationId = uuidv4();
      const accessToken = token?.access_token;
      const cidn = sessionStorage.getItem("cidn");
      headers.set(CONSTANTS.HEADERS.AUTHORIZATION, `Bearer ${accessToken}`);
      headers.set(CONSTANTS.HEADERS.DS_CORRELATION_ID, correlationId);
      headers.set(
        CONSTANTS.HEADERS.DS_APPLICATION_LABEL,
        CONSTANTS.HEADERS.REIMAGINE_SPA
      );
      headers.set(
        CONSTANTS.HEADERS.DS_SOURCE_SYSTEM,
        CONSTANTS.HEADERS.STRATEGIC_ORDERING_UI
      );
      headers.set(CONSTANTS.HEADERS.CIDN, cidn);
      headers.set(
        CONSTANTS.HEADERS.CONTENT_TYPE,
        CONSTANTS.HEADERS.APPLICATION_JSON
      );
      headers.set(CONSTANTS.HEADERS.CONTENT_LANGUAGE, CONSTANTS.HEADERS.EN_AU);
      headers.set(CONSTANTS.HEADERS.ACCEPT, CONSTANTS.HEADERS.APPLICATION_JSON);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getContacts: builder.query<IRequester, void>({
      query: () => ({
        url: "customer-information/associated-contacts",
        method: CONSTANTS.METHODS.GET,
        headers: {
          "access-control-request-method": "GET",
        },
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(saveContactsResponse(data));
      },
    }),
    getBillingAccounts: builder.query<IAssociatedBillingAccounts, void>({
      query: () => "customer-information/associated-billing-accounts",
    }),
    validateReferenceNumber: builder.query<IValidateReferenceNumberRes, string>(
      {
        query: (caseNumber) => `case-management/cases/${caseNumber}`,
      }
    ),
    getRecommendedAddresses: builder.query<RecommendedAddresses, void>({
      query: () => "customer-information/recommended-addresses",
    }),
  }),
});

export const AEMDynamicContentAPI = createApi({
  reducerPath: "AEMDynamicContentAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: aemHostURL,
  }),
  endpoints: (builder) => ({
    getFeatureFlags: builder.query<IGetFeatureFlagsAEM, void>({
      query: () => `/feature-flags/config.json`, // remove config.json from query path when pointing to mock.
    }),
  }),
});

export const {
  useGetContactsQuery,
  useGetBillingAccountsQuery,
  useLazyValidateReferenceNumberQuery,
  useGetRecommendedAddressesQuery,
} = baseApiService;

export const { useGetFeatureFlagsQuery } = AEMDynamicContentAPI;
