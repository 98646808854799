import "./style.scss";
import {
  Grid,
  Spacing,
  InlineLink,
  TextStyle,
  Column,
  Card,
} from "@able/react";
import { useSearchParams } from "react-router-dom";
import { LANDING_PAGE } from "./constant";

const LandingPageComponent = (props) => {
  const [queryParams] = useSearchParams();
  const cidn = queryParams.get("cidn");
  const usertype = queryParams.get("usertype");
  const queryParamsSubPath = `?cidn=${cidn}&usertype=${usertype}`;
  return (
    <>
      <div className="landing-page">
        <Grid>
          <Spacing top="spacing2x" bottom="spacing4x">
            <TextStyle alias="HeadingB" element="h1">
              {LANDING_PAGE.HEADING}
            </TextStyle>
          </Spacing>
          {LANDING_PAGE.ACTIONS.map((action) => {
            return (
              <Column
                key={action.id}
                cols={12}
                bsm={6}
                bmd={4}
                blg={4}
                className="action"
              >
                <Card
                  variant="Destination"
                  imageType="picto"
                  picto={action.pictoName}
                  title={action.label}
                  titleElement="h2"
                  summary={action.description}
                  useLargePictoOnDesktop={false}
                  destinationTitleActionProps={{
                    element: "Link",
                    to: `${action.link}${queryParamsSubPath}`,
                  }}
                />
              </Column>
            );
          })}
          <Spacing top="spacing5x" bottom="spacing10x">
            <InlineLink>
              <TextStyle alias="Base" alignment="Center">
                <div>
                  {LANDING_PAGE.FIRST_TIME_USER}
                  <a href="/"> {LANDING_PAGE.VIEW_VIDEOS_AND_TUTORIALS}</a>
                </div>
              </TextStyle>
            </InlineLink>
          </Spacing>
        </Grid>
      </div>
    </>
  );
};

export default LandingPageComponent;
