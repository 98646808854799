import "./style.scss";
import { TextField } from "@able/react";
import { ableDevelopmentUrl, REGULAR_EXPRESSIONS } from "@utils/helper";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { SIM_CONFIG_TABLE } from "@pages/new-plan-order/constant";
import { updateQBSPlansArrUsingIndex } from "@state/cart-slice";
import { useState } from "react";

export default function SIMSerialNumber({ planId, setId }) {
  const dispatch = useAppDispatch();
  const plansInfo = useAppSelector((state) => state.cart.normalizedPlansQBS);
  const planIndex = plansInfo.findIndex((planInfo) => planInfo.id === planId);
  const [fieldValue, setFieldValue] = useState(
    plansInfo[planIndex].setIds[setId].simSerialNumber
  );
  const [fieldInvalid, setFieldInvalid] = useState(
    plansInfo[planIndex].setIds[setId].invalid_SIM_number ||
      plansInfo[planIndex].setIds[setId].invalid_UNMS_SIM_number
  );

  const checkSIMValidation = () => {
    const SNValue = fieldValue;
    let invalidSIM;
    if (SNValue === "") {
      invalidSIM =
        plansInfo[planIndex].setIds[setId].invalid_SIM_number || false;
    } else if (SNValue.length !== 13) {
      invalidSIM = true;
    } else {
      invalidSIM = false;
    }
    const payload = {
      planIndexes: [planIndex],
      setIds: [setId],
      fields: {
        simSerialNumber: fieldValue,
        invalid_SIM_number: invalidSIM,
      },
      isAnyFieldInSetInvalid: invalidSIM,
    };
    dispatch(updateQBSPlansArrUsingIndex(payload));
  };
  const updateSIMNumber = (e) => {
    setFieldValue(e.target.value);
    setFieldInvalid(false);
  };

  const getErrorMessage = () => {
    const plan = plansInfo[planIndex].setIds[setId];
    return (
      (plan.invalid_SIM_number &&
        plan.simSerialNumber.length > 0 &&
        plan.simSerialNumber.length != 13 &&
        SIM_CONFIG_TABLE.SIM_SERIAL_NUMBER_13DIGIT_ERROR) ||
      (plan.invalid_UNMS_SIM_number &&
        SIM_CONFIG_TABLE.SIM_SERIAL_NUMBER_INVALID_UNMS) ||
      (plan.invalid_SIM_number &&
        plan.simSerialNumber.length === 13 &&
        SIM_CONFIG_TABLE.SIM_SERIAL_NUMBER_DUPLICATE)
    );
  };

  return (
    <TextField
      key={"simSerialNumber" + setId}
      id={"simSerialNumber" + setId}
      name={"simSerialNumber" + setId}
      developmentUrl={ableDevelopmentUrl}
      className="sim-serial-number"
      invalid={fieldInvalid}
      invalidInputText={getErrorMessage()}
      value={fieldValue}
      events={{
        onBlur: () => {
          checkSIMValidation();
        },
        onChange: (e) => {
          if (
            e.target.value === "" ||
            REGULAR_EXPRESSIONS.NUMBERS_ONLY.test(e.target.value)
          ) {
            updateSIMNumber(e);
          }
        },
      }}
    />
  );
}
