import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICart, NormalizedPlanQBS } from "./types";
import { resetAction } from "./reset";
import { v4 as uuidv4 } from "uuid";

const initialState: ICart = {
  cartId: "",
  billingAccount: "",
  plans: {},
  normalizedDevicesAndAccesories: [],
  cartFlow: "",
  normalizedPlansQBS: [],
  initialPlans: {},
  patchCartResponse: {
    cartId: "",
    cartItems: [],
  },
  error: null,
  deltaPlans: {},
  invalidBillingAccount: false,
  requiredBillingError: false,
  contactsResponse: {
    cidn: "",
    name: "",
    status: "",
    contacts: [],
  },
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCart: (state, action) => {
      return { ...state, ...action.payload };
    },

    setInitialCart: () => {
      return { ...initialState };
    },

    updateCartAddOns: (state, action) => {
      const { planId, selectedAddons } = action.payload;
      const index = state.normalizedPlansQBS.findIndex(
        (plan) => plan.id === planId
      );
      state.normalizedPlansQBS[index].addOns = { ...selectedAddons };
    },

    setNormalizedPlansQBS: (state, action) => {
      const plans = JSON.parse(JSON.stringify(action.payload));
      state.initialPlans = action.payload.reduce(
        (acc, cur) => ({ ...acc, [cur.id]: cur }),
        {}
      );
      plans.forEach((plan) => {
        if (plan.quantity > 1) {
          Object.keys(plan.setIds).forEach((setId) => {
            // downstream does not return simType and SIM serial number if quantity is more than 1,
            // Hence hardcoding the value of simType.
            plan.setIds[setId].simType = "SIM Card";
          });
        }
      });
      state.normalizedPlansQBS = plans;
    },

    setNormalizedDevicesAndAccesories: (state, action) => {
      state.normalizedDevicesAndAccesories = action.payload;
    },
    setCartFlow: (state, action) => {
      state.cartFlow = action.payload;
    },

    savePatchCartResponse: (state, action) => {
      state.patchCartResponse = action.payload;
    },

    saveContactsResponse: (state, action) => {
      state.contactsResponse = action.payload;
    },

    createMultiplePlansBasedOnSplit: (state, action) => {
      const { filteredList, planId } = action.payload;
      let index = state.normalizedPlansQBS.findIndex(
        (item) => item.id === planId
      );
      state.normalizedPlansQBS[index].quantity = Number(
        filteredList[0].splitField
      );
      const splitList = [];
      filteredList.slice(1).forEach((item) => {
        const list = state.normalizedPlansQBS.map((plan) => ({ ...plan }));
        const planState = list[index];
        planState.quantity = Number(item.splitField);
        planState.id = planId + uuidv4();
        planState.setIds = {};
        planState.itemAttributes = [];
        for (let i = 1; i <= Number(item.splitField); i++) {
          planState.setIds[i] = {
            username: "",
            simSerialNumber: "",
            phoneNumber: "",
            simType: "",
            refreshCount: 0,
            invalid_UNMS_SIM_number: false,
            invalid_SIM_number: false,
            invalid_username: false,
            invalid_SIM_type: false,
          };
        }
        splitList.push(planState);
      });
      state.normalizedPlansQBS.splice(++index, 0, ...splitList);
    },

    updateQBSPlansArrUsingIndex: (state, action) => {
      action.payload.planIndexes?.length &&
        action.payload.planIndexes.forEach((planIndex) => {
          action.payload.setIds.forEach((setId) => {
            state.normalizedPlansQBS[planIndex].setIds[setId] &&
              (state.normalizedPlansQBS[planIndex].setIds[setId] = {
                ...state.normalizedPlansQBS[planIndex].setIds[setId],
                ...action.payload.fields,
              });
          });
          if (action.payload.isAnyFieldInSetInvalid) {
            state.normalizedPlansQBS[planIndex] = {
              ...state.normalizedPlansQBS[planIndex],
              isAnyFieldInSetInvalid: action.payload.isAnyFieldInSetInvalid,
            };
          } else {
            state.normalizedPlansQBS[planIndex] = {
              ...state.normalizedPlansQBS[planIndex],
              isAnyFieldInSetInvalid: false,
            };
          }
        });
      if (action.payload.sort) {
        state.normalizedPlansQBS = state.normalizedPlansQBS.sort(
          (planA, planB) => {
            return (
              Number(planB.isAnyFieldInSetInvalid) -
              Number(planA.isAnyFieldInSetInvalid)
            );
          }
        );
      }
    },

    deletePlansUsingSetIdsQBS: (state, action) => {
      const { planIndex, setIdsToBeRemoved } = action.payload;
      setIdsToBeRemoved.forEach((setId) => {
        delete state.normalizedPlansQBS[planIndex].setIds[setId];
        state.normalizedPlansQBS[planIndex].quantity--;
      });

      if (state.normalizedPlansQBS[planIndex].quantity === 0) {
        state.normalizedPlansQBS = state.normalizedPlansQBS.filter(
          (plan, index) => index !== planIndex
        );
      }
    },

    updateAssignedPhoneNumbers: (state, action) => {
      const { selectedIds, selectedNumbers, numbersForUnassignment } =
        action.payload;
      state.normalizedPlansQBS.forEach((plan) => {
        selectedIds.forEach((ids, i) => {
          if (plan.id === ids.planId) {
            plan.setIds[ids.setId].phoneNumber = selectedNumbers[i]
              ? selectedNumbers[i]
              : numbersForUnassignment[i];
          }
        });
      });
    },

    updateSimType: (state, action) => {
      const { selectedIds, fields } = action.payload;
      state.normalizedPlansQBS.forEach((plan) => {
        selectedIds.forEach((ids, i) => {
          if (plan.id === ids.planId) {
            plan.setIds[ids.setId] = {
              ...plan.setIds[ids.setId],
              ...fields,
            };
          }
        });
      });
    },

    deletePlan: (state, action) => {
      state.normalizedPlansQBS = state.normalizedPlansQBS.filter(
        (plan) => plan.id !== action.payload
      );
    },

    setDeltaPlans: (state, action) => {
      state.deltaPlans[action.payload.id] = action.payload;
    },

    resetDeltaPlans: (state) => {
      state.deltaPlans = {};
    },

    removeDeltaItem: (state, action) => {
      delete state.deltaPlans[action.payload];
    },

    updateDevicePayment: (state, action) => {
      const deviceId = action.payload.id.slice(12);
      const index = state.normalizedDevicesAndAccesories.findIndex(
        (item) => item.id === deviceId
      );
      state.normalizedDevicesAndAccesories[index].paymentType =
        action.payload.value;
      state.normalizedDevicesAndAccesories[index].isPaymentTypeSelected = true;
    },

    deleteDevice: (state, action) => {
      state.normalizedDevicesAndAccesories =
        state.normalizedDevicesAndAccesories.filter(
          (device) => device.id !== action.payload
        );
    },

    setRequiredForPaymentType: (state, action) => {
      action.payload.forEach((deviceId) => {
        const index = state.normalizedDevicesAndAccesories.findIndex(
          (item) => item.id === deviceId
        );
        state.normalizedDevicesAndAccesories[index].isPaymentTypeSelected =
          false;
      });
    },

    setCartId: (state, action) => {
      state.cartId = action.payload;
      return state;
    },

    updateBillingAccount: (state, action) => {
      const { billingAccount, invalidBillingAccount, requiredBillingError } =
        action.payload;
      state.billingAccount = billingAccount;
      state.invalidBillingAccount = invalidBillingAccount;
      state.requiredBillingError = requiredBillingError;
      return state;
    },

    updatePaymentTypeUsingGroupPayment: (state, action) => {
      const { selectedOption, configuredDeviceSelected } = action.payload;
      configuredDeviceSelected.forEach((itemId) => {
        const index = state.normalizedDevicesAndAccesories.findIndex(
          (item) => item.id === itemId
        );
        state.normalizedDevicesAndAccesories[index].paymentType =
          selectedOption;
        state.normalizedDevicesAndAccesories[index].isPaymentTypeSelected =
          true;
      });
    },

    updateQBSPlan: (
      state,
      action: PayloadAction<{ plan: NormalizedPlanQBS; quantity: number }>
    ) => {
      const { plan: targetPlan, quantity } = action.payload;
      state.normalizedPlansQBS.forEach((plan) => {
        if (plan.id === targetPlan.id) {
          plan.quantity = quantity;
        }
      });
    },
  },

  extraReducers(builder) {
    builder.addCase(resetAction, (state, action) => {
      return { ...initialState };
    });
  },
});

export const {
  setCart,
  setInitialCart,
  updateCartAddOns,
  setNormalizedPlansQBS,
  setNormalizedDevicesAndAccesories,
  savePatchCartResponse,
  saveContactsResponse,
  updateQBSPlansArrUsingIndex,
  updateAssignedPhoneNumbers,
  updateSimType,
  deletePlan,
  setDeltaPlans,
  resetDeltaPlans,
  removeDeltaItem,
  updateDevicePayment,
  deleteDevice,
  setRequiredForPaymentType,
  setCartId,
  updateBillingAccount,
  updatePaymentTypeUsingGroupPayment,
  createMultiplePlansBasedOnSplit,
  setCartFlow,
  deletePlansUsingSetIdsQBS,
  updateQBSPlan,
} = cartSlice.actions;

export default cartSlice.reducer;
