import { useAppSelector } from "@state/hooks";
import { useGetFeatureFlagHook } from "@utils/helper";
import {
  NormalizedDevice,
  PatchCart,
  NormalizedPlanQBS,
  CartItem,
  ICart,
} from "@state/types";
import { CART } from "./common-static-data";
import { CONSTANTS } from "@services/constant";
import { CART_CHARGES_TABLE, CART_PLANS_TABLE } from "@pages/cart/constant";

const { REMOVE, UPDATE, ADD, NO_CHANGE } = CONSTANTS.CART_ACTIONS;
const { ATTRIBUTES } = CART;

export const useShouldEnableGetCartHook = () => {
  const patchCartResponse = useAppSelector(
    (state) => state.cart.patchCartResponse.cartItems
  );
  const isPatchCartResponseAvailable =
    patchCartResponse && patchCartResponse.length > 0;
  const { getCartEnabled } = useGetFeatureFlagHook();
  return getCartEnabled && !isPatchCartResponseAvailable;
};

export function getNormalizedPlansQBS(data: PatchCart) {
  const plans: NormalizedPlanQBS[] = [];
  const addonsArray: NormalizedPlanQBS[] = [];
  const index = {};
  const { cartItems } = data;

  for (const cartItem of cartItems) {
    const { id, productOffering, itemRelationships, itemAttributes, quantity } =
      cartItem;
    const plan = {
      id,
      action: NO_CHANGE,
      quantity,
      itemAttributes: [],
      itemRelationships,
      planName: productOffering.name,
      addOns: {},
      setIds: {},
      isAnyFieldInSetInvalid: false,
      category: null,
      subCategory: null,
      marketSegments: productOffering.marketSegments,
      productOfferingId: productOffering.id,
      characteristics: productOffering.characteristics,
    };

    if (
      productOffering.id === CONSTANTS.PRODUCT_IDS.AM_PLAN ||
      itemRelationships.filter((v) => v.relationshipType === "Bundled").length >
        0
    ) {
      for (const itemAttribute of itemAttributes) {
        if (itemAttribute.name === "BillingAccount") {
          plan.setIds[itemAttribute.setId] = {
            billingAccount: itemAttribute.value,
          };
          plan.setIds[itemAttribute.setId] = {
            username: "",
            simSerialNumber: "",
            phoneNumber: "",
            simType: "",
            refreshCount: 0,
            invalid_UNMS_SIM_number: false,
            invalid_SIM_number: false,
            invalid_username: false,
            invalid_SIM_type: false,
          };
          plan.itemAttributes.push({
            ...itemAttribute,
            action: NO_CHANGE,
          });
        }
        if (itemAttribute.name === "Alias") {
          if (plan.setIds[itemAttribute.setId]) {
            plan.setIds[itemAttribute.setId].username = itemAttribute.value;
          }
        }
      }
      const { characteristics } = productOffering;

      for (const characteristic of characteristics) {
        if (characteristic.name === "PlanType") {
          plan.category = characteristic.value;
        }
        if (characteristic.name === "MobilityPlan") {
          plan.subCategory = characteristic.value;
        }
        if (characteristic.name === "CustomerFacingServiceId") {
          if (plan.setIds[characteristic.setId]) {
            plan.setIds[characteristic.setId].phoneNumber =
              characteristic.value;
          }
        }
        if (characteristic.name === "SIMSerialNumber") {
          if (plan.setIds[characteristic.setId]) {
            plan.setIds[characteristic.setId].simSerialNumber =
              characteristic.value;
            plan.setIds[characteristic.setId].simType =
              CART_PLANS_TABLE.EXISTING_SIM;
          }
        }
        if (characteristic.name === "SIMType") {
          if (plan.setIds[characteristic.setId]) {
            plan.setIds[characteristic.setId].simType = characteristic.value;
          }
        }
      }

      if (plan.category) {
        plans.push(plan);
        index[plan.id] = plans.length - 1;
      } else {
        addonsArray.push(plan);
      }
    }
  }

  for (const addon of addonsArray) {
    let planId;
    for (const relationship of addon.itemRelationships) {
      if (relationship.relationshipType === "Bundled") {
        planId = relationship.id;
      }
    }
    if (plans[index[planId]]) {
      const { characteristics } = addon;
      plans[index[planId]].addOns[addon.productOfferingId] =
        characteristics.length === 0
          ? { name: addon.planName, id: addon.id }
          : {
              name: characteristics[0].name,
              value: characteristics[0].value,
              id: addon.id,
            };
    }
  }

  return plans;
}

export function useCartAttributes() {
  const {
    cartId,
    cartItems = [],
    relatedParties = [],
    cartAttributes = [],
  } = useAppSelector((state) => state.cart.patchCartResponse);

  function getCartId() {
    return cartId;
  }

  function getAllCartAttributes() {
    let submissionDate;
    let internalReferenceNumber;
    let orderReferenceNumber;
    for (const cartAttribute of cartAttributes) {
      if (cartAttribute.name === ATTRIBUTES.SUBMISSION_DATE) {
        submissionDate = cartAttribute.value;
      }
      if (cartAttribute.name === ATTRIBUTES.TELSTRA_INTERNAL_REFERENCE) {
        internalReferenceNumber = cartAttribute.value;
      }
      if (cartAttribute.name === ATTRIBUTES.ORDER_REFERENCE) {
        orderReferenceNumber = cartAttribute.value;
      }
    }
    return { submissionDate, internalReferenceNumber, orderReferenceNumber };
  }

  function getCartAttribute(attribute) {
    for (const cartAttribute of cartAttributes) {
      if (cartAttribute.name === attribute) {
        return cartAttribute.value;
      }
    }
    return undefined;
  }

  function getAllItemAttributes() {
    let billingAccount;
    if (cartItems.length) {
      for (const itemAttribute of cartItems[0].itemAttributes) {
        if (itemAttribute.name === ATTRIBUTES.BILLING_ACCOUNT_NUMBER) {
          billingAccount = itemAttribute.value;
          break;
        }
      }
    }
    return { billingAccount };
  }

  function getItemAttribute(attribute) {
    if (cartItems.length) {
      for (const itemAttribute of cartItems[0].itemAttributes) {
        if (itemAttribute.name === attribute) {
          return itemAttribute.value;
        }
      }
    }
    return undefined;
  }

  function getAllRelatedParties() {
    let cidn;
    let customerName;
    let requester;
    let requesterId;
    let orderConfirmationEmailedTo;
    for (const relatedParty of relatedParties) {
      const { id, name } = relatedParty;
      if (relatedParty["@referredType"] === ATTRIBUTES.CUSTOMER) {
        cidn = id;
        customerName = name;
      }
      if (relatedParty["@referredType"] === ATTRIBUTES.REQUESTER) {
        requester = name;
        requesterId = id;
        orderConfirmationEmailedTo = name;
      }
    }
    return {
      cidn,
      customerName,
      requester,
      requesterId,
      orderConfirmationEmailedTo,
    };
  }

  function getRelatedParty(referredType) {
    for (const relatedParty of relatedParties) {
      if (relatedParty["@referredType"] === referredType) {
        return relatedParty;
      }
    }
    return undefined;
  }

  function getAllAttributes() {
    return {
      cartId,
      ...getAllCartAttributes(),
      ...getAllRelatedParties(),
      ...getAllItemAttributes(),
    };
  }

  return {
    getCartId,
    getAllAttributes,
    getAllCartAttributes,
    getCartAttribute,
    getAllItemAttributes,
    getItemAttribute,
    getAllRelatedParties,
    getRelatedParty,
  };
}

export function useContactsInfo() {
  const { name, contacts = [] } = useAppSelector(
    (state) => state.cart.contactsResponse
  );
  const { relatedParties = [] } = useAppSelector(
    (state) => state.cart.patchCartResponse
  );

  function getRequesterName() {
    const requester = relatedParties.find((relatedParty) => {
      return relatedParty["@referredType"] === "Requester";
    });
    if (requester) {
      const contact = contacts.find((obj) => {
        return obj.id === requester.id;
      });

      return contact?.fullName || "";
    }

    return "";
  }

  function getCustomerName() {
    return name;
  }

  return {
    getCustomerName,
    getRequesterName,
  };
}

export function getNormalizedDevicesAndAccessories(data) {
  const devices: NormalizedDevice[] = [];

  const { cartItems } = data;

  for (const cartItem of cartItems) {
    const {
      id,
      quantity,
      productOffering,
      itemRelationships,
      itemAttributes,
      itemPrices,
    } = cartItem;
    const device: NormalizedDevice = {
      id,
      quantity,
      name: "",
      category: "",
      sku: "",
      technologyType: "",
      color: "",
      manufacturer: "",
      outright: 0,
      twelveMonthPayment: 0,
      twentyfourMonthPayment: 0,
      paymentType: CONSTANTS.PAYMENT_TYPE.OUTRIGHT,
      isPaymentTypeSelected: true,
      itemRelationships,
      marketSegments: productOffering.marketSegments,
      productOfferingId: productOffering.id,
      initialValues: {
        paymentType: "",
      },
    };

    if (
      productOffering.id === CONSTANTS.PRODUCT_IDS.DEVICES ||
      productOffering.id === CONSTANTS.PRODUCT_IDS.ACCESSORIES
    ) {
      device.name = productOffering.name;

      for (const itemAttribute of itemAttributes) {
        if (itemAttribute.name === "category") {
          device.category = itemAttribute.value;
        }
      }
      for (const itemPrice of itemPrices) {
        if (itemPrice.priceType === CART_CHARGES_TABLE.ONCE_OFF) {
          if (CART.PRODUCTS.PAYMENT_12_MONTHS in itemPrice) {
            device.twelveMonthPayment =
              itemPrice[CART.PRODUCTS.PAYMENT_12_MONTHS];
          }
          if (CART.PRODUCTS.PAYMENT_24_MONTHS in itemPrice) {
            device.twentyfourMonthPayment =
              itemPrice[CART.PRODUCTS.PAYMENT_24_MONTHS];
          }
          if ("unitPrice" in itemPrice) {
            device.outright = itemPrice?.unitPrice;
          }
        }
      }

      const { characteristics } = productOffering;
      for (const characteristic of characteristics) {
        if (characteristic.name === CONSTANTS.CHARACTERISTICS.TECHNOLOGY_TYPE) {
          device.technologyType = characteristic.value;
        }

        if (productOffering.id === CONSTANTS.PRODUCT_IDS.DEVICES) {
          if (characteristic.name === CONSTANTS.CHARACTERISTICS.DEVICE_COLOR) {
            device.color = characteristic.value;
          }
          if (
            characteristic.name ===
            CONSTANTS.CHARACTERISTICS.DEVICE_MANUFACTURER
          ) {
            device.manufacturer = characteristic.value;
          }
        }

        if (productOffering.id === CONSTANTS.PRODUCT_IDS.ACCESSORIES) {
          if (
            characteristic.name === CONSTANTS.CHARACTERISTICS.ACCESSORIES_COLOR
          ) {
            device.color = characteristic.value;
          }
          if (
            characteristic.name ===
            CONSTANTS.CHARACTERISTICS.ACCESSORIES_MANUFACTURER
          ) {
            device.manufacturer = characteristic.value;
          }
        }
        if (characteristic.name === CONSTANTS.CHARACTERISTICS.SKU) {
          device.sku = characteristic.value;
        }
        if (characteristic.name === CONSTANTS.CHARACTERISTICS.CONTRACT_TYPE) {
          if (characteristic.value === CONSTANTS.PAYMENT_TYPE.PURCHASE) {
            device.paymentType = CONSTANTS.PAYMENT_TYPE.OUTRIGHT;
          } else if (
            characteristic.value === CONSTANTS.PAYMENT_TYPE.HARDWARE_REPAYMENT
          ) {
            const contactTerm = characteristics.find(
              (char) => char.name === CONSTANTS.CHARACTERISTICS.CONTRACT_TERM
            );
            if (contactTerm) {
              device.paymentType =
                +contactTerm.value === 12
                  ? CART.PRODUCTS.PAYMENT_12_MONTHS
                  : CART.PRODUCTS.PAYMENT_24_MONTHS;
            }
          }
        }
      }
      device.initialValues.paymentType = device.paymentType;
      devices.push(device);
    }
  }

  return devices;
}

function createAddonCartItem(
  key: string,
  action: string,
  plan: NormalizedPlanQBS
): CartItem {
  const currentAddon = plan.addOns[key];
  const addonCartItem: CartItem = {
    action,
    quantity: plan.quantity,
    id: currentAddon.id ? currentAddon.id : null,
    consumerItemId: currentAddon.id ? null : key + currentAddon.name,
    itemRelationships: [
      {
        id: plan.id,
        relationshipType: currentAddon.id ? "Bundled" : "ReliesOn",
      },
    ],
    productOffering: {
      id: key,
      marketSegments: plan.marketSegments,
      name: currentAddon.name,
    },
  };

  if (currentAddon.value) {
    addonCartItem.productOffering.characteristics = [
      {
        name: currentAddon.name,
        value: currentAddon.value,
      },
    ];
  }
  return addonCartItem;
}

function createItemAttribute(
  action: string,
  setId: string,
  billingAccount: string
) {
  return {
    "@type": "member",
    action,
    name: CART.ATTRIBUTES.BILLING_ACCOUNT_NUMBER,
    setId,
    value: billingAccount,
    valueType: "String",
  };
}

export const useGeneratePayloadHook = () => {
  const {
    initialPlans,
    normalizedPlansQBS,
    deltaPlans,
    billingAccount,
    patchCartResponse,
  } = useAppSelector((state) => state.cart) as ICart;
  const previousPatchCartResponse: PatchCart = patchCartResponse;
  const { cartId, billingAccount: previousBillingAccount } =
    useCartAttributes().getAllAttributes();

  function getItemAttributes(plan: NormalizedPlanQBS, prevQty: number) {
    const newQty = plan.quantity;
    let itemAttributes = [];

    if (newQty < prevQty) {
      for (let i = 0; i < prevQty; i++) {
        const { setId, value } = plan.itemAttributes[i];
        if (i < newQty) {
          if (billingAccount !== previousBillingAccount) {
            itemAttributes.push(
              createItemAttribute(UPDATE, setId, billingAccount)
            );
          } else {
            itemAttributes.push(createItemAttribute(NO_CHANGE, setId, value));
          }
        } else {
          itemAttributes.push(createItemAttribute(REMOVE, setId, value));
        }
      }
    } else {
      itemAttributes = plan.itemAttributes.map((attribute) => {
        if (billingAccount !== previousBillingAccount) {
          return createItemAttribute(UPDATE, attribute.setId, billingAccount);
        }
        return createItemAttribute(NO_CHANGE, attribute.setId, attribute.value);
      });
      for (let i = 0; i < newQty - prevQty; i++) {
        itemAttributes.push(
          createItemAttribute(ADD, i.toString(), billingAccount)
        );
      }
    }
    return itemAttributes;
  }

  function getConfigureAddonsPayload(): PatchCart {
    // Handle baseOffer
    const baseOffer = previousPatchCartResponse.cartItems.filter(
      (cartItem) => cartItem.itemRelationships.length === 0
    )[0];
    const cartItems: CartItem[] = [
      {
        ...baseOffer,
        action: billingAccount !== previousBillingAccount ? UPDATE : NO_CHANGE,
        itemAttributes: baseOffer.itemAttributes.map((attribute) => {
          if (attribute.name === ATTRIBUTES.BILLING_ACCOUNT_NUMBER) {
            if (billingAccount !== previousBillingAccount) {
              return { ...attribute, value: billingAccount, action: UPDATE };
            }
            return { ...attribute, action: NO_CHANGE };
          }
          return attribute;
        }),
      },
    ];

    Object.values(deltaPlans).forEach((deletedPlan) => {
      // Handle Deleted Plans
      if (initialPlans[deletedPlan.id]) {
        cartItems.push({ action: REMOVE, ...deletedPlan });
      }
    });

    let consumerItemId = 0;
    normalizedPlansQBS.forEach((plan) => {
      if (initialPlans[plan.id]) {
        // This is existing plan
        const cartItem: CartItem = {
          id: plan.id,
          action:
            billingAccount !== previousBillingAccount ||
            initialPlans[plan.id].quantity !== plan.quantity
              ? UPDATE
              : NO_CHANGE,
          quantity: plan.quantity,
          itemAttributes: getItemAttributes(
            plan,
            initialPlans[plan.id].quantity
          ),
          itemRelationships: plan.itemRelationships,
          productOffering: {
            id: plan.productOfferingId,
            marketSegments: plan.marketSegments,
            characteristics: plan.characteristics,
            name: plan.planName,
          },
        };

        cartItems.push(cartItem);

        Object.keys(plan.addOns).forEach((key) => {
          // Handle Added, Updated or Unchanged Addons
          const currentAddon = plan.addOns[key];
          const prevAddon = initialPlans[plan.id].addOns[key];

          const addonCartItem = createAddonCartItem(
            key,
            prevAddon
              ? prevAddon.value === currentAddon.value
                ? NO_CHANGE
                : UPDATE
              : ADD,
            plan
          );

          if (addonCartItem.action !== NO_CHANGE) cartItem.action = UPDATE;

          cartItems.push(addonCartItem);
        });

        Object.keys(initialPlans[plan.id].addOns).forEach((key) => {
          // Handle Deleted addons
          if (!plan.addOns[key]) {
            cartItems.push(
              createAddonCartItem(key, REMOVE, initialPlans[plan.id])
            );
          }
        });
      } else {
        // This is new Split Plan
        consumerItemId++;
        cartItems.push({
          action: ADD,
          quantity: plan.quantity,
          consumerItemId: consumerItemId.toString(),
          itemAttributes: getItemAttributes(plan, 0),
          itemRelationships: plan.itemRelationships,
          productOffering: {
            id: plan.productOfferingId,
            name: plan.planName,
            characteristics: plan.characteristics,
            marketSegments: plan.marketSegments,
          },
        });

        Object.keys(plan.addOns).forEach((key) => {
          // Handle related addons
          const addonCartItem: CartItem = createAddonCartItem(key, ADD, plan);
          addonCartItem.itemRelationships[0].id = consumerItemId.toString();
          cartItems.push(addonCartItem);
        });
      }
    });

    return {
      cartId,
      cartItems,
    };
  }

  return { getConfigureAddonsPayload };
};
