import "./style.scss";
import {
  ActionButton,
  Column,
  Grid,
  SectionHeader,
  Spacing,
  TextStyle,
} from "@able/react";
import {
  ORDER_CONFIRMATION_PAGE,
  ORDER_CONFIRMATION_PAGE_CTA,
} from "./constants";
import OrderDetails from "./order-details";
import OrderSuccessIllustration from "./assets/Illustration_Scene_Success.svg";
import { useAppDispatch } from "@state/hooks";
import { resetAction } from "@state/reset";

export default function OrderConfirmation() {
  const dispatch = useAppDispatch();
  const CTAEvents = {
    returnToCustomerAccountEvent: {
      onClick: () => null,
    },
    createAnotherOrderEvent: {
      onClick: () => {
        dispatch(resetAction());
      },
    },
  };

  return (
    <Spacing className={["order-confirmation"]} bottom="spacing5x">
      <Grid>
        <Column bsm={7} bmd={7} blg={7}>
          <Spacing top="spacing10x">
            <Column>
              <TextStyle alias="HeadingB" element="h1">
                {ORDER_CONFIRMATION_PAGE.HEADING}
              </TextStyle>
            </Column>
          </Spacing>
          <Spacing top="spacing4x">
            <Column>
              <SectionHeader
                title={ORDER_CONFIRMATION_PAGE.SECTION_HEADING}
                variantLevel="Level2"
                headingElement="h2"
                subtitle={ORDER_CONFIRMATION_PAGE.SUBTITLE}
                actionLabel={ORDER_CONFIRMATION_PAGE.ACTION_LABEL}
                className="custom-class"
                actionProps={{ element: "button" }}
              />
            </Column>
          </Spacing>
        </Column>
        <Column bsm={5} bmd={5} blg={5}>
          <Spacing topVSM="spacing10x" topVMD="spacing10x" topVLG="spacing10x">
            <figure
              className="success-illustration"
              style={{ backgroundImage: `url(${OrderSuccessIllustration})` }}
            />
          </Spacing>
        </Column>
        <Column>
          <Spacing top="spacing5x">
            <OrderDetails />
          </Spacing>
        </Column>
        <Spacing top="spacing10x" />
        {ORDER_CONFIRMATION_PAGE_CTA.map((option) => (
          <Column bsm={12} bmd={4} blg={4} key={option.id}>
            <Spacing bottom="spacing3x">
              <ActionButton {...option} events={CTAEvents[option.id]} />
            </Spacing>
          </Column>
        ))}
      </Grid>
    </Spacing>
  );
}
