import { baseApiService } from "./base";

export const configApiService = baseApiService.injectEndpoints({
  endpoints: (builder) => ({
    getConfigs: builder.query<any, void>({
      query: () => "config-management/config",
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
  }),
});

export const { useGetConfigsQuery } = configApiService;
