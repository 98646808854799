import "./style.scss";
import { AdvanceTableV3 } from "telstra-ui/components/table-advance-v3/AdvanceTableV3";
import {
  changeColorCase,
  generateTableData,
  prefixWithDollarSign,
  renderBoldText,
} from "@utils/helper";
import { SELECT_DEVICES } from "@pages/devices-and-accessories/constant";
import { Spacing, TextStyle } from "@able/react";
import QuantityButton from "../components/quantity-button";
import { useEffect } from "react";
import StockStatus from "../components/stock-status";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { setSelectDevicesPagination } from "@state/select-devices-slice";

const CONSTANTS = SELECT_DEVICES.TABLE_COLUMN_HEADERS;

const columns = [
  {
    headerClassName: "table-align-left",
    className: "td-align-left ",
    Header: renderBoldText(CONSTANTS.MODEL),
    resizable: false,
    width: 337,
    Cell: (rowData) => {
      return (
        <div>
          <TextStyle alias="Tag">
            {rowData?.original?.category?.toUpperCase()}
          </TextStyle>
          <TextStyle alias="HeadingD">{rowData?.original?.name}</TextStyle>
          <TextStyle alias="Tag">{`SKU:${rowData?.original?.sku}`}</TextStyle>
        </div>
      );
    },
  },
  {
    headerClassName: "table-align-left",
    className: "td-align-left ",
    Header: renderBoldText(CONSTANTS.STORAGE),
    resizable: false,
    width: 88,
    Cell: (rowData) => {
      return <TextStyle alias="Base">--</TextStyle>;
    },
  },
  {
    headerClassName: "table-align-left",
    className: "td-align-left ",
    Header: renderBoldText(CONSTANTS.COLOUR),
    resizable: false,
    width: 160,
    Cell: (rowData) => {
      return (
        <TextStyle alias="Base">
          {changeColorCase(rowData?.original?.color)}
        </TextStyle>
      );
    },
  },

  {
    headerClassName: "table-align-left",
    className: "td-align-left ",
    Header: renderBoldText(CONSTANTS.AVAILABILITY),
    resizable: false,
    width: 185,
    Cell: (rowData) => {
      return <StockStatus product={rowData?.original} showMessage={true} />;
    },
  },
  {
    headerClassName: "table-align-left",
    className: "td-align-right",
    Header: renderBoldText(CONSTANTS.UNIT_CHARGE),
    resizable: false,
    width: 260,
    Cell: (rowData) => {
      return (
        <div className="outright-charge">
          <TextStyle alias="Label">
            {prefixWithDollarSign(rowData?.original?.price)}
          </TextStyle>
          <TextStyle alias="FinePrint">
            {rowData?.original?.minMonthlyCharge
              ? `${SELECT_DEVICES.DEVICE_PAYMENT_MSG1} ${prefixWithDollarSign(
                  rowData?.original?.minMonthlyCharge
                )} ${SELECT_DEVICES.DEVICE_PAYMENT_MSG2}`
              : `${SELECT_DEVICES.OUTRIGHT}`}
          </TextStyle>
        </div>
      );
    },
  },
];

const TableView = ({ devices, toggleButton }) => {
  const paginationDetails = useAppSelector(
    (state) => state.selectDevices.selectDevicesPagination
  );

  const dispatch = useAppDispatch();

  const getTableDevicesCount = () => {
    return paginationDetails.lastIndex - paginationDetails.startIndex;
  };

  useEffect(() => {
    generateTableData(
      devices,
      paginationDetails.pageSize,
      dispatch,
      setSelectDevicesPagination
    )(1, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices]);

  return (
    <>
      <Spacing bottom="spacing5x" className={["toggle-button-section"]}>
        <TextStyle alias="HeadingD">
          {SELECT_DEVICES.ITEM_COUNT_DISPLAY(
            getTableDevicesCount(),
            devices.length
          )}
        </TextStyle>
        {toggleButton}
      </Spacing>
      <div className="select-devices-table">
        <AdvanceTableV3
          columns={columns}
          resizable={true}
          data={paginationDetails.data}
          totalRecordLength={paginationDetails.totalRecordLength}
          pages={paginationDetails.pages}
          page={paginationDetails.page}
          pageSize={paginationDetails.pageSize}
          defaultPageSize={paginationDetails.pageSize}
          className="td-align-left"
          pageHeaderClassName={"header"}
          isStickyHeader={true}
          sendApi={generateTableData(
            devices,
            paginationDetails.pageSize,
            dispatch,
            setSelectDevicesPagination
          )}
          action={{
            sticky: true,
            column: {
              headerClassName: "table-align-left tl-tableV3-last-sticky",
              className: "td-align-left",
              Header: renderBoldText(CONSTANTS.QUANTITY),
              resizable: false,
              width: 178,
              style: { height: "84px" },
              Cell: (rowData) => {
                return <QuantityButton device={rowData?.original} />;
              },
            },
          }}
        />
      </div>
    </>
  );
};
export default TableView;
