import "./style.scss";
import { useAppSelector } from "@state/hooks";
import { Spinner } from "telstra-ui/components/spinner/spinner";

export default function Loading() {
  return (
    <div className="loading-screen" data-testid="loading">
      <Spinner size="tl-spinner-large" />
    </div>
  );
}

export function GlobalSpinner() {
  const { loading } = useAppSelector((store) => store.commonStore);
  return loading ? (
    <div className="global-spinner" data-testid="global-spinner">
      <div className="background" />
      <div className="spinner">
        <Spinner size="tl-spinner-large" />
      </div>
    </div>
  ) : null;
}
