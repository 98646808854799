import "./style.scss";
import { ToolTip } from "telstra-ui/components/tool-tip/Tooltip";
import { IPlan } from "@state/types";
import { SIM_CONFIG_TABLE } from "@pages/new-plan-order/constant";
import { TextStyle } from "@able/react";

const generateList = (selections) => {
  return (
    <TextStyle element="ul">
      {selections.map((item) => (
        <div key={item.name}>
          <li>{item.value ? `${item.name} - ${item.value}` : item.name}</li>
        </div>
      ))}
    </TextStyle>
  );
};

interface IProps {
  plan: IPlan;
  field: "barrings" | "addOns";
}

export default function Selections(props: IProps) {
  const { plan, field } = props;
  const selectionsObject = plan[field] || {};

  const selections = Object.values(selectionsObject);
  if (selections.length === 0) {
    return <>- -</>;
  } else if (selections.length < 3) {
    return generateList(selections);
  } else {
    return (
      <div>
        <TextStyle element="ul">
          <div key={selections[0].name}>
            <li>
              {selections[0].value
                ? `${selections[0].name} - ${selections[0].value}`
                : selections[0].name}
            </li>
          </div>
        </TextStyle>
        <ToolTip
          title={
            field === "barrings"
              ? SIM_CONFIG_TABLE.BARRING
              : SIM_CONFIG_TABLE.ADD_ONS
          }
          description={generateList(selections.slice(1))}
        >
          <TextStyle className="more-selections-tooltip">
            {`+${selections.length - 1} more`}
          </TextStyle>
        </ToolTip>
      </div>
    );
  }
}
