import "./style.scss";
import { Spacing, TextStyle, Divider } from "@able/react";
import { useLocation } from "react-router-dom";
import { CHARGE_SUMMARY, PATHS } from "@utils/common-static-data";
import { useAppSelector } from "@state/hooks";
import { prefixWithDollarSign } from "@utils/helper";

const ChargeSummary = () => {
  const path = useLocation().pathname;
  const patchCartResponse = useAppSelector(
    (state) => state.cart.patchCartResponse
  );

  const customError = useAppSelector((state) => state.error.isError);

  return (
    (path.includes(PATHS.BARRING_ADDONS) ||
      path.includes(PATHS.CONFIGURE_SIM) ||
      path.includes(PATHS.CART) ||
      path.includes(PATHS.CHECKOUT) ||
      path.includes(PATHS.CONFIGURE_DEVICES)) &&
    !customError && (
      <>
        <Divider />
        <Spacing top="spacing2x" bottom="spacing2x">
          <div className="chargeSummary">
            <div className="summary">
              <Spacing left="spacing3x">
                <TextStyle alias="HeadingD">
                  {CHARGE_SUMMARY.CHARGE_SUMMARY_TITLE}
                </TextStyle>
              </Spacing>
            </div>
            <div className="recurring-charge" data-testid="summaryPrice">
              <TextStyle alias="Base">
                {CHARGE_SUMMARY.RECURING_CHARGE_TITLE}
              </TextStyle>
              <Spacing top="spacing1x" />
              <TextStyle alias="HeadingD">
                {prefixWithDollarSign(
                  patchCartResponse?.cartTotalPrice?.recurringPrice
                )}
              </TextStyle>
            </div>
            <div className="on-off-charge">
              <TextStyle alias="Base">
                {CHARGE_SUMMARY.ONE_OFF_CHARGE_TITLE}
              </TextStyle>
              <Spacing top="spacing1x" />
              <TextStyle alias="HeadingD">
                {prefixWithDollarSign(
                  patchCartResponse?.cartTotalPrice?.onceOffPrice
                )}
              </TextStyle>
            </div>
            <div className="totalCharge">
              <Spacing left="spacing1x" right="spacing4x">
                <TextStyle alias="Label">
                  {CHARGE_SUMMARY.TOTAL_CHARGE_TITLE}
                </TextStyle>
                <Spacing top="spacing1x" />
                <TextStyle alias="HeadingD">
                  {prefixWithDollarSign(
                    patchCartResponse?.cartTotalPrice?.totalCartPrice
                  )}
                </TextStyle>
              </Spacing>
            </div>
          </div>
        </Spacing>
        <Divider />
      </>
    )
  );
};

export default ChargeSummary;
