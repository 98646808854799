import React, { useRef } from "react";
import { ModalDialogCritical, useToggle } from "@able/react";
import { useAppDispatch } from "@state/hooks";
import { setEmptyCartError } from "@state/error";
import { ableDevelopmentUrl } from "@utils/helper";

const EmptyCart = (props) => {
  const dispatch = useAppDispatch();
  const [modalIsOpen, toggleModal] = useToggle(props.modalIsOpen);
  const focusOnCloseRef = useRef(null);

  const buttonEvents = {
    onClick: () => dispatch(setEmptyCartError(false)),
  };
  return (
    <ModalDialogCritical
      isShowing={modalIsOpen}
      setHideDialog={toggleModal}
      OKButtonEvents={buttonEvents}
      title={props.title}
      description={props.description}
      focusOnCloseRef={focusOnCloseRef}
      developmentUrl={ableDevelopmentUrl}
    />
  );
};

export default EmptyCart;
