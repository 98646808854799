import {
  CartItem,
  Characteristic,
  CreateCartParams,
  NormalizedDevice,
  DeviceProduct,
  IOffer,
  PatchCart,
  NormalizedPlanQBS,
} from "@state/types";
import { CONSTANTS } from "@services/constant";
import { CART, RELATED_PARTIES } from "@utils/common-static-data";
import { RootState } from "@state/store";

const { ATTRIBUTES } = CART;
const { REMOVE, UPDATE, ADD } = CONSTANTS.CART_ACTIONS;

export function createCartBody(params: CreateCartParams) {
  const cartAttributes = [
    {
      name: ATTRIBUTES.TELSTRA_INTERNAL_REFERENCE,
      value: params.internalReferenceNumber,
    },
    {
      name: ATTRIBUTES.CUSTOMER,
      value: params.cidn,
    },
    {
      name: ATTRIBUTES.REQUESTER,
      value: params.requester,
    },
    {
      name: ATTRIBUTES.ORDER_REFERENCE,
      value: params.orderReference,
    },
  ];

  return { cartAttributes };
}

function generateSelectPlansPatchCartItemBody(
  offer: IOffer,
  index: number,
  billingAccount: string
): CartItem {
  const quantity = offer.quantity;
  const itemAttributes = [];
  for (let i = 1; i <= quantity; i++) {
    itemAttributes.push({
      name: ATTRIBUTES.BILLING_ACCOUNT_NUMBER,
      value: billingAccount,
      valueType: "string",
      "@type": "member",
      setId: i.toString(),
      action: CONSTANTS.CART_ACTIONS.ADD,
    });
  }
  return {
    id: null,
    consumerItemId: `${index + 2}`,
    action: CONSTANTS.CART_ACTIONS.ADD,
    quantity: offer.quantity,
    itemRelationships: [
      {
        id: "1",
        relationshipType: "ReliesOn",
      },
    ],
    itemAttributes,
    productOffering: {
      id: offer.offerId,
      name: offer.offerName,
      characteristics: offer.characteristics,
      marketSegments: offer.marketSegment,
    },
  };
}

export function generateSelectPlansPatchCartBody(params: any): PatchCart {
  const cartItems: CartItem[] = [
    {
      id: null,
      consumerItemId: "1",
      action: CONSTANTS.CART_ACTIONS.ADD,
      quantity: 1,
      itemRelationships: [],
      itemAttributes: [
        {
          name: ATTRIBUTES.BILLING_ACCOUNT_NUMBER,
          value: params.billingAccount,
          valueType: "string",
          "@type": "member",
        },
      ],
      productOffering: {
        id: "DMCAT_Offer_001233",
        name: "Adaptive Mobility",
        characteristics: [],
        marketSegments: [
          {
            id: "B2B",
            name: "B2B",
          },
        ],
      },
    },
  ];

  params.selectedOffers.forEach((offer, index) =>
    cartItems.push(
      generateSelectPlansPatchCartItemBody(offer, index, params.billingAccount)
    )
  );

  return {
    cartId: params.cartId,
    cartItems,
  };
}

export function generatePatchCartCancelDraftOrder(reason) {
  return {
    status: "Cancelled",
    cartAttributes: [
      {
        name: "cancellationReason",
        value: reason,
      },
    ],
  };
}

export function generatePatchCartOnCheckout(params) {
  const { orderReferenceNumber, internalReferenceNumber, requesterId } =
    params.previousPatchCartAttributes;
  const { cartItems } = params.previousPatchCartResponse;
  const relatedParties = [];
  const reqCartAttributes = [];
  if (
    params.selectedRelatedParties?.id &&
    requesterId !== params.selectedRelatedParties?.id
  ) {
    relatedParties.push({
      "@referredType": RELATED_PARTIES.REFERRED_TYPE,
      id: params.selectedRelatedParties?.id,
      idType: RELATED_PARTIES.REFERRED_TYPE,
      name: params.selectedRelatedParties?.name,
      role: RELATED_PARTIES.ROLE,
    });
  }

  if (internalReferenceNumber !== params.internalReferenceNumber) {
    reqCartAttributes.push({
      name: CART.ATTRIBUTES.TELSTRA_INTERNAL_REFERENCE,
      value: params.internalReferenceNumber,
      valueType: "text",
    });
  }

  if (orderReferenceNumber !== params.orderReference) {
    reqCartAttributes.push({
      name: CART.ATTRIBUTES.ORDER_REFERENCE,
      value: params.orderReference,
      valueType: "text",
    });
  }

  const reqCartItems = [];
  for (const cartItem of cartItems) {
    const newCartItem = { ...cartItem };
    const { productOffering, itemAttributes } = cartItem;
    const { id: productOfferingID } = productOffering;
    const {
      deliveryAddress: { id: deliveryAddressID },
      contactDetails: { id: contactDetailsID },
    } = params;
    if (itemAttributes.length) {
      const newAttributes = itemAttributes.map((item) => {
        const newItem = { ...item };
        newItem.action = CONSTANTS.CART_ACTIONS.NO_CHANGE;
        return newItem;
      });
      newCartItem.itemAttributes = newAttributes;
    }
    if (
      deliveryAddressID &&
      productOfferingID &&
      (productOfferingID === CONSTANTS.PRODUCT_IDS.AM_PLAN ||
        productOfferingID === CONSTANTS.PRODUCT_IDS.DEVICES ||
        productOfferingID === CONSTANTS.PRODUCT_IDS.ACCESSORIES)
    ) {
      reqCartItems.push({
        ...newCartItem,
        action: CONSTANTS.CART_ACTIONS.UPDATE,
        itemPlace: [
          {
            type: CART.ITEM_PLACE_ATTRIBUTES.TYPE,
            id: deliveryAddressID,
            idType: CART.ITEM_PLACE_ATTRIBUTES.ID_TYPE,
            itemPlaceAttributes: [
              {
                name: CART.ITEM_PLACE_ATTRIBUTES.CONTACT_ID,
                value: contactDetailsID,
                valueType: "string",
              },
            ],
          },
        ],
      });
    } else {
      reqCartItems.push({
        ...newCartItem,
        action: CONSTANTS.CART_ACTIONS.NO_CHANGE,
      });
    }
  }
  return {
    cartId: params.previousPatchCartResponse.cartId,
    ...(reqCartAttributes.length > 0 && { cartAttributes: reqCartAttributes }),
    cartItems: reqCartItems,
    ...(relatedParties.length > 0 && { relatedParties }),
  };
}

export function generatePatchCartOnSimConfiguration(params) {
  const cartItems = [];
  const initialPlans = params.initialPlans;

  for (const plan of params.plansInfo) {
    const initialPlan = initialPlans[plan.id];
    const characteristics = [];
    const itemAttributes = [...initialPlan.itemAttributes];
    Object.keys(plan.setIds).forEach((setId) => {
      // phone number
      if (
        plan.setIds[setId].phoneNumber !== initialPlan.setIds[setId].phoneNumber
      ) {
        characteristics.push({
          name: "CustomerFacingServiceId",
          value: plan.setIds[setId].phoneNumber,
          setId,
          action: UPDATE,
        });
      }

      // SIM type
      if (plan.setIds[setId].simType !== initialPlan.setIds[setId].simType) {
        const item = {
          name: "SIMType",
          value: plan.setIds[setId].simType,
          setId,
        };
        if (initialPlan.setIds[setId].simType?.length > 0) {
          characteristics.push({ ...item, action: UPDATE });
        } else {
          characteristics.push({ ...item, action: ADD });
        }
      }

      // SIM serial number
      if (
        plan.setIds[setId].simSerialNumber !==
        initialPlan.setIds[setId].simSerialNumber
      ) {
        const previousSimSerialNumber =
          initialPlan.setIds[setId].simSerialNumber;
        const item = {
          name: "SIMSerialNumber",
          value: plan.setIds[setId].simSerialNumber,
          setId,
        };
        if (previousSimSerialNumber?.length > 0) {
          characteristics.push({ ...item, action: UPDATE });
        } else {
          characteristics.push({ ...item, action: ADD });
        }
      }

      // username
      if (plan.setIds[setId].username !== initialPlan.setIds[setId].username) {
        const item = {
          name: "Alias",
          valueType: "string",
          value: plan.setIds[setId].username,
          "@type": "member",
          setId,
        };
        if (initialPlan.setIds[setId].username?.length === 0) {
          itemAttributes.push({ ...item, action: ADD });
        } else if (
          initialPlan.setIds[setId].username?.length > 0 &&
          plan.setIds[setId].username?.length === 0
        ) {
          itemAttributes.push({ ...item, action: REMOVE });
        } else {
          itemAttributes.push({ ...item, action: UPDATE });
        }
      }
    });

    cartItems.push({
      id: plan.id,
      action: UPDATE,
      quantity: plan.quantity,
      itemAttributes,
      itemRelationships: initialPlan.itemRelationships,
      productOffering: {
        id: initialPlan.productOfferingId,
        name: initialPlan.planName,
        characteristics,
        marketSegments: plan.marketSegments,
      },
    });
  }
  return {
    cartId: params.cartId,
    cartItems,
  };
}

const patchCartRequestSkeleton = (plan) => {
  return {
    id: plan.id,
    action: plan.action,
    quantity: plan.quantity,
    itemRelationships: plan.itemRelationships,
    productOffering: plan.productOffering,
  };
};
export function generatePatchCartRequest(params) {
  const deltaPlans = params.deltaPlans;
  const cartItems = [];
  const cartItemIds = Object.keys(deltaPlans);
  cartItemIds.forEach((id) =>
    cartItems.push(patchCartRequestSkeleton(deltaPlans[id]))
  );
  return {
    cartId: params.cartId,
    cartItems,
  };
}

export function generateSelectDevicesPatchCartBody(
  params: RootState
): PatchCart {
  const { billingAccount, cartId } = params.cart;
  const { selectedDevices } = params.selectDevices;

  function generateCartItemBody(device: DeviceProduct, index): CartItem {
    return {
      id: null,
      consumerItemId: `${index + 2}`,
      action: CONSTANTS.CART_ACTIONS.ADD,
      quantity: device?.quantity || 1,
      itemRelationships: device
        ? [{ id: "1", relationshipType: "ReliesOn" }]
        : [],
      itemAttributes: [
        {
          name: ATTRIBUTES.BILLING_ACCOUNT_NUMBER,
          value: billingAccount,
          valueType: "string",
          "@type": "member",
        },
      ],
      productOffering: {
        id: device?.productId || "DMCAT_Offer_001231",
        name: device?.name || "Standalone Devices and Accessories",
        characteristics: device?.characteristics || [],
        marketSegments: device?.marketSegment || [{ id: "B2B", name: "B2B" }],
      },
    };
  }

  return {
    cartId,
    cartItems: [
      generateCartItemBody(null, -1),
      ...selectedDevices.map((device, index) =>
        generateCartItemBody(device, index)
      ),
    ],
  };
}

type Action = "update" | "remove";

export function createDeltaItem(
  item: NormalizedDevice | NormalizedPlanQBS,
  action: Action,
  updatedCharacteristics?: Characteristic[]
): CartItem {
  return {
    id: item.id,
    action: action === "update" ? UPDATE : REMOVE,
    quantity: item.quantity,
    itemRelationships: item.itemRelationships,
    productOffering: {
      id: item.productOfferingId,
      marketSegments: item.marketSegments,
      characteristics: updatedCharacteristics,
    },
  };
}
