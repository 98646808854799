/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import { useEffect, useState } from "react";
import { TableAccordion } from "telstra-ui/components/table-accordion/AccordionTable";
import { ableDevelopmentUrl, renderBoldText, renderText } from "@utils/helper";
import { SvgIcon } from "telstra-ui/components/svg/Icon";
import Selections from "@pages/new-plan-order/steps/components/selections";
import {
  ModalDialogConfirmationDestructive,
  TextStyle,
  useToggle,
} from "@able/react";
import { CART_PLANS_TABLE, REMOVE_DIALOG } from "../constant";
import ActionColumn from "./action-column";
import { useLocation } from "react-router-dom";
import { PATHS } from "@utils/common-static-data";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { deletePlansUsingSetIdsQBS, setCartFlow } from "@state/cart-slice";
import { useGetConfigsQuery } from "@services/config";

const ReviewCartPlansTable = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [cartPlans, setCartPlans] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [modalIsOpen, toggleModal] = useToggle();
  const [setIDsOfplansToBeRemoved, setSetIDsOfplansToBeRemoved] = useState(
    new Set()
  );
  const location = useLocation();
  const path = location.pathname;
  const isCheckoutPage = path.includes(PATHS.CHECKOUT);
  const [groupLength, setGroupLength] = useState(0);
  const { normalizedPlansQBS: plansInfo } = useAppSelector(
    (state) => state.cart
  );
  const dispatch = useAppDispatch();
  const { data: configs = { SIM_TYPE_OPTIONS: [] } } = useGetConfigsQuery();

  const onExpandedChange = (expandedObj) => {
    setExpanded(expandedObj);
  };

  const headerHandlerClick = (columnName) => {
    if (columnName === "id") {
      const expandRow = pageSize;
      const obj = {};
      for (let i = 0; i < expandRow; i++) {
        obj[i] = {};
      }
      Object.keys(expanded).length === 0 && onExpandedChange(obj);
    }
  };
  const updateGroupLength = (plansTableView) => {
    const allCategories = plansTableView.map((plan) => plan.planId);
    const uniqueCategories = new Set(allCategories);
    const categoriesLength = uniqueCategories.size;
    setGroupLength(categoriesLength);
  };

  useEffect(() => {
    const plansTableView = [];
    plansInfo.forEach((plan) => {
      const { planName, id, setIds, addOns } = plan;
      Object.keys(setIds).forEach((setId) => {
        plansTableView.push({
          planName,
          addOns,
          planId: id,
          setId,
          ...setIds[setId],
        });
      });
    });

    setCartPlans(plansTableView);
    updateGroupLength(plansTableView);
    if (!isCheckoutPage) headerHandlerClick("id");
  }, [plansInfo]);

  const renderTitle = (accessor: string) => {
    const onClick = (e) => {
      e.stopPropagation();
      headerHandlerClick(accessor);
    };
    const iconName =
      Object.keys(expanded).length === 0
        ? "icon-system-chevron-down"
        : "icon-system-chevron-up";
    return (
      <div className="tl-table-header-icon-wrapper" onClick={onClick}>
        <SvgIcon name={iconName} />
      </div>
    );
  };

  const onPageChange = (tabName, pageIndex) => {
    setPage(pageIndex);
  };
  const onPageSizeChange = (tabName, currentPageSize) => {
    setPageSize(currentPageSize);
  };

  const getSIMType = (SIMType) => {
    const simType = configs?.SIM_TYPE_OPTIONS?.filter((options) => {
      return options?.value === SIMType;
    });
    return simType.length && renderText(simType[0]?.label);
  };

  const columnData = [
    {
      headerClassName: "table-align-center",
      className: "td-align-center border-right no-border-bottom",
      style: { paddingLeft: "8px" },
      Header: renderTitle(CART_PLANS_TABLE.ID),
      accessor: "id",
      sortable: true,
      resizable: false,
      width: 56,
      Cell: () => null,
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right no-border-bottom",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(CART_PLANS_TABLE.QUANTITY),
      sortable: true,
      resizable: false,
      width: 86,
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(CART_PLANS_TABLE.PLAN),
      accessor: "planName",
      sortable: true,
      resizable: false,
      width: 300,
      Cell: (cellInfo) => renderText(cellInfo.value),
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(CART_PLANS_TABLE.BARRING),
      sortable: true,
      resizable: false,
      width: 300,
      Cell: (cellInfo) => (
        <Selections plan={cellInfo.original} field="barrings" />
      ),
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(CART_PLANS_TABLE.ADD_ONS),
      sortable: true,
      resizable: false,
      width: 264,
      Cell: (cellInfo) => (
        <Selections plan={cellInfo.original} field="addOns" />
      ),
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(CART_PLANS_TABLE.PHONE_NUMBER),
      accessor: "phoneNumber",
      resizable: false,
      sortable: true,
      width: 160,
      Cell: (cellInfo) => renderText(cellInfo.value),
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(CART_PLANS_TABLE.SIM_TYPE),
      accessor: "simType",
      sortable: true,
      resizable: false,
      width: 159,
      Cell: (cellInfo) =>
        cellInfo.value && configs && getSIMType(cellInfo.value),
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(CART_PLANS_TABLE.SIM_SERIAL_NUMBER),
      accessor: "simSerialNumber",
      sortable: true,
      resizable: false,
      width: 190,
      Cell: (cellInfo) => renderText(cellInfo?.value),
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(CART_PLANS_TABLE.USERNAME),
      accessor: "username",
      sortable: true,
      resizable: false,
      width: 135,
      Cell: (cellInfo) => renderText(cellInfo?.value),
    },
    {
      Header: renderBoldText(CART_PLANS_TABLE.GROUP_BY_CATEGORY),
      accessor: "planId",
      sortable: false,
      width: 130,
      expander: true,
      pivot: true,
      Pivot: (cellInfo) => {
        const subrowsLength = cellInfo?.subRows?.length;
        const className = ["rt-flex", "rt-flex-subheader"].join(" ");
        const planName = cellInfo?.subRows[0]?.planName;

        return (
          <div
            className={`tl-row-expander ${
              cellInfo?.isExpanded ? "tl-row-expanded" : ""
            }`}
          >
            <div className={className}>
              <div className="tl-border-override icon-container">
                <div className="tl-accordion-icon-wrapper">
                  {cellInfo?.isExpanded ? (
                    <SvgIcon name="icon-system-chevron-up" />
                  ) : (
                    <SvgIcon name="icon-system-chevron-down" />
                  )}
                </div>
              </div>

              <div
                className="tl-border-override"
                style={{ width: 86 }}
                onClick={(e) => e.stopPropagation()}
              >
                <TextStyle alias="Label" className="tl-accordion-icon-wrapper">
                  {subrowsLength}
                </TextStyle>
              </div>
              <div className="pivot-text-holder">
                <TextStyle alias="Label">{planName}</TextStyle>
              </div>
            </div>
            {!isCheckoutPage && (
              <div
                className="rt-td td-align-left tl-cell-action"
                style={{ width: 95 }}
              >
                <ActionColumn
                  cellInfo={cellInfo}
                  setSetIDsOfplansToBeRemoved={setSetIDsOfplansToBeRemoved}
                  toggleModal={toggleModal}
                />
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="plansTable">
      <TableAccordion
        data={cartPlans}
        columns={columnData}
        showPagination={groupLength > 10}
        resizable={true}
        page={page}
        defaultPageSize={pageSize}
        totalRecordLength={cartPlans.length}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        isStickyHeader={true}
        sortable={false}
        pivotBy={["planId"]}
        expanded={expanded}
        onExpandedChange={onExpandedChange}
        freezWhenExpanded={false}
        defaultSortMethod={() => ({})}
        {...(!isCheckoutPage && {
          action: {
            sticky: true,
            column: {
              headerClassName:
                "table-align-left tl-tableV3-last-sticky sticky-column",
              className: "td-align-left",
              Header: renderBoldText("Remove"),
              width: 95,
              Cell: (cellInfo) => (
                <ActionColumn
                  cellInfo={cellInfo}
                  setSetIDsOfplansToBeRemoved={setSetIDsOfplansToBeRemoved}
                  toggleModal={toggleModal}
                />
              ),
            },
          },
        })}
      />
      {!isCheckoutPage && (
        <ModalDialogConfirmationDestructive
          title={
            setIDsOfplansToBeRemoved.size > 1
              ? REMOVE_DIALOG.MULTIPLE_ITEM_TITLE
              : REMOVE_DIALOG.SINGLE_ITEM_TITLE
          }
          description={
            setIDsOfplansToBeRemoved.size > 1
              ? REMOVE_DIALOG.MULTIPLE_ITEM_DESCRIPTION
              : REMOVE_DIALOG.SINGLE_ITEM_DESCRIPTION
          }
          stackButtonOnVXS={false}
          confirmButtonLabel={REMOVE_DIALOG.CONFIRM_BUTTON_LABEL}
          confirmButtonEvents={{
            onClick: () => {
              const { planId } = cartPlans.find((plan) =>
                setIDsOfplansToBeRemoved.has(plan.setId)
              );
              const planIndex = plansInfo.findIndex(
                (plan) => plan.id === planId
              );
              const setIdsToBeRemoved = Array.from(setIDsOfplansToBeRemoved);
              dispatch(
                deletePlansUsingSetIdsQBS({ planIndex, setIdsToBeRemoved })
              );
              dispatch(setCartFlow("plans"));
            },
          }}
          cancelButtonLabel={REMOVE_DIALOG.CANCEL_BUTTON_LABEL}
          cancelButtonEvents={{
            onClick: () => null,
          }}
          isShowing={modalIsOpen}
          setHideDialog={toggleModal}
          developmentUrl={ableDevelopmentUrl}
        />
      )}
    </div>
  );
};

export default ReviewCartPlansTable;
