import { DeviceProduct } from "@state/types";
import GridItem from "./grid-item";
import { ActionButton, Grid, Spacing, TextStyle } from "@able/react";
import { ReactElement, useState } from "react";
import { ableDevelopmentUrl } from "@utils/helper";
import { SELECT_DEVICES } from "@pages/devices-and-accessories/constant";

type Props = {
  devices: DeviceProduct[];
  toggleButton: ReactElement;
};

export function GridView({ devices, toggleButton }: Props) {
  const [itemsLoaded, setItemsLoaded] = useState(
    SELECT_DEVICES.GRID_INITIAL_COUNT
  );
  const itemsToLoad = SELECT_DEVICES.GRID_INITIAL_COUNT;
  const onLoadMore = {
    onClick: () => {
      if (itemsLoaded < devices.length) {
        setItemsLoaded(itemsLoaded + itemsToLoad);
      }
    },
  };

  return (
    <Grid>
      <Spacing bottom="spacing5x" className={["toggle-button-section"]}>
        <TextStyle alias="HeadingD">
          {SELECT_DEVICES.ITEM_COUNT_DISPLAY(
            devices.length < itemsLoaded ? devices.length : itemsLoaded,
            devices.length
          )}
        </TextStyle>
        {toggleButton}
      </Spacing>
      {devices.map((device, index) => {
        if (index < itemsLoaded) {
          return <GridItem key={device.id} device={device} />;
        }
        return null;
      })}
      {itemsLoaded < devices.length && (
        <div className="load-more-view">
          <ActionButton
            developmentUrl={ableDevelopmentUrl}
            element="button"
            label={SELECT_DEVICES.LOAD_MORE}
            variant="LowEmphasis"
            events={onLoadMore}
          />
        </div>
      )}
    </Grid>
  );
}
