import "./style.scss";
import { FOOTER } from "../constant";
import { useParams } from "react-router-dom";
import { ActionButton, Spacing } from "@able/react";
import {
  ableDevelopmentUrl,
  cartPagePatchCartHandler,
  useCustomNavigate,
} from "@utils/helper";
import { PATHS } from "@utils/common-static-data";
import { useAppSelector, useAppDispatch } from "@state/hooks";
import { useLazyPatchCartQuery } from "@services/cart";
import { setCart } from "@state/cart-slice";
import { setEmptyCartError } from "@state/error";

export default function CartFooter() {
  const navigate = useCustomNavigate();
  const dispatch = useAppDispatch();
  const { cartId } = useParams();
  const deltaPlans = useAppSelector((state) => state.cart.deltaPlans);
  const [patchCart] = useLazyPatchCartQuery();
  const { normalizedPlansQBS, initialPlans } = useAppSelector(
    (state) => state.cart
  );
  return (
    <Spacing
      top="spacing3x"
      bottom="spacing3x"
      className={["review-cart-footer"]}
    >
      <ActionButton
        developmentUrl={ableDevelopmentUrl}
        element="button"
        icon="ChevronLeft"
        label={FOOTER.BACK_BUTTON}
        variant="LowEmphasis"
        events={{
          onClick: () => {
            navigate(PATHS.CONFIGURE_SIM, cartId);
          },
        }}
      />
      <div className="button-row">
        <ActionButton
          element="button"
          label={FOOTER.ADD_PLANS_BUTTON}
          variant="MediumEmphasis"
        />
        <ActionButton
          element="button"
          label={FOOTER.ADD_DEVICES_BUTTON}
          variant="MediumEmphasis"
          events={{
            onClick: async () => {
              localStorage.setItem("select-devices-page-from", PATHS.CART);
              navigate(PATHS.SELECT_DEVICES, cartId);
            },
          }}
        />
        <ActionButton
          element="button"
          label={FOOTER.ADD_BLANK_SIM_BUTTON}
          variant="MediumEmphasis"
        />
        <ActionButton
          element="button"
          label={FOOTER.CHECKOUT_BUTTON}
          variant="HighEmphasis"
          events={{
            onClick: () => {
              if (normalizedPlansQBS.length > 0) {
                cartPagePatchCartHandler({
                  cartId,
                  deltaPlans,
                  normalizedPlansQBS,
                  initialPlans,
                  patchCart,
                  navigate,
                  dispatch,
                  setCart,
                  product: "plan",
                });
              } else {
                dispatch(setEmptyCartError(true));
              }
            },
          }}
        />
      </div>
    </Spacing>
  );
}
