import "./style.scss";
import {
  ActionButton,
  Column,
  Grid,
  Spacing,
  Surface,
  TextStyle,
} from "@able/react";
import { SELECT_DEVICES } from "@pages/devices-and-accessories/constant";
import { useAppSelector } from "@state/hooks";

const ViewItemsFloater = () => {
  const selectedDevices = useAppSelector(
    (state) => state.selectDevices.selectedDevices
  );

  return (
    <div className="view-items">
      <Grid>
        <Column>
          <Surface variant="SurfaceMid" background="materialBasePrimary">
            <Spacing
              top="spacing2x"
              right="spacing3x"
              bottom="spacing2x"
              left="spacing3x"
            >
              <Grid className="view-items-grid">
                <Column cols={6} bsm={6} bmd={6} blg={6}>
                  <TextStyle alias="HeadingD">{`${selectedDevices.length} ${SELECT_DEVICES.ITEMS_ADDED}`}</TextStyle>
                </Column>
                <Column
                  cols={6}
                  bsm={6}
                  bmd={6}
                  blg={6}
                  className="btn-wrapper"
                >
                  <ActionButton
                    label={SELECT_DEVICES.VIEW_ITEMS_BUTTON}
                    element="button"
                    variant="MediumEmphasis"
                    events={{
                      onClick: () => {
                        document
                          .querySelector(".selected-devices-table")
                          .scrollIntoView({ behavior: "smooth" });
                      },
                    }}
                  />
                </Column>
              </Grid>
            </Spacing>
          </Surface>
        </Column>
      </Grid>
    </div>
  );
};
export default ViewItemsFloater;
