import "./style.scss";
import { Lozenge, TextStyle } from "@able/react";
import { STOCK_STATUS } from "@pages/devices-and-accessories/constant";

const StockStatus = ({ product, showMessage }) => {
  const { stockStatus, restockMessage, stockAvailability } = product;
  let availabilityMsg = stockAvailability;
  if (stockStatus === "OUT_OF_STOCK") {
    availabilityMsg = restockMessage;
  }

  const getStatus = () => {
    let variant;
    switch (stockStatus) {
      case "IN_STOCK":
        variant = "PositiveEmphasis";
        break;
      case "LIMITED_STOCK":
        variant = "Negative";
        break;
      case "OUT_OF_STOCK":
        variant = "NegativeEmphasis";
        break;
      default:
        variant = "";
    }

    return (
      variant && (
        <Lozenge
          label={STOCK_STATUS[stockStatus]}
          variant={variant}
          className="statusDisplay"
        />
      )
    );
  };

  return (
    <div>
      {getStatus()}
      {showMessage && availabilityMsg && (
        <TextStyle alias="Base">{availabilityMsg}</TextStyle>
      )}
    </div>
  );
};
export default StockStatus;
