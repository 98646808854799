import { Checkbox } from "telstra-ui/components/checkbox/Checkbox";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { toggleDeviceSelection } from "@state/select-devices-slice";
import { TextStyle } from "@able/react";
import { CONFIGURE_DEVICES_AND_ACCESSORIES } from "@pages/devices-and-accessories/constant";

export default function DevicesCheckbox(props) {
  const { device } = props;

  const dispatch = useAppDispatch();
  const configuredDeviceSelected = useAppSelector(
    (state) => state.selectDevices.configuredDeviceSelected
  );

  return (
    <Checkbox
      id={device.id}
      label={
        <div>
          <TextStyle alias="Tag">{device.category}</TextStyle>
          <TextStyle alias="Label">{device.name}</TextStyle>
          <TextStyle alias="Tag">
            {device.technologyType} | {device.color} | --
          </TextStyle>
          <TextStyle alias="Tag">
            {CONFIGURE_DEVICES_AND_ACCESSORIES.SKU} {device.sku}
          </TextStyle>
        </div>
      }
      checked={configuredDeviceSelected.includes(device.id)}
      onChange={(e) => {
        dispatch(toggleDeviceSelection(e.target.id));
      }}
    />
  );
}
