/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import { NEW_SIM_CONFIG_TABLE } from "@pages/new-plan-order/constant";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { Checkbox } from "telstra-ui/components/checkbox/Checkbox";
import {
  ableDevelopmentUrl,
  generateTableData,
  renderBoldText,
} from "@utils/helper";
import { useEffect, useState } from "react";
import { SvgIcon } from "telstra-ui/components/svg/Icon";
import { TableAccordion } from "telstra-ui/components/table-accordion/AccordionTable";
import {
  TextStyle,
  Checkbox as AbleCheckbox,
  InlineLink,
  useToggle,
  Select,
  MessageSection,
  Spacing,
} from "@able/react";
import ViewDetailsModal from "./components/view-details-modal";
import AddUsername from "./components/username.tsx";
import AssignedPhoneNumber from "./components/assigned-phone-number";
import { updateQBSPlansArrUsingIndex } from "@state/cart-slice";
import { useGetConfigsQuery } from "@services/config";
import SIMSerialNumber from "./components/sim-serial-number";
import { useParams } from "react-router-dom";
import { useShouldEnableGetCartHook } from "@utils/cart";
import { useCommonGetCartQuery } from "@services/cart";
import { setPagination } from "@state/sim-configuration-slice";
import { toggleAll } from "@state/common-slice";

const NewSimConfigurationTable = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [simConfigPlans, setSimConfigPlans] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [modalIsOpen, toggleModal] = useToggle();
  const [selectedPlanDetails, setSelectedPlanDetails] = useState([]);
  const { cartId = "" } = useParams();
  const dispatch = useAppDispatch();
  const skipGetCart = useShouldEnableGetCartHook();
  useCommonGetCartQuery({ cartId }, { skip: !skipGetCart });
  const { selectedPlans } = useAppSelector((state) => state.commonStore);
  const plansInfo = useAppSelector((state) => state.cart.normalizedPlansQBS);
  const { isPlanFieldInvalid, paginationState } = useAppSelector(
    (state) => state.simConfiguration
  );

  const { data: configs = { SIM_TYPE_OPTIONS: [] } } = useGetConfigsQuery();

  useEffect(() => {
    headerHandlerClick("id");
  }, []);

  useEffect(() => {
    const plansTableView = [];
    plansInfo.forEach((plan) => {
      const { planName, id, setIds, addOns } = plan;
      Object.keys(setIds).forEach((key) => {
        plansTableView.push({
          planName,
          addOns,
          planId: id,
          ...setIds[key],
          setId: key,
        });
      });
    });
    setSimConfigPlans(plansTableView);
  }, [plansInfo]);

  useEffect(() => {
    plansInfo.length &&
      generateTableData(
        plansInfo,
        paginationState.pageSize,
        dispatch,
        setPagination
      )(paginationState.page, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plansInfo]);

  const onExpandedChange = (expandedObj) => {
    setExpanded(expandedObj);
  };
  const onPageChange = (tabName, pageIndex) => {
    setPage(pageIndex);
  };
  const onPageSizeChange = (tabName, currentPageSize) => {
    setPageSize(currentPageSize);
  };

  const headerHandlerClick = (columnName) => {
    if (columnName === "id") {
      const expandRow = pageSize;
      const obj = {};
      for (let i = 0; i < expandRow; i++) {
        obj[i] = {};
      }
      Object.keys(expanded).length === 0
        ? onExpandedChange(obj)
        : onExpandedChange({});
    }
  };
  const renderTitle = (accessor: string) => {
    const onClick = (e) => {
      e.stopPropagation();
      headerHandlerClick(accessor);
    };
    return (
      <div className="tl-table-header-icon-wrapper" onClick={onClick}>
        {renderBoldText(NEW_SIM_CONFIG_TABLE.TABLE_HEADERS.EXPAND)}
      </div>
    );
  };

  const onChangeSimType = (e, rowData) => {
    const planIndex = plansInfo.findIndex(
      (planInfo) => planInfo.id === rowData.original.planId
    );
    const payload = {
      planIndexes: [planIndex],
      setIds: [rowData.original.setId],
      fields: {
        simSerialNumber: "",
        invalid_SIM_number: false,
        invalid_UNMS_SIM_number: false,
        invalid_SIM_type: false,
        simType: e.target.value,
      },
    };
    dispatch(updateQBSPlansArrUsingIndex(payload));
  };

  const columnData = [
    {
      headerClassName: "table-align-center",
      className: "td-align-center border-right no-border-bottom",
      style: { paddingLeft: "8px" },
      Header: renderTitle(NEW_SIM_CONFIG_TABLE.TABLE_HEADERS.ID),
      accessor: "setId",
      sortable: true,
      resizable: false,
      width: 96,
      Cell: () => null,
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right",
      style: { paddingLeft: "8px" },
      Header: (
        <Checkbox
          id="toggle-all"
          checked={selectedPlans.length === simConfigPlans.length}
          label={
            <TextStyle alias="Label">
              {NEW_SIM_CONFIG_TABLE.TABLE_HEADERS.PLAN}
            </TextStyle>
          }
          className={"select-all"}
          onChange={() => {
            if (selectedPlans.length === simConfigPlans.length)
              dispatch(toggleAll([]));
            else {
              const allSetIds = simConfigPlans.map(
                (item) => `${item.setId}:${item.planId}`
              );
              dispatch(toggleAll(allSetIds));
            }
          }}
        />
      ),
      accessor: "planName",
      sortable: true,
      resizable: false,
      width: 400,
      Cell: (cellInfo) => (
        <AbleCheckbox
          id={cellInfo.row.setId}
          checked={selectedPlans.includes(
            `${cellInfo.row.setId}:${cellInfo.row.planId}`
          )}
          label={cellInfo.value}
          events={{
            onChange: () => {
              let customSelectedColumn = [...selectedPlans];
              if (
                customSelectedColumn.includes(
                  `${cellInfo.row.setId}:${cellInfo.row.planId}`
                )
              ) {
                customSelectedColumn = customSelectedColumn.filter(
                  (item) =>
                    item !== `${cellInfo.row.setId}:${cellInfo.row.planId}`
                );
              } else {
                customSelectedColumn = [
                  ...customSelectedColumn,
                  `${cellInfo.row.setId}:${cellInfo.row.planId}`,
                ];
              }
              dispatch(toggleAll(customSelectedColumn));
            },
          }}
        />
      ),
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(NEW_SIM_CONFIG_TABLE.TABLE_HEADERS.QUANTITY),
      sortable: true,
      resizable: false,
      width: 97,
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(
        NEW_SIM_CONFIG_TABLE.TABLE_HEADERS.ASSIGNED_PHONE_NUMBER
      ),
      accessor: "phoneNumber",
      resizable: false,
      sortable: true,
      width: 168,
      Cell: (cellInfo) => {
        return <AssignedPhoneNumber plan={cellInfo.original} />;
      },
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(NEW_SIM_CONFIG_TABLE.TABLE_HEADERS.SIM_TYPE),
      accessor: "simType",
      sortable: true,
      resizable: false,
      width: 210,
      Cell: (cellInfo) => {
        return (
          <Select
            id={`simType-${cellInfo?.original?.setId}`}
            key={`simType-${cellInfo?.original?.setId}`}
            label=""
            options={configs?.SIM_TYPE_OPTIONS}
            value={cellInfo?.original?.simType}
            invalid={cellInfo?.original?.invalid_SIM_type}
            events={{ onChange: (e) => onChangeSimType(e, cellInfo) }}
            developmentUrl={ableDevelopmentUrl}
          />
        );
      },
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(
        NEW_SIM_CONFIG_TABLE.TABLE_HEADERS.SIM_SERIAL_NUMBER
      ),
      accessor: "simSerialNumber",
      sortable: true,
      resizable: false,
      width: 206,
      Cell: (cellInfo) => {
        return cellInfo?.original?.simType === "existing SIM Card" ? (
          <SIMSerialNumber
            setId={cellInfo.original.setId}
            planId={cellInfo.original.planId}
          />
        ) : (
          (cellInfo?.original?.simType === "SIM Card" ||
            cellInfo?.original?.simType === "eSIM") && (
            <InlineLink>
              <TextStyle alias="Base">
                <p>
                  {NEW_SIM_CONFIG_TABLE.SIM_INFORMATION_MESSAGE_1}
                  <a href="#">
                    {NEW_SIM_CONFIG_TABLE.SIM_INFORMATION_MESSAGE_2}
                  </a>
                  {NEW_SIM_CONFIG_TABLE.SIM_INFORMATION_MESSAGE_3}
                </p>
              </TextStyle>
            </InlineLink>
          )
        );
      },
    },
    {
      headerClassName: "table-align-left",
      className: "td-align-left border-right",
      style: { paddingLeft: "8px" },
      Header: renderBoldText(NEW_SIM_CONFIG_TABLE.TABLE_HEADERS.ADD_USERNAME),
      accessor: "username",
      sortable: true,
      resizable: false,
      width: 240,
      Cell: (cellInfo) => {
        return (
          <AddUsername
            setId={cellInfo.original.setId}
            planId={cellInfo.original.planId}
          />
        );
      },
    },
    {
      Header: renderBoldText(
        NEW_SIM_CONFIG_TABLE.TABLE_HEADERS.GROUP_BY_PLANNAME
      ),
      accessor: "planId",
      sortable: false,
      width: 130,
      expander: true,
      pivot: true,
      Pivot: (cellInfo) => {
        const subrowsLength = cellInfo?.subRows?.length;
        const className = ["rt-flex", "rt-flex-subheader"].join(" ");
        const planName = cellInfo?.subRows[0]?.planName;
        const planId = cellInfo?.value;
        const subRowIds = cellInfo?.subRows.map((item) => {
          return `${item.setId}:${item.planId}`;
        });

        return (
          <div
            className={`tl-row-expander ${
              cellInfo?.isExpanded ? "tl-row-expanded" : ""
            }`}
          >
            <div className={className}>
              <div
                className="tl-border-override icon-container"
                style={{ width: 96 }}
              >
                <div className="tl-accordion-icon-wrapper">
                  {cellInfo?.isExpanded ? (
                    <SvgIcon name="icon-system-chevron-up" />
                  ) : (
                    <SvgIcon name="icon-system-chevron-down" />
                  )}
                </div>
              </div>
              <div className="group-header-section">
                <div
                  className="tl-border-override"
                  style={{ width: 400 }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="pivot-text-holder">
                    <Checkbox
                      id={planId}
                      checked={subRowIds.every((item: any) =>
                        selectedPlans.includes(item)
                      )}
                      label={<TextStyle alias="Label">{planName}</TextStyle>}
                      onChange={(e) => {
                        let customSelectedColumn = [...selectedPlans];
                        if (!e.target.checked) {
                          customSelectedColumn = customSelectedColumn.filter(
                            (item: any) => !subRowIds.includes(item)
                          );
                        } else {
                          const remainingSuIds = subRowIds.filter(
                            (item) => !customSelectedColumn.includes(item)
                          );
                          customSelectedColumn = [
                            ...customSelectedColumn,
                            ...remainingSuIds,
                          ];
                        }
                        dispatch(toggleAll(customSelectedColumn));
                      }}
                    />
                    <InlineLink>
                      <TextStyle alias="Base" className="view-details">
                        <button
                          onClick={() => {
                            setSelectedPlanDetails(cellInfo.subRows[0]);
                            toggleModal();
                          }}
                          className="no-msg-link-btn"
                        >
                          {NEW_SIM_CONFIG_TABLE.VIEW_DETAILS.BTN}
                        </button>
                      </TextStyle>
                    </InlineLink>
                  </div>
                </div>
              </div>
              <div
                className="pivot-text-holder tl-border-override"
                style={{ width: 86 }}
              >
                <TextStyle alias="Label">{subrowsLength}</TextStyle>
              </div>
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="sim-config-table">
      {isPlanFieldInvalid && (
        <Spacing bottom="spacing3x">
          <MessageSection
            description={NEW_SIM_CONFIG_TABLE.ALL_SIM_SERIAL_NUMBER_VALIDATION}
            variant="Error"
            developmentUrl={ableDevelopmentUrl}
          />
        </Spacing>
      )}
      <TableAccordion
        data={simConfigPlans}
        columns={columnData}
        showPagination={true}
        resizable={true}
        page={page}
        defaultPageSize={pageSize}
        totalRecordLength={simConfigPlans && simConfigPlans.length}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        isStickyHeader={true}
        sortable={false}
        pivotBy={["planId"]}
        expanded={expanded}
        onExpandedChange={onExpandedChange}
        freezWhenExpanded={false}
        defaultSortMethod={() => ({})}
        getTdProps={(state, rowInfo, column, instance) => {
          const invalidRow =
            rowInfo.original?.invalid_UNMS_SIM_number ||
            rowInfo.original?.invalid_SIM_number ||
            rowInfo.original?.invalid_username ||
            rowInfo.original?.invalid_SIM_type;
          return invalidRow ? { className: "invalid-row" } : {};
        }}
      />
      <ViewDetailsModal
        selectedPlanDetails={selectedPlanDetails}
        modalIsOpen={modalIsOpen}
        toggleModal={toggleModal}
      />
    </div>
  );
};

export default NewSimConfigurationTable;
