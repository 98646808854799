import { Outlet } from "react-router-dom";
import Header from "./components/header";
import Breadcrumb from "./components/breadcrumbs";
import "telstra-ui/resources/telstraui.css";
import "telstra-ui/resources/themes/pacific.css";
import ChargeSummary from "./components/charge-summary";
import Footer from "@components/footer";
import { useScrollToTop } from "@utils/helper";
import { useGetFeatureFlagsQuery } from "@services/base";
import { GlobalSpinner } from "@components/loading-screen";

export default function App() {
  useScrollToTop();
  useGetFeatureFlagsQuery();

  return (
    <>
      <GlobalSpinner />
      <Header />
      <ChargeSummary />
      <Breadcrumb />
      <Outlet />
      <Footer />
    </>
  );
}
