import { Spacing, TextStyle } from "@able/react";
import { useAppSelector } from "@state/hooks";
import { BULK_CONFIG } from "@pages/new-plan-order/steps/sim-configuration/bulk-config/constants";

export default function SelectedPlansCount() {
  const selectedPlans = useAppSelector(
    (store) => store.commonStore.selectedPlans
  );

  return (
    <Spacing bottom="spacing1x">
      <TextStyle alias="Label">
        {BULK_CONFIG.PLANS_SELECTED(selectedPlans.length)}
      </TextStyle>
    </Spacing>
  );
}
