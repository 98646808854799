import { IEnvironmentSpecificConfig } from "./commonInterface";
import { Environments, Environment } from "./environments";
import getHostUrl from "./get-host";
import dev from "@config/cms/dev/config.json";
import local from "@config/cms/local/config.json";
import mock from "@config/cms/mock/config.json";
import poc from "@config/cms/poc/config.json";
import prod from "@config/cms/prod/config.json";
import qa from "@config/cms/qa/config.json";
import sit from "@config/cms/sit/config.json";
import staging from "@config/cms/staging/config.json";
import { IGetFeatureFlagsAEM } from "@state/types";

declare let global: {
  env: string;
};

export const Configurations: { [key: string]: IEnvironmentSpecificConfig } = {
  [Environments.PROD]: {
    apiHostUrl: getHostUrl(
      "aHR0cHM6Ly90YXBpLnRlbHN0cmEuY29tL3ByZXNlbnRhdGlvbi92MS90YXBhcy9zdHJhdGVnaWMtbWFuYWdlbWVudC8="
    ), // 'https://tapi.telstra.com/presentation/v1/tapas/strategic-management/'
    aemDynamicContentHostURL: getHostUrl(
      "aHR0cHM6Ly93d3cudGVsc3RyYS5jb20uYXUvY29udGVudC9kYW0vdGNvbS9jcHFvL2Ntcy9wcm9kLw=="
    ),
    // 'https://www.telstra.com.au/content/dam/tcom/cpqo/cms/prod/'
    phoenixHostUrl: getHostUrl(
      "aHR0cHM6Ly90ZWxzdHJhYjJiLmxpZ2h0bmluZy5mb3JjZS5jb20vbGlnaHRuaW5nL3BhZ2UvaG9tZQ=="
    ), // "https://telstrab2b.lightning.force.com/lightning/page/home"
    authority:
      "https://sa.auth.thyra.telstra.com/SecureAuth674/SecureAuth.aspx",
    client_id: "49a64c0dcc644961b4af629d10597d3b",
  },

  [Environments.STAGING]: {
    apiHostUrl: getHostUrl(
      "aHR0cHM6Ly9zdGFnaW5nLXRhcGkudGVsc3RyYS5jb20vcHJlc2VudGF0aW9uL3YxL3RhcGFzL3N0cmF0ZWdpYy1tYW5hZ2VtZW50Lw=="
    ), // 'https://staging-tapi.telstra.com/presentation/v1/tapas/strategic-management/'
    aemDynamicContentHostURL: getHostUrl(
      "aHR0cHM6Ly93d3cudGVsc3RyYS5jb20uYXUvY29udGVudC9kYW0vdGNvbS9jcHFvL2Ntcy9zdGFnaW5nLw=="
    ),
    // 'https://www.telstra.com.au/content/dam/tcom/cpqo/cms/staging/'
    phoenixHostUrl: getHostUrl(
      "aHR0cHM6Ly90ZWxzdHJhYjJiLS1wcmVwcm9kdGVsLnNhbmRib3gubGlnaHRuaW5nLmZvcmNlLmNvbS9saWdodG5pbmcvcGFnZS9ob21l"
    ), // "https://telstrab2b--preprodtel.sandbox.lightning.force.com/lightning/page/home"
    authority:
      "https://sa.auth.thyra.telstra.com/SecureAuth674/SecureAuth.aspx",
    client_id: "49a64c0dcc644961b4af629d10597d3b",
  },
  [Environments.SIT]: {
    apiHostUrl: getHostUrl(
      "aHR0cHM6Ly9zbG90NC5vcmcwMDIudC1kZXYudGVsc3RyYS5jb20vcHJlc2VudGF0aW9uL3YxL3RhcGFzL3N0cmF0ZWdpYy1tYW5hZ2VtZW50Lw=="
    ), // https://slot4.org002.t-dev.telstra.com/presentation/v1/tapas/strategic-management/
    aemDynamicContentHostURL: getHostUrl(
      "aHR0cHM6Ly9zdGFnZS50ZWxzdHJhLmNvbS5hdS9jb250ZW50L2RhbS90Y29tL2NwcW8vY21zL3NpdC8="
    ), // 'https://stage.telstra.com.au/content/dam/tcom/cpqo/cms/sit/'
    phoenixHostUrl: getHostUrl(
      "aHR0cHM6Ly90ZWxzdHJhYjJiLS10ZWxiMmJxYTIuc2FuZGJveC5saWdodG5pbmcuZm9yY2UuY29tL2xpZ2h0bmluZy9wYWdlL2hvbWU="
    ), // "https://telstrab2b--telb2bqa2.sandbox.lightning.force.com/lightning/page/home"
    authority: "https://sa.thyra.telstra.com/SecureAuth872/SecureAuth.aspx",
    client_id: "110b5e059328429aaa4211e98b4c6672",
  },
  [Environments.SVT]: {
    apiHostUrl: getHostUrl(
      "aHR0cHM6Ly9zbG90NS5vcmcwMDIudC1kZXYuY29ycC50ZWxzdHJhLmNvbS9wcmVzZW50YXRpb24vdjEvdGFwYXMvc3RyYXRlZ2ljLW1hbmFnZW1lbnQ="
    ), // https://slot5.org002.t-dev.corp.telstra.com/presentation/v1/tapas/strategic-management
    aemDynamicContentHostURL: getHostUrl(
      "aHR0cHM6Ly9zdGFnZS50ZWxzdHJhLmNvbS5hdS9jb250ZW50L2RhbS90Y29tL2NwcW8vY21zL3N2dC8="
    ), // 'https://stage.telstra.com.au/content/dam/tcom/cpqo/cms/svt/'
    phoenixHostUrl: getHostUrl(
      "aHR0cHM6Ly90ZWxzdHJhYjJiLS10ZWxiMmJxYTIuc2FuZGJveC5saWdodG5pbmcuZm9yY2UuY29tL2xpZ2h0bmluZy9wYWdlL2hvbWU="
    ), // "https://telstrab2b--telb2bqa2.sandbox.lightning.force.com/lightning/page/home"
    authority: "https://sa.thyra.telstra.com/SecureAuth872/SecureAuth.aspx",
    client_id: "110b5e059328429aaa4211e98b4c6672",
  },
  [Environments.QA]: {
    apiHostUrl: getHostUrl(
      "aHR0cHM6Ly9zbG90My5vcmcwMDIudC1kZXYudGVsc3RyYS5jb20vcHJlc2VudGF0aW9uL3YxL3RhcGFzL3N0cmF0ZWdpYy1tYW5hZ2VtZW50Lw=="
    ), // https://slot3.org002.t-dev.telstra.com/presentation/v1/tapas/strategic-management/
    aemDynamicContentHostURL: getHostUrl(
      "aHR0cHM6Ly9zdGFnZS50ZWxzdHJhLmNvbS5hdS9jb250ZW50L2RhbS90Y29tL2NwcW8vY21zL3FhLw=="
    ),
    // 'https://stage.telstra.com.au/content/dam/tcom/cpqo/cms/qa/'
    phoenixHostUrl: getHostUrl(
      "aHR0cHM6Ly90ZWxzdHJhYjJiLS10ZWxxYTFiMmIuc2FuZGJveC5saWdodG5pbmcuZm9yY2UuY29tL2xpZ2h0bmluZy9wYWdlL2hvbWU="
    ), // "https://telstrab2b--telqa1b2b.sandbox.lightning.force.com/lightning/page/home"
    authority: "https://sa.thyra.telstra.com/SecureAuth872/SecureAuth.aspx",
    client_id: "110b5e059328429aaa4211e98b4c6672",
  },
  [Environments.MOCK]: {
    apiHostUrl: getHostUrl(
      "aHR0cHM6Ly9tb2NrLWFwaS1vcmRlci11aS5hcHBzLm5wLnNkcHBjZi5jb20v"
    ), // https://mock-api-order-ui.apps.np.sdppcf.com/,
    aemDynamicContentHostURL: getHostUrl(
      "aHR0cHM6Ly9zdGFnZS50ZWxzdHJhLmNvbS5hdS9jb250ZW50L2RhbS90Y29tL2NwcW8vY21zL21vY2sv"
    ), // https://stage.telstra.com.au/content/dam/tcom/cpqo/cms/mock/
    phoenixHostUrl: getHostUrl(
      "aHR0cHM6Ly90ZWxzdHJhYjJiLS10ZWxxYTFiMmIuc2FuZGJveC5saWdodG5pbmcuZm9yY2UuY29tL2xpZ2h0bmluZy9wYWdlL2hvbWU="
    ), // "https://telstrab2b--telqa1b2b.sandbox.lightning.force.com/lightning/page/home"
  },
  [Environments.DEV]: {
    apiHostUrl: getHostUrl(
      "aHR0cHM6Ly9zbG90Mi5vcmcwMDIudC1kZXYudGVsc3RyYS5jb20vcHJlc2VudGF0aW9uL3YxL3RhcGFzL3N0cmF0ZWdpYy1tYW5hZ2VtZW50Lw=="
    ), //  https://slot2.org002.t-dev.telstra.com/presentation/v1/tapas/strategic-management/
    aemDynamicContentHostURL: getHostUrl(
      "aHR0cHM6Ly9zdGFnZS50ZWxzdHJhLmNvbS5hdS9jb250ZW50L2RhbS90Y29tL2NwcW8vY21zL2Rldi8="
    ), // 'https://stage.telstra.com.au/content/dam/tcom/cpqo/cms/dev/'
    phoenixHostUrl: getHostUrl(
      "aHR0cHM6Ly90ZWxzdHJhYjJiLS10ZWxxYTFiMmIuc2FuZGJveC5saWdodG5pbmcuZm9yY2UuY29tL2xpZ2h0bmluZy9wYWdlL2hvbWU="
    ), // "https://telstrab2b--telqa1b2b.sandbox.lightning.force.com/lightning/page/home"
    authority: "https://sa.thyra.telstra.com/SecureAuth872/SecureAuth.aspx",
    client_id: "110b5e059328429aaa4211e98b4c6672",
  },

  [Environments.LOCAL]: {
    apiHostUrl: getHostUrl("aHR0cDovL2xvY2FsaG9zdDo1MDUwLw=="), // 'http://localhost:5050/'
    aemDynamicContentHostURL: getHostUrl("aHR0cDovL2xvY2FsaG9zdDo1MDUwLw=="), // 'http://localhost:5050/'
    phoenixHostUrl: getHostUrl(
      "aHR0cHM6Ly90ZWxzdHJhYjJiLS10ZWxxYTFiMmIuc2FuZGJveC5saWdodG5pbmcuZm9yY2UuY29tL2xpZ2h0bmluZy9wYWdlL2hvbWU="
    ), // "https://telstrab2b--telqa1b2b.sandbox.lightning.force.com/lightning/page/home"
  },
  [Environments.POC]: {
    apiHostUrl: getHostUrl(
      "aHR0cHM6Ly9tb2NrLWFwaS1vcmRlci11aS5hcHBzLm5wLnNkcHBjZi5jb20v"
    ), // https://mock-api-order-ui.apps.np.sdppcf.com/,
    aemDynamicContentHostURL: getHostUrl(
      "aHR0cHM6Ly9zdGFnZS50ZWxzdHJhLmNvbS5hdS9jb250ZW50L2RhbS90Y29tL2NwcW8vY21zL3BvYy8="
    ), // https://stage.telstra.com.au/content/dam/tcom/cpqo/cms/poc/
    phoenixHostUrl: getHostUrl(
      "aHR0cHM6Ly90ZWxzdHJhYjJiLS10ZWxxYTFiMmIuc2FuZGJveC5saWdodG5pbmcuZm9yY2UuY29tL2xpZ2h0bmluZy9wYWdlL2hvbWU="
    ), // "https://telstrab2b--telqa1b2b.sandbox.lightning.force.com/lightning/page/home"
    authority: "https://sa.thyra.telstra.com/SecureAuth872/SecureAuth.aspx",
    client_id: "110b5e059328429aaa4211e98b4c6672",
  },
};

export const getCurrentEnvironment: () => Environment = () => {
  if ("env" in global) {
    return global.env as Environment;
  }
  return Environments.MOCK;
};

const getConfig = () => {
  const env = getCurrentEnvironment();
  const baseConfig = Configurations[env];
  return baseConfig;
};

export const Config = getConfig();

export const getLocalFeatureFlags = (): IGetFeatureFlagsAEM => {
  const localFeatureFlags = {
    dev,
    local,
    mock,
    poc,
    prod,
    qa,
    sit,
    staging,
  };
  const env = global.env || Environments.LOCAL;
  return localFeatureFlags[env.toLowerCase()];
};
