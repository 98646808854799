/* eslint-disable react-hooks/exhaustive-deps */
import { ActionButton } from "@able/react";
import { CONFIGURE_DEVICES_AND_ACCESSORIES } from "@pages/devices-and-accessories/constant";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { setIsDeviceSelected } from "@state/select-devices-slice";
import { ableDevelopmentUrl } from "@utils/helper";
import { useEffect } from "react";

export default function GroupPaymentTypeButton(props) {
  const { toggleModal } = props;
  const dispatch = useAppDispatch();
  const configuredDeviceSelected = useAppSelector(
    (state) => state.selectDevices.configuredDeviceSelected
  );

  useEffect(() => {
    dispatch(setIsDeviceSelected(true));
  }, [configuredDeviceSelected]);
  return (
    <ActionButton
      developmentUrl={ableDevelopmentUrl}
      element="button"
      label={CONFIGURE_DEVICES_AND_ACCESSORIES.GROUP_PAYMENT_TYPE.LABEL}
      variant="MediumEmphasis"
      events={{
        onClick: () => {
          if (configuredDeviceSelected.length === 0) {
            const addressDropdown = document.getElementsByClassName(
              "group-payment-message-section"
            )[0];
            addressDropdown?.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });
            dispatch(setIsDeviceSelected(false));
          } else {
            toggleModal();
          }
        },
      }}
    />
  );
}
