import "./style.scss";
import { QuantityInput } from "telstra-ui/components/quantity/quantity";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { updateSelectedOffers } from "@state/select-plans-slice";
import { IOffer } from "@state/types";
import { setCart } from "@state/cart-slice";

export default function QuantityButton({ offer }: { offer: IOffer }) {
  const dispatch = useAppDispatch();
  const selectedOffers = useAppSelector(
    (state) => state.selectPlans.selectedOffers
  );

  const isSelected = selectedOffers.find(
    (selectedOffer) => selectedOffer.id === offer.id
  );

  const handleChange = (newQuantity) => {
    dispatch(updateSelectedOffers({ offer, newQuantity }));
    dispatch(setCart({ error: null }));
  };

  return (
    <QuantityInput
      className="quantity-button"
      id={offer.id}
      index={0}
      value={isSelected ? isSelected.quantity : 0}
      min={0}
      enable={true}
      step={1}
      onChange={(_index, quantity) => {
        handleChange(quantity);
      }}
    />
  );
}
