import { IconButton } from "@able/react";
import { ableDevelopmentUrl } from "@utils/helper";

const ActionColumn = ({
  cellInfo,
  setSetIDsOfplansToBeRemoved,
  toggleModal,
}) => {
  return (
    <div className={`action-wrapper`}>
      <IconButton
        icon="Calendar"
        screenReaderContent="Remove Plan"
        variant="Destructive"
        developmentUrl={ableDevelopmentUrl}
        size="Big"
        events={{
          onClick: (e) => {
            e.stopPropagation();
            const setIDsOfplansToBeRemoved = new Set();
            if (cellInfo.original) {
              setIDsOfplansToBeRemoved.add(cellInfo.original.setId);
            } else {
              cellInfo.subRows.forEach((plan) => {
                setIDsOfplansToBeRemoved.add(plan._original.setId);
              });
            }
            setSetIDsOfplansToBeRemoved(setIDsOfplansToBeRemoved);
            toggleModal();
          },
        }}
      />
    </div>
  );
};

export default ActionColumn;
