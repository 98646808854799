import { ActionButtonProps } from "@able/react/dist/ActionButton";
import { Config } from "@config/index";
import { PATHS } from "@utils/common-static-data";
import { ableDevelopmentUrl } from "@utils/helper";

const phoenixHostUrl = Config.phoenixHostUrl;
export const ORDER_CONFIRMATION_PAGE = {
  HEADING: "Order has been submitted",
  SECTION_HEADING: "Order details",
  SUBTITLE: "Subtitle short example text here. ",
  ACTION_LABEL: "Export order details",
  CREATE_ANOTHER_CTA: "Create another order",
  RETURN_CUSTOMER_CTA: "Return to customer account",
  RETURN_HOME_CTA: "Return to home",
};

export const ORDER_CONFIRMATION_PAGE_CTA: (ActionButtonProps & {
  id: string;
})[] = [
  {
    id: "createAnotherOrderEvent",
    developmentUrl: ableDevelopmentUrl,
    element: "NavLink",
    to: PATHS.LANDING_PAGE,
    label: ORDER_CONFIRMATION_PAGE.CREATE_ANOTHER_CTA,
    variant: "HighEmphasis",
    className: "order-confirmation-cta",
  },
  {
    id: "returnToCustomerAccountEvent",
    developmentUrl: ableDevelopmentUrl,
    element: "button",
    label: ORDER_CONFIRMATION_PAGE.RETURN_CUSTOMER_CTA,
    variant: "MediumEmphasis",
    className: "order-confirmation-cta",
  },
  {
    id: "returnToHomeEvent",
    developmentUrl: ableDevelopmentUrl,
    element: "NavLink",
    to: phoenixHostUrl,
    label: ORDER_CONFIRMATION_PAGE.RETURN_HOME_CTA,
    variant: "MediumEmphasis",
    className: "order-confirmation-cta",
  },
];

export const FIELDS = {
  LABELS: {
    ORDER_NUMBER: "Order number",
    SUBMISSION_DATE: "Submission date",
    ORDER_CONFIRMATION: "Order confirmation emailed to",
    NAME: "Customer name",
    CIDN: "Customer CIDN",
    BILLING_ACCOUNT: "Billing account",
    REQUESTER: "Requester",
    INTERNAL_REFERENCE_NUMBER: "Telstra internal reference number",
    ORDER_REFERENCE: "Order reference",
  },
};
