import "./style.scss";
import { TextStyle, Column, Spacing, Divider, Surface } from "@able/react";
import QuantityButton from "../../components/quantity-button";
import { NEW_PLAN_ORDER } from "../../../../constant";
import { IOffer } from "@state/types";
import Inclusions from "../../components/inclusions";
import DataCost from "../../components/data-cost";

export default function GridSurface({ offer }: { offer: IOffer }) {
  const { sharedDataPool, inclusions } = offer;

  return (
    <Column bsm={6} bmd={4} className="grid-view-column">
      <Surface
        className="surface-container"
        variant="SurfaceFlat"
        cornerRadius="cornerRadiusSoft"
        background="materialBasePrimary"
      >
        <div
          className="surface-inner-container"
          data-testid="grid-surface"
          id={`gridViewSurface${offer.id}`}
          aria-label={offer.offerName}
        >
          <div>
            <TextStyle alias="HeadingC" alignment="Left" element="span">
              {offer.offerName}
            </TextStyle>
            <Spacing top="spacing4x" bottom="spacing3x">
              <DataCost offer={offer} view="grid" />
            </Spacing>
            <Inclusions inclusions={inclusions} />
            <Spacing top="spacing2x" bottom="spacing2x">
              <Divider />
            </Spacing>
            <TextStyle alias="Label">{NEW_PLAN_ORDER.PLAN_SHARING}</TextStyle>
            <TextStyle alias="Base">
              <span>{sharedDataPool}</span>
            </TextStyle>
          </div>
          <Spacing top="spacing2x">
            <QuantityButton offer={offer} />
          </Spacing>
        </div>
      </Surface>
    </Column>
  );
}
