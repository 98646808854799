import "./style.scss";
import { Spacing, TextStyle } from "@able/react";
import { NEW_PLAN_ORDER } from "@pages/new-plan-order/constant";
import { IOffer } from "@state/types";
import { prefixWithDollarSign } from "@utils/helper";

type IOfferNameProps = {
  offer: IOffer;
  view: "list" | "grid";
};

export default function DataCost({ offer, view }: IOfferNameProps) {
  const { dataAllowance, monthlyCharge } = offer;

  return (
    <div className={`${view}-view`}>
      <div className="data-price-section">
        <TextStyle alias="HeadingC">{dataAllowance}</TextStyle>
        <div className="vert-divider" />
        <div className="price-details">
          <TextStyle alias="HeadingC">
            {prefixWithDollarSign(monthlyCharge)}
          </TextStyle>
          <div className="price-units">
            <TextStyle alias="HeadingC">
              {`${NEW_PLAN_ORDER.PLAN_PER_MONTH}`}
            </TextStyle>
            <Spacing left="spacingHalf">
              <TextStyle alias="FinePrint" className="gst">
                {`${NEW_PLAN_ORDER.PLAN_PRICE_TEXT}`}
              </TextStyle>
            </Spacing>
          </div>
        </div>
      </div>
    </div>
  );
}
