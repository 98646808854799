import { baseApiService } from "./base";
import {
  IAvailableNumbers,
  IValidateSIMSerialResponse,
  IRefreshNumberParams,
  ISwapNumbersParams,
} from "@state/types";
import { CONSTANTS } from "./constant";
import { toggleAll } from "@state/common-slice";
import {
  createRefreshNumberRequestBody,
  createFetchNumbersRequestBody,
} from "@services/templates/number";
import {
  updateAssignedPhoneNumbers,
  // updatePlansArrUsingIndex,
} from "@state/cart-slice";
export const numbersApiService = baseApiService.injectEndpoints({
  endpoints: (builder) => ({
    fetchNumber: builder.query<IAvailableNumbers, any>({
      query: (searchFormState) => ({
        url: "number-management/fetch-numbers",
        method: CONSTANTS.METHODS.POST,
        body: createFetchNumbersRequestBody(searchFormState),
      }),
      // configuration for an individual endpoint, overriding the api setting
      keepUnusedDataFor: 5,
    }),
    refreshNumber: builder.query<IAvailableNumbers, IRefreshNumberParams>({
      query: (params) => ({
        url: "number-management/fetch-numbers",
        method: CONSTANTS.METHODS.POST,
        body: createRefreshNumberRequestBody(params),
      }),
      // Below code is out of scope for now. once we start working again on TAPAS then the below
      // functionality needs to be completed. Please use this reducer updateQBSPlansArrUsingIndex
      // to update the numbers.
      // async onQueryStarted(params, { dispatch, queryFulfilled }) {
      // const { data } = await queryFulfilled;
      // const newNumber = data.data.numbers[0].number;

      // const payload = {
      //   planIndexes: [params.planIndex],
      //   fields: {
      //     phoneNumber: newNumber,
      //     refreshCount: params.refreshCount + 1,
      //     planUpdated: true,
      //   },
      // };
      // dispatch(updatePlansArrUsingIndex(payload));
      // },
    }),
    validateSIMSerials: builder.query<IValidateSIMSerialResponse, any>({
      query: (SIMSerialNumbers) => ({
        url: "number-management/validate-sim-serials",
        method: CONSTANTS.METHODS.POST,
        body: SIMSerialNumbers,
      }),
      keepUnusedDataFor: 5,
    }),
    unlockNumbers: builder.query<any, any>({
      query: (data) => ({
        url: "number-management/manage-numbers",
        method: CONSTANTS.METHODS.PATCH,
        body: {
          action: CONSTANTS.NUMBERS.UNLOCK,
          numbersForUnassignment: data,
        },
      }),
    }),
    swapNumbers: builder.query<IAvailableNumbers, ISwapNumbersParams>({
      query: (params) => ({
        url: "number-management/manage-numbers",
        method: CONSTANTS.METHODS.PATCH,
        body: {
          action: CONSTANTS.NUMBERS.RESERVE_UNLOCK,
          numbersForAssignment: params.selectedNumbers,
          numbersForUnassignment: params.numbersForUnassignment,
        },
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        const { newSelectedPlans, selectedIds } = params;
        const newNumbers = data.data.numbers.map((item) => item.number);

        dispatch(
          updateAssignedPhoneNumbers({
            selectedIds,
            selectedNumbers: newNumbers,
            numbersForUnassignment: params.numbersForUnassignment,
          })
        );
        dispatch(toggleAll(newSelectedPlans));
      },
    }),
  }),
});

export const {
  useLazyFetchNumberQuery,
  useLazyValidateSIMSerialsQuery,
  useLazyRefreshNumberQuery,
  useLazyUnlockNumbersQuery,
  useLazySwapNumbersQuery,
} = numbersApiService;
