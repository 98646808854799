import { createSlice } from "@reduxjs/toolkit";
import { resetAction } from "./reset";
import { IPaginationState } from "./types";
import { INITIAL_PAGINATION_STATE } from "@utils/common-static-data";
interface IPlan {
  id: string;
  planType: string;
  phoneNumber: string;
  barring: string[];
  addOns: string[];
  simType: string;
  simSerialNumber: string;
  username: string;
  invalid_UNMS_SIM_number: boolean;
  invalid_SIM_number: boolean;
  invalid_username: boolean;
  invalid_SIM_type: boolean;
  refreshCount: number;
}
interface INumber {
  // eslint-disable-next-line id-denylist
  number: string;
  numberType: string;
  premiumType: string;
  status: string;
}

interface ISearchNumbersFormState {
  searchQuantity: string;
  patternType: string;
  pattern: string;
  action: string;
  numbersForUnassignment: string[];
}

interface IValidationDetailsState {
  searchQuantity: {
    isInvalid: boolean;
    message: string;
  };
  patternType: {
    isInvalid: boolean;
    message: string;
  };
  pattern: {
    isInvalid: boolean;
    message: string;
  };
}

export interface ISIMConfigurationCartState {
  selectedNumbers: string[];
  searchResultsState: {
    availableNumbers: INumber[];
    fetchedNumbers: boolean;
  };
  paginationState: IPaginationState;
  plans: IPlan[];
  refreshedPhonePlanId: string;
  searchNumbersFormState: ISearchNumbersFormState;
  validationDetails: IValidationDetailsState;
  isPlanFieldInvalid: boolean;
}

const initialSearchNumbersFormState = {
  searchQuantity: "",
  patternType: "",
  pattern: "",
  action: "",
  numbersForUnassignment: [],
};

const initialValidationState = {
  searchQuantity: {
    isInvalid: false,
    message: "",
  },
  patternType: {
    isInvalid: false,
    message: "",
  },
  pattern: {
    isInvalid: false,
    message: "",
  },
};

const initialState: ISIMConfigurationCartState = {
  selectedNumbers: [],
  searchResultsState: {
    availableNumbers: [],
    fetchedNumbers: false,
  },
  paginationState: INITIAL_PAGINATION_STATE,
  plans: [],
  refreshedPhonePlanId: "",
  searchNumbersFormState: initialSearchNumbersFormState,
  validationDetails: initialValidationState,
  isPlanFieldInvalid: false,
};

export const simConfigurationSlice = createSlice({
  name: "sim-configuration",
  initialState,
  reducers: {
    toggleSelectedNumbers: (state, action) => {
      const id = action.payload;
      const position = state.selectedNumbers.indexOf(id);
      if (position === -1) {
        state.selectedNumbers.push(id);
      } else {
        state.selectedNumbers.splice(position, 1);
      }
    },
    toggleAllNumbers: (state, action) => {
      if (action.payload.length === state.selectedNumbers.length) {
        return { ...state, selectedNumbers: [] };
      }
      return { ...state, selectedNumbers: [...action.payload] };
    },
    deleteSelectedNumbers: (state) => {
      return { ...state, selectedNumbers: [] };
    },
    setPagination: (state, action) => {
      return { ...state, paginationState: { ...action.payload } };
    },
    setPlans: (state, action) => {
      return { ...state, plans: action.payload };
    },
    setRefreshedPhonePlanId: (state, action) => {
      return { ...state, refreshedPhonePlanId: action.payload };
    },
    setSearchNumbersFormState: (state, action) => {
      return { ...state, searchNumbersFormState: action.payload };
    },
    setValidationDetails: (state, action) => {
      return { ...state, validationDetails: action.payload };
    },
    resetSearchNumbersFormState: (state) => {
      return {
        ...state,
        searchNumbersFormState: initialSearchNumbersFormState,
      };
    },
    setSearchResultsState: (state, action) => {
      return { ...state, searchResultsState: action.payload };
    },
    setIsPlanFieldsInvalid: (state, action) => {
      return { ...state, isPlanFieldInvalid: action.payload };
    },
  },
  extraReducers(builder) {
    builder.addCase(resetAction, (state, action) => {
      return { ...initialState };
    });
  },
});

export const {
  toggleSelectedNumbers,
  toggleAllNumbers,
  deleteSelectedNumbers,
  setPagination,
  setPlans,
  setRefreshedPhonePlanId,
  setSearchNumbersFormState,
  setValidationDetails,
  resetSearchNumbersFormState,
  setSearchResultsState,
  setIsPlanFieldsInvalid,
} = simConfigurationSlice.actions;

export default simConfigurationSlice.reducer;
