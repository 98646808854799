import { createSlice } from "@reduxjs/toolkit";
import { ILoggedInCIDN } from "./types";
import { resetAction } from "./reset";
const initialState: ILoggedInCIDN = {
  cidn: "",
};
export const loggedInCIDNSlice = createSlice({
  name: "loggedInCIDN",
  initialState,
  reducers: {
    setLoggedInCIDN: (state, action) => {
      state.cidn = action.payload.cidn;
    },
  },
  extraReducers(builder) {
    builder.addCase(resetAction, (state, action) => {
      return { ...initialState };
    });
  },
});

export const { setLoggedInCIDN } = loggedInCIDNSlice.actions;
export default loggedInCIDNSlice.reducer;
