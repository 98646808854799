export enum Environments {
  "PROD" = "PROD",
  "STAGING" = "STAGING",
  "SIT" = "SIT",
  "QA" = "QA",
  "MOCK" = "MOCK",
  "DEV" = "DEV",
  "LOCAL" = "LOCAL",
  "POC" = "POC",
  "SVT" = "SVT",
}
type EnsureValuesAreSameAsKeys<TEnum> = {
  [key in keyof TEnum]: key;
};

export type Environment = keyof EnsureValuesAreSameAsKeys<typeof Environments>;
