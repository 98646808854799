export const BULK_CONFIG = {
  TITLE: "Configure in bulk",
  SUBTEXT:
    "Select plans to configure SIMs in bulk. You can also make bulk changes to the assigned numbers.",
  SIM_TYPE: "SIM type",
  ASSIGNED_NUMBER: "Change assigned numbers",
  PLANS_SELECTED: (num) => `${num} plans selected to configure.`,
  NO_PLANS_SELECTED_ERROR: "Select at least 1 plan to configure.",
};

export const SIM_TYPE_CONFIG = {
  TITLE: "Configure SIM type",
  PLANS_SELECTED: (num) => `${num} plans selected to configure.`,
  LABEL: "Choose SIM type",
  CANCEL: "Cancel",
  CONFIRM: "Confirm",
};
