import { Column, Grid, MessageSection, Spacing, useToggle } from "@able/react";
import ConfigureDeviceAndAccessoriesTable from "./configure-device-accessories-table";
import GroupPaymentTypeButton from "./components/group-payment-type-button";
import GroupPaymentTypeModal from "./components/group-payment-type-modal";
import { ableDevelopmentUrl } from "@utils/helper";
import { CONFIGURE_DEVICES_AND_ACCESSORIES } from "@pages/devices-and-accessories/constant";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { useCommonGetCartQuery } from "@services/cart";
import { useParams } from "react-router-dom";
import { useShouldEnableGetCartHook } from "@utils/cart";
import { useEffect } from "react";
import { setError } from "@state/error";
import { setCart } from "@state/cart-slice";
import EmptyCart from "@components/empty-cart";
import { EMPTY_CART_MESSAGE } from "@components/empty-cart/constants";

function ConfigureDeviceAndAccessories() {
  const [modalIsOpen, toggleModal] = useToggle();
  const isDeviceSelected = useAppSelector(
    (state) => state.selectDevices.isDeviceSelected
  );
  const { normalizedDevicesAndAccesories: devicesAndAccesories } =
    useAppSelector((store) => store.cart);
  const { cartId } = useParams();
  const skipGetCart = useShouldEnableGetCartHook();
  const dispatch = useAppDispatch();
  const { isError: isGetCartError, error: getCartError } =
    useCommonGetCartQuery({ cartId }, { skip: !skipGetCart });

  useEffect(() => {
    if (isGetCartError) {
      dispatch(setError(true));
      dispatch(setCart({ error: getCartError }));
    } else {
      dispatch(setError(false));
      dispatch(setCart({ error: null }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetCartError, getCartError]);

  const devicewithOutrightPrice = () => {
    let count = 0;
    devicesAndAccesories.forEach((item) => {
      if (item.twelveMonthPayment === 0 && item.twentyfourMonthPayment === 0) {
        count++;
      }
    });
    return count;
  };

  const emptyCartError = useAppSelector((state) => state.error.emptyCartError);

  return (
    <Grid>
      <Column cols={12} bsm={12} bmd={12} blg={12}>
        {devicewithOutrightPrice() !== devicesAndAccesories.length && (
          <Spacing top="spacing10x">
            <GroupPaymentTypeButton
              modalIsOpen={modalIsOpen}
              toggleModal={toggleModal}
            />
          </Spacing>
        )}
        {emptyCartError && (
          <EmptyCart
            modalIsOpen={emptyCartError}
            title={EMPTY_CART_MESSAGE.TITLE_DEVICES}
            description={EMPTY_CART_MESSAGE.DESCRIPTION_DEVICES}
          />
        )}
        <Spacing top="spacing3x">
          <div className="group-payment-message-section">
            {!isDeviceSelected && (
              <MessageSection
                variant="Error"
                developmentUrl={ableDevelopmentUrl}
                description={
                  CONFIGURE_DEVICES_AND_ACCESSORIES.GROUP_PAYMENT_TYPE
                    .DEVICE_TO_BE_SELECTED_INFORMATION
                }
              />
            )}
          </div>
        </Spacing>
        <Spacing top="spacing4x" bottom="spacing4x">
          <ConfigureDeviceAndAccessoriesTable />
        </Spacing>
        {devicewithOutrightPrice() !== devicesAndAccesories.length && (
          <GroupPaymentTypeButton
            modalIsOpen={modalIsOpen}
            toggleModal={toggleModal}
          />
        )}
        <GroupPaymentTypeModal
          modalIsOpen={modalIsOpen}
          toggleModal={toggleModal}
        />
      </Column>
    </Grid>
  );
}

export default ConfigureDeviceAndAccessories;
