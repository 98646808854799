import "./style.scss";
import {
  Column,
  Grid,
  Select,
  Spacing,
  TextField,
  TextStyle,
  Surface,
} from "@able/react";
import Loading from "@components/loading-screen";
import {
  useGetContactsQuery,
  useLazyValidateReferenceNumberQuery,
} from "@services/base";
import { useLazyCreateCartQuery } from "@services/cart";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { setLoggedInCIDN } from "@state/logged-in-cidn-slice";
import { NewOrderDetailsState } from "@state/new-order-slice";
import { ableDevelopmentUrl, useReloadHook } from "@utils/helper";
import { useEffect, useState } from "react";
import { NEW_ORDER_DETAILS } from "./constant";
import OrderingOptionButton from "./ordering-option-button";
import { customErrorMessages } from "@components/error-screen/constant";
import ErrorScreen from "@components/error-screen";
import { setError } from "@state/error";
import { getEligibleRequesterContacts } from "@utils/contactsValidation";
import { setInitialCart } from "@state/cart-slice";

const initialPageError = {
  error: null,
  customErrorMsg: "",
};
export default function NewOrderDetails() {
  useReloadHook();
  const dispatch = useAppDispatch();
  const existingState = useAppSelector((store) => store.newOrder);
  const [state, setState] = useState<NewOrderDetailsState>(existingState);
  const [pageError, setPageError] = useState(initialPageError);

  function handleChange(e) {
    const { id, value } = e;
    setState({ ...state, [id]: value, [`${id}Error`]: "" });
  }

  const {
    data: requesters,
    isFetching,
    isSuccess,
    error: contactsQueryError,
  } = useGetContactsQuery();
  const [createCart, createCartResult] = useLazyCreateCartQuery();
  const [validateRefQuery, validateQueryResult] =
    useLazyValidateReferenceNumberQuery();

  const requesterOptions =
    getEligibleRequesterContacts(requesters?.contacts)?.map((requester) => {
      return {
        label: requester.fullName,
        value: requester.id,
      };
    }) || [];

  useEffect(() => {
    if (requesterOptions.length === 1) {
      setState({ ...state, requester: requesterOptions[0].value });
    }
    isSuccess && dispatch(setLoggedInCIDN(requesters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (
      (contactsQueryError && !isFetching) ||
      (createCartResult?.isError && !createCartResult?.isFetching) ||
      (validateQueryResult?.isError && !validateQueryResult?.isFetching)
    ) {
      checkError();
    } else {
      dispatch(setError(false));
      setPageError(initialPageError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactsQueryError, isFetching, createCartResult, validateQueryResult]);

  const checkError = () => {
    let customErrMsg = "";
    let errorObj = {};
    if (contactsQueryError && "status" in contactsQueryError) {
      customErrMsg = customErrorMessages?.contactAPI[contactsQueryError.status];
      errorObj = contactsQueryError;
    }
    if (validateQueryResult.error) {
      errorObj = validateQueryResult.error;
      if (
        "status" in validateQueryResult.error &&
        validateQueryResult.error.status === 500
      ) {
        const newState = { ...state };
        newState.internalReferenceNumberError =
          NEW_ORDER_DETAILS.TELSTRA_INTERNAL_REFERENCE_NUMBER_VALIDATION_API_FAILED;
        setState(newState);
        return;
        // inline error for err code 500, hence return
      }
    }

    if (createCartResult.error && "status" in createCartResult.error) {
      customErrMsg =
        customErrorMessages?.cartAPI[createCartResult.error.status];
      errorObj = createCartResult.error;
    }

    dispatch(setError(true));
    setPageError({
      error: errorObj,
      customErrorMsg: customErrMsg || "",
    });
  };

  useEffect(() => {
    dispatch(setInitialCart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (pageError.error) {
    return (
      <ErrorScreen
        error={pageError.error}
        customErrorMsg={pageError.customErrorMsg}
      />
    );
  }

  return isFetching ||
    createCartResult.isFetching ||
    validateQueryResult.isFetching ? (
    <Loading />
  ) : (
    <>
      <Grid>
        <Spacing top="spacing2x">
          <TextStyle alias="HeadingA" element="h1">
            {NEW_ORDER_DETAILS.HEADING}
          </TextStyle>
        </Spacing>
        <Column cols={12} bsm={6} bmd={6} blg={6}>
          <Spacing top="spacing4x">
            <Select
              id="requester"
              label={NEW_ORDER_DETAILS.REQUESTER}
              developmentUrl={ableDevelopmentUrl}
              required={true}
              options={requesterOptions}
              value={state.requester}
              events={{ onChange: (e) => handleChange(e.target) }}
              invalid={!!state.requesterError}
              invalidMessage={state.requesterError}
            />
          </Spacing>
          <Spacing top="spacing5x">
            <TextField
              id="internalReferenceNumber"
              name="internalReferenceNumber"
              developmentUrl={ableDevelopmentUrl}
              label={NEW_ORDER_DETAILS.TELSTRA_INTERNAL_REFERENCE_NUMBER}
              value={state.internalReferenceNumber}
              required={true}
              events={{ onChange: (e) => handleChange(e.target) }}
              invalid={!!state.internalReferenceNumberError}
              invalidInputText={state.internalReferenceNumberError}
            />
          </Spacing>
          <Spacing top="spacing5x">
            <TextField
              id="orderReference"
              name="orderReference"
              developmentUrl={ableDevelopmentUrl}
              label={NEW_ORDER_DETAILS.ORDER_REFERENCE}
              value={state.orderReference}
              required={false}
              events={{ onChange: (e) => handleChange(e.target) }}
            />
          </Spacing>
        </Column>
      </Grid>
      <Spacing top="spacing5x" />
      <Surface
        variant="SurfaceFlat"
        className="new-order-details-page"
        cornerRadius="cornerRadiusNone"
      >
        <Grid>
          <Spacing bottom="spacing5x" top="spacing5x">
            <TextStyle alias="HeadingD" element="h2">
              {NEW_ORDER_DETAILS.WHAT_WOULD_YOU_LIKE_TO_ORDER}
            </TextStyle>
          </Spacing>

          {Object.values(NEW_ORDER_DETAILS.ORDERING_OPTIONS).map((option) => {
            return (
              <OrderingOptionButton
                key={option.ID}
                state={state}
                setState={setState}
                optionDetails={option}
                createCart={createCart}
                validateRef={validateRefQuery}
              />
            );
          })}
        </Grid>
      </Surface>
    </>
  );
}
