import "./style.scss";
import {
  ModalContent,
  ActionButton,
  Spacing,
  TextStyle,
  TextField,
  IconButton,
  MessageSection,
  MessageInline,
} from "@able/react";
import { CONFIGURE_BARRINGS_AND_ADDONS } from "@pages/new-plan-order/steps/configure-plan-barring-add-ons/constant";
import { createMultiplePlansBasedOnSplit } from "@state/cart-slice";
import { useAppDispatch } from "@state/hooks";
import { REGULAR_EXPRESSIONS, ableDevelopmentUrl } from "@utils/helper";
import { useState } from "react";

const BodyContent = (props) => {
  const {
    plan,
    splitList,
    setSplitList,
    splitWarningMessageForGreaterQuantity,
    setSplitWarningMessageForGreaterQuantity,
  } = props;
  const planQuantity = plan.quantity;
  const [reservedMessage, setReservedMessage] = useState(false);

  const topSection = (
    <>
      <Spacing top="spacing2x">
        <TextStyle alias="Base" element="span">
          {`${CONFIGURE_BARRINGS_AND_ADDONS.SPLIT_MODAL.INFORMATION_MESSAGE_1} `}
        </TextStyle>
        <TextStyle alias="Label" element="span">
          {`${plan.quantity} ${plan.planName}`}
        </TextStyle>
        <TextStyle alias="Base" element="span">
          {`${CONFIGURE_BARRINGS_AND_ADDONS.SPLIT_MODAL.INFORMATION_MESSAGE_2}`}
        </TextStyle>
      </Spacing>
      <Spacing top="spacing2x">
        <TextStyle alias="Base">
          {CONFIGURE_BARRINGS_AND_ADDONS.SPLIT_MODAL.INFORMATION_MESSAGE_3}
        </TextStyle>
      </Spacing>
      <Spacing top="spacing5x">
        <TextStyle alias="HeadingD">
          {CONFIGURE_BARRINGS_AND_ADDONS.SPLIT_MODAL.SPLIT_SECTION_HEADING}
        </TextStyle>
      </Spacing>
    </>
  );

  const handleSplitField = (ele, index) => {
    setSplitWarningMessageForGreaterQuantity(false);
    const list = splitList.map((item) => ({ ...item }));
    const { name, value } = ele.target;
    list[index][name] = value;
    list[index].validateField = false;
    list[index].errorMessage = "";
    setSplitList(list);
  };
  return (
    <Spacing bottom="spacing5x">
      {topSection}
      {reservedMessage && (
        <Spacing top="spacing5x">
          <MessageSection
            description={
              CONFIGURE_BARRINGS_AND_ADDONS.SPLIT_MODAL
                .SPLIT_PLANS_INFORMATION_MSG
            }
            variant="Information"
            className="message-section"
            developmentUrl={ableDevelopmentUrl}
          />
        </Spacing>
      )}
      {splitWarningMessageForGreaterQuantity && (
        <Spacing top="spacing5x">
          <MessageInline
            developmentUrl={ableDevelopmentUrl}
            description={
              CONFIGURE_BARRINGS_AND_ADDONS.SPLIT_MODAL
                .SPLIT_MORE_THAN_SELECTED_PLANS_MSG
            }
            variant="Error"
            className="message-section"
          />
        </Spacing>
      )}
      {splitList.map((listItem, index) => {
        return (
          <Spacing top="spacing5x" key={"Splitfield" + index}>
            <div className="split-field-section">
              <TextField
                className="split-field"
                id={`${index}`}
                name="splitField"
                size="Long"
                developmentUrl={ableDevelopmentUrl}
                label={`${CONFIGURE_BARRINGS_AND_ADDONS.SPLIT_MODAL.NUMBER_OF_PLANS}${CONFIGURE_BARRINGS_AND_ADDONS.SPLIT_MODAL.SPLIT_NUMBERS[index]} ${CONFIGURE_BARRINGS_AND_ADDONS.SPLIT_MODAL.SET}`}
                value={listItem.splitField}
                events={{
                  onChange: (e) => {
                    const { name, value } = e.target;
                    if (
                      value === "" ||
                      REGULAR_EXPRESSIONS.NUMBERS_ONLY.test(value)
                    ) {
                      const list = splitList.map((item) => ({ ...item }));
                      list[index].validateField = false;
                      list[index].errorMessage = "";
                      let quantityField;
                      if (splitList.length === 2) {
                        if (value.length !== 0) {
                          quantityField = Number(value);
                          list[index][name] = quantityField;
                          if (value !== "" && quantityField === 0) {
                            list[index].validateField = true;
                            list[index].errorMessage =
                              CONFIGURE_BARRINGS_AND_ADDONS.SPLIT_MODAL.GREATER_THAN_ZERO_MSG;
                          } else if (quantityField > planQuantity) {
                            list[index].validateField = true;
                            list[index].errorMessage =
                              CONFIGURE_BARRINGS_AND_ADDONS.SPLIT_MODAL.MAX_SPLIT_WARNING_MSG;
                          } else {
                            if (index === 0) {
                              list[index + 1][name] =
                                planQuantity - quantityField;
                              list[index + 1].validateField = false;
                              list[index + 1].errorMessage = "";
                            } else if (index === 1) {
                              list[index - 1][name] =
                                planQuantity - quantityField;
                              list[index - 1].validateField = false;
                              list[index - 1].errorMessage = "";
                            }
                          }
                        } else {
                          if (index === 0) {
                            list[0][name] = "";
                          } else if (index === 1) {
                            list[1][name] = "";
                          }
                        }
                        setSplitList(list);
                      } else handleSplitField(e, index);
                    }
                  },
                }}
                invalid={listItem.validateField}
                invalidInputText={listItem.errorMessage}
              />
              {splitList.length > 2 && index > 1 && (
                <IconButton
                  icon="Calendar"
                  className="remove-icon"
                  screenReaderContent={
                    CONFIGURE_BARRINGS_AND_ADDONS.SPLIT_MODAL.REMOVE_SET
                  }
                  variant="Destructive"
                  developmentUrl={ableDevelopmentUrl}
                  size="Big"
                  events={{
                    onClick: () => {
                      setSplitWarningMessageForGreaterQuantity(false);
                      if (splitList.length - 1 === 2) setReservedMessage(false);
                      const list = splitList.map((item) => ({ ...item }));
                      list.splice(index, 1);
                      setSplitList(list);
                    },
                  }}
                />
              )}
            </div>
            {splitList.length < 5 && splitList.length - 1 === index && (
              <Spacing top="spacing5x">
                <ActionButton
                  developmentUrl={ableDevelopmentUrl}
                  element="button"
                  label={CONFIGURE_BARRINGS_AND_ADDONS.SPLIT_MODAL.ADD_SET_BTN}
                  variant="LowEmphasis"
                  icon="Plus"
                  events={{
                    onClick: () => {
                      setReservedMessage(true);
                      setSplitList([
                        ...splitList,
                        {
                          splitField: "",
                          validateField: false,
                          errorMessage: "",
                        },
                      ]);
                    },
                  }}
                />
              </Spacing>
            )}
          </Spacing>
        );
      })}
    </Spacing>
  );
};
const FooterContent = (props) => {
  const {
    setStateModalForMultipleQuantity,
    quantity,
    splitList,
    setSplitList,
    setSplitWarningMessageForGreaterQuantity,
    initialSplitList,
    planId,
  } = props;
  const dispatch = useAppDispatch();
  const resetState = () => {
    setSplitList(initialSplitList);
    setSplitWarningMessageForGreaterQuantity(false);
    setStateModalForMultipleQuantity(false);
  };
  const cancelEvent = {
    onClick: () => {
      resetState();
    },
  };
  const totalSplitCount = splitList.reduce(
    (accumulator, item) => accumulator + Number(item.splitField),
    0
  );

  const splitPlanEvent = {
    onClick: () => {
      const list = splitList.map((item) => ({ ...item }));
      const isFirstFieldEmpty = Number(splitList[0].splitField) === 0;
      const isSecondFieldEmpty = Number(splitList[1].splitField) === 0;
      if (isFirstFieldEmpty || isSecondFieldEmpty) {
        if (isFirstFieldEmpty) {
          list[0].validateField = true;
          list[0].errorMessage =
            CONFIGURE_BARRINGS_AND_ADDONS.SPLIT_MODAL.GREATER_THAN_ZERO_MSG;
        }
        if (isSecondFieldEmpty) {
          list[1].validateField = true;
          list[1].errorMessage =
            CONFIGURE_BARRINGS_AND_ADDONS.SPLIT_MODAL.GREATER_THAN_ZERO_MSG;
        }
        setSplitList(list);
      } else if (totalSplitCount !== quantity) {
        setSplitWarningMessageForGreaterQuantity(true);
        const message = document.getElementsByClassName("message-section")[1];
        message?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      } else {
        const filteredList = splitList.filter((item) => item.splitField > 0);
        dispatch(createMultiplePlansBasedOnSplit({ filteredList, planId }));
        resetState();
      }
    },
  };

  return (
    <>
      <ActionButton
        developmentUrl={ableDevelopmentUrl}
        element="button"
        label={CONFIGURE_BARRINGS_AND_ADDONS.SPLIT_MODAL.CANCEL_BTN}
        variant="MediumEmphasis"
        events={cancelEvent}
      />
      <ActionButton
        developmentUrl={ableDevelopmentUrl}
        element="button"
        label={CONFIGURE_BARRINGS_AND_ADDONS.SPLIT_MODAL.SPLIT_BTN}
        variant="HighEmphasis"
        events={splitPlanEvent}
      />
    </>
  );
};

export default function SplitModal(props) {
  const { modalForMultipleQuantity, setStateModalForMultipleQuantity, plan } =
    props;
  const initialSplitList = [
    { splitField: "", validateField: false, errorMessage: "" },
    { splitField: "", validateField: false, errorMessage: "" },
  ];
  const [splitList, setSplitList] = useState(initialSplitList);
  const [
    splitWarningMessageForGreaterQuantity,
    setSplitWarningMessageForGreaterQuantity,
  ] = useState(false);
  return (
    <ModalContent
      developmentUrl={ableDevelopmentUrl}
      title={CONFIGURE_BARRINGS_AND_ADDONS.SPLIT_MODAL.TITLE}
      variant="Comfortable"
      onClose={() => {
        setSplitList(initialSplitList);
        setSplitWarningMessageForGreaterQuantity(false);
        setStateModalForMultipleQuantity(false);
      }}
      bodyContent={
        <BodyContent
          plan={plan}
          splitList={splitList}
          setSplitList={setSplitList}
          splitWarningMessageForGreaterQuantity={
            splitWarningMessageForGreaterQuantity
          }
          setSplitWarningMessageForGreaterQuantity={
            setSplitWarningMessageForGreaterQuantity
          }
        />
      }
      footerContent={
        <FooterContent
          quantity={plan.quantity}
          planId={plan.id}
          splitList={splitList}
          setSplitList={setSplitList}
          setStateModalForMultipleQuantity={setStateModalForMultipleQuantity}
          splitWarningMessageForGreaterQuantity={
            splitWarningMessageForGreaterQuantity
          }
          setSplitWarningMessageForGreaterQuantity={
            setSplitWarningMessageForGreaterQuantity
          }
          initialSplitList={initialSplitList}
        />
      }
      isShowing={modalForMultipleQuantity}
      setHideDialog={setStateModalForMultipleQuantity}
    />
  );
}
