import "./style.scss";
import { SvgIcon } from "telstra-ui/components/svg/Icon";
import { Navbar } from "telstra-ui/components/navbar/Navbar";
import { Spacing, Icon, Divider } from "@able/react";
import { ableDevelopmentUrl } from "@utils/helper";
import { useLocation, NavLink } from "react-router-dom";
import { PATHS } from "@utils/common-static-data";
import { useAppSelector } from "@state/hooks";
import { Config } from "@config/index";

export default function Header() {
  const { pathname } = useLocation();
  const isError = useAppSelector((state) => state.error.isError);
  const phoenixHostUrl = Config.phoenixHostUrl;

  const cartIcon = !(
    pathname === PATHS.LANDING_PAGE ||
    pathname === PATHS.NEW_ORDER_DETAILS ||
    pathname.includes(PATHS.ORDER_CONFIRMATION) ||
    isError
  );

  const haveDivider =
    pathname.includes(PATHS.SELECT_DEVICES) ||
    pathname.includes(PATHS.CONFIGURE_DEVICES);

  return (
    <>
      <Navbar
        className={`tl-nav-demo-wrapper ${isError ? "colored-header" : ""}`}
        id="tl-navbar"
      >
        <div className="tl-nav-left">
          <NavLink data-testid="telstra-logo" to={phoenixHostUrl}>
            <SvgIcon name="icon-logo-blue" className="tl-icon-logo" />
          </NavLink>
        </div>
        <div className="tl-nav-right">
          <div className="tl-nav-item-question">
            {cartIcon && (
              <Icon
                className="cart-icon"
                developmentUrl={ableDevelopmentUrl}
                icon="Cart"
                size="24"
              />
            )}
            <Icon
              className="help-icon"
              developmentUrl={ableDevelopmentUrl}
              icon="Help"
              size="24"
            />
          </div>
        </div>
        <Spacing right="spacing2x" />
      </Navbar>
      {!isError && haveDivider && <Divider />}
    </>
  );
}
