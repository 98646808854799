import { Spacing, TextStyle, InlineLink } from "@able/react";
import { useState } from "react";
import { SELECT_PLAN } from "@pages/new-plan-order/constant";
import { PATHS } from "@utils/common-static-data";
const InclusionsList = ({ inclusions }) => {
  const [showAll, setShowAll] = useState(false);
  const visibleInclusions = showAll ? inclusions : inclusions.slice(0, 2);
  const toggleShowAll = (e) => {
    e.preventDefault();
    setShowAll(!showAll);
  };
  return (
    <div>
      <TextStyle alias="Base" element="ul">
        {visibleInclusions.map((inclusion, idx) => (
          <li key={idx}>{inclusion}</li>
        ))}
      </TextStyle>
      {inclusions.length > 2 && (
        <Spacing left="spacing2x">
          <TextStyle alias="Base" element="div">
            <InlineLink>
              <a href={PATHS.SELECT_PLAN} onClick={toggleShowAll}>
                {showAll
                  ? SELECT_PLAN.INCLUSIONS_LIST.NORMAL
                  : SELECT_PLAN.INCLUSIONS_LIST.READ_MORE}
              </a>
            </InlineLink>
          </TextStyle>
        </Spacing>
      )}
    </div>
  );
};
export default InclusionsList;
