import "./style.scss";
import React from "react";
import { Column, Grid, MessageSection, Spacing, TextStyle } from "@able/react";
import { ERROR_TYPES } from "../../constant";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { ableDevelopmentUrl, refreshPhoneNumber } from "@utils/helper";
import { SIM_CONFIG_ERRORS, TRY_AGAIN } from "@utils/common-static-data";
import { useLazyRefreshNumberQuery } from "@services/numbers";
import { setRefreshedPhonePlanId } from "@state/sim-configuration-slice";
import { NormalizedPlanQBS } from "@state/types";
import NewSimConfigurationTable from "./new-table-view";
import SelectedPlansCount from "@pages/new-plan-order/steps/components/selected-items-count";
import BulkConfig from "@pages/new-plan-order/steps/sim-configuration/bulk-config";
import BILLING_ACCOUNT_CONTSTANTS from "@components/billing-account/constants";

const SIMConfiguration = () => {
  const dispatch = useAppDispatch();
  const {
    SIM_CONFIG_NUMBER_REFRESH,
    SIM_CONFIG_NUMBER_PARTIAL_SUCCESS,
    SIM_CONFIG_NO_PLANS_SELECTED,
  } = ERROR_TYPES;
  const { billingAccount } = useAppSelector((state) => state.cart);

  const simConfigError = useAppSelector(
    (state) => state.error.simConfigurationError
  );

  const { errorCode, errorDescription, errorType } = simConfigError || {};
  const refreshedPhonePlanId = useAppSelector(
    (state) => state.simConfiguration.refreshedPhonePlanId
  );
  const plansInfo: [] = useAppSelector(
    (state) => state.cart.normalizedPlansQBS
  );
  const planIndex =
    plansInfo.length &&
    plansInfo.findIndex(
      (currentPlan: NormalizedPlanQBS) =>
        currentPlan?.id === refreshedPhonePlanId
    );
  const plan = plansInfo.length && planIndex && plansInfo[planIndex];

  const [refreshNumber] = useLazyRefreshNumberQuery();
  return (
    <Grid>
      <Column cols={12} bsm={12} bmd={12} blg={12}>
        <Spacing top="spacing4x" bottom="spacing1x">
          <TextStyle alias="Label">
            {BILLING_ACCOUNT_CONTSTANTS.BILLING_ACCOUNT}
          </TextStyle>
        </Spacing>
        <Spacing bottom="spacing4x">
          <TextStyle alias="FinePrint" colour="Subtle">
            {BILLING_ACCOUNT_CONTSTANTS.BILLING_ACCOUNT_ASSISTIVE_TEXT}
          </TextStyle>
          <TextStyle alias="Label">{billingAccount}</TextStyle>
        </Spacing>

        <BulkConfig showLabel />

        {((errorCode === 500 && errorType === SIM_CONFIG_NUMBER_REFRESH) ||
          (errorCode === 200 &&
            errorType === SIM_CONFIG_NUMBER_PARTIAL_SUCCESS) ||
          errorType === SIM_CONFIG_NO_PLANS_SELECTED) && (
          <Spacing bottom="spacing2x">
            <Grid>
              <Column cols={6} bsm={6} bmd={6} blg={6}>
                <MessageSection
                  variant="Error"
                  developmentUrl={ableDevelopmentUrl}
                  description={errorDescription}
                  actionText={errorCode === 500 ? TRY_AGAIN : ""}
                  className="error-message-section"
                  actionCallback={(e) => {
                    e.preventDefault();
                    refreshPhoneNumber(
                      dispatch,
                      setRefreshedPhonePlanId,
                      refreshedPhonePlanId,
                      refreshNumber,
                      plan,
                      planIndex,
                      SIM_CONFIG_ERRORS
                    );
                  }}
                />
              </Column>
            </Grid>
          </Spacing>
        )}
        <SelectedPlansCount />
        <Spacing top="spacing2x">
          <NewSimConfigurationTable />
        </Spacing>
        <BulkConfig />
      </Column>
    </Grid>
  );
};

export default SIMConfiguration;
