import { baseApiService } from "./base";
import {
  createCartBody,
  generatePatchCartCancelDraftOrder,
} from "./templates/cart";
import { CONSTANTS } from "./constant";
import {
  setCart,
  savePatchCartResponse,
  resetDeltaPlans,
  setNormalizedDevicesAndAccesories,
  setNormalizedPlansQBS,
} from "@state/cart-slice";
import {
  CreateCart,
  CreateCartParams,
  PatchCart,
  ICancelDraftOrder,
  PatchCartParams,
  GetCartParams,
} from "@state/types";
import {
  getNormalizedDevicesAndAccessories,
  getNormalizedPlansQBS,
} from "@utils/cart";
import { setGlobalLoader } from "@state/common-slice";

export const cartApiService = baseApiService.injectEndpoints({
  endpoints: (builder) => ({
    createCart: builder.query<CreateCart, CreateCartParams>({
      query: (params) => ({
        url: "cart-management/carts",
        method: CONSTANTS.METHODS.POST,
        body: createCartBody(params),
      }),
    }),
    patchCart: builder.query<PatchCart, PatchCartParams>({
      query: (params) => ({
        url: `cart-management/carts/${params.payload.cartId}`,
        method: CONSTANTS.METHODS.PATCH,
        body: params.payload,
        headers: (() =>
          params.tapasAction
            ? {
                "TAPAS-ACTION": params.tapasAction,
              }
            : {})(),
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        dispatch(setGlobalLoader(true));
        try {
          const { data } = await queryFulfilled;
          dispatch(savePatchCartResponse(data));
          switch (params.product) {
            case "plan": {
              if (params.tapasAction !== "VS" && data.code !== 206) {
                dispatch(setNormalizedPlansQBS(getNormalizedPlansQBS(data)));
              }
              break;
            }
            case "device": {
              dispatch(
                setNormalizedDevicesAndAccesories(
                  getNormalizedDevicesAndAccessories(data)
                )
              );
              break;
            }
            case "all": {
              dispatch(setNormalizedPlansQBS(getNormalizedPlansQBS(data)));
              dispatch(
                setNormalizedDevicesAndAccesories(
                  getNormalizedDevicesAndAccessories(data)
                )
              );
              return;
            }
            default:
              break;
          }
          if (params.resetDelta) {
            dispatch(resetDeltaPlans());
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        } finally {
          dispatch(setGlobalLoader(false));
        }
      },
    }),
    patchCartCancelDraftOrder: builder.query<PatchCart, ICancelDraftOrder>({
      query: ({ cartId, cancellationReason }) => ({
        url: `cart-management/carts/${cartId}`,
        method: CONSTANTS.METHODS.PATCH,
        body: generatePatchCartCancelDraftOrder(cancellationReason),
        headers: {
          "TAPAS-ACTION": "CD",
        },
      }),
      async onQueryStarted(
        { cartId, cancellationReason },
        { dispatch, queryFulfilled }
      ) {
        const { data } = await queryFulfilled;
        if (data.responseCode === "SUCCESS") {
          dispatch(setCart({}));
        }
      },
    }),
    commonGetCart: builder.query<PatchCart, GetCartParams>({
      query: (params) => ({
        url: `cart-management/carts/${params.cartId}`,
        method: CONSTANTS.METHODS.GET,
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(savePatchCartResponse(data));
        dispatch(setNormalizedPlansQBS(getNormalizedPlansQBS(data)));
        dispatch(
          setNormalizedDevicesAndAccesories(
            getNormalizedDevicesAndAccessories(data)
          )
        );
      },
    }),
  }),
});

export const {
  useLazyCreateCartQuery,
  useLazyPatchCartQuery,
  useLazyPatchCartCancelDraftOrderQuery,
  useCommonGetCartQuery,
} = cartApiService;
