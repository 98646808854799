/* eslint-disable react-hooks/exhaustive-deps */
import { ActionButton, SectionHeader, Select, Spacing } from "@able/react";
import { ableDevelopmentUrl, renderLabel, renderText } from "@utils/helper";
import { CHECKOUT } from "../constant";
import { useGetRecommendedAddressesQuery } from "@services/base";
import { memo, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import {
  setDeliveryAddress,
  setDeliveryAddressRequired,
} from "@state/checkout-slice";

export default memo(function DeliveryDetails() {
  const dispatch = useAppDispatch();
  const { deliveryAddress } = useAppSelector((state) => state.checkout);

  const { data } = useGetRecommendedAddressesQuery();
  const addressOptions =
    data?.data?.address?.map((address) => {
      return {
        label: address.fullAddress,
        value: address.adborId,
      };
    }) || [];

  useEffect(() => {
    dispatch(setDeliveryAddressRequired(false));
  }, []);
  const addressDetails = (
    <>
      <Spacing bottom="spacing5x">
        <Select
          id="delivery-address"
          className="delivery-address-dropdown"
          label={CHECKOUT.DELIVERY_DETAILS.LABEL.DELIVERY_ADDRESS}
          developmentUrl={ableDevelopmentUrl}
          helpText={CHECKOUT.DELIVERY_DETAILS.LABEL.DELIVERY_ADDRESS_SUBTEXT}
          required={false}
          options={addressOptions}
          value={deliveryAddress.id}
          invalid={false}
          invalidMessage={CHECKOUT.DELIVERY_DETAILS.REQUIRED_FIELD}
          events={{
            onChange: (e) => {
              const { value, options } = e.target;
              dispatch(
                setDeliveryAddress({
                  id: value,
                  value: options[options.selectedIndex].innerText,
                })
              );
              dispatch(setDeliveryAddressRequired(false));
            },
          }}
        />
      </Spacing>
      {deliveryAddress.id && (
        <Spacing bottom="spacing5x">
          {renderLabel(CHECKOUT.DELIVERY_DETAILS.LABEL.DELIVER_TO)}
          {renderText(deliveryAddress.value)}
        </Spacing>
      )}
      <Spacing bottom="spacing5x">
        <ActionButton
          developmentUrl={ableDevelopmentUrl}
          element="button"
          label={`${
            deliveryAddress.id &&
            CHECKOUT.DELIVERY_DETAILS.LABEL.ADDRESS_NOT_RIGHT
          } ${CHECKOUT.DELIVERY_DETAILS.LABEL.ADD_ADDRESS}`}
          variant="LowEmphasis"
        />
      </Spacing>
    </>
  );
  const addAddress = (
    <>
      <Spacing bottom="spacing2x">
        {renderLabel(CHECKOUT.DELIVERY_DETAILS.LABEL.DELIVERY_ADDRESS)}
        {renderText(CHECKOUT.DELIVERY_DETAILS.LABEL.DELIVERY_ADDRESS_ADD_NEW)}
      </Spacing>
      <Spacing bottom="spacing5x">
        <ActionButton
          developmentUrl={ableDevelopmentUrl}
          element="button"
          label={CHECKOUT.DELIVERY_DETAILS.LABEL.ADD_NEW_ADDRESS}
          variant="MediumEmphasis"
        />
      </Spacing>
    </>
  );

  return (
    <div className="delivery-details">
      <SectionHeader
        title={CHECKOUT.DELIVERY_DETAILS.TITLE}
        variantLevel="Level2"
        headingElement="h2"
      />
      {addressOptions?.length ? addressDetails : addAddress}
    </div>
  );
});
