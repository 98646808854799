import "./style.scss";
import { ableDevelopmentUrl, renderLabel, renderText } from "@utils/helper";
import {
  TextStyle,
  Column,
  Spacing,
  Divider,
  Surface,
  ActionButton,
  Lozenge,
  InlineLink,
} from "@able/react";
import { DeviceProduct } from "@state/types";
import QuantityButton from "../../components/quantity-button";

export default function GridItem({ device }: { device: DeviceProduct }) {
  const topSection = (
    <>
      {renderLabel(device.manufacturer)}
      <TextStyle alias="HeadingD">{device.name}</TextStyle>
      <Spacing top="spacing1x" bottom="spacing3x">
        <Lozenge label="5G" variant="Neutral" />
      </Spacing>
      <TextStyle alias="Tag">{`SKU ${device.sku}`}</TextStyle>
      <Spacing bottom="spacingHalf" />
      <InlineLink>
        <TextStyle alias="Base">
          <a href="#">View details and features</a>
        </TextStyle>
      </InlineLink>
    </>
  );

  const midSection = (
    <>
      <div className="selected-color">
        {renderLabel("Select colour:")}
        <Spacing left="spacingHalf" />
        {renderText(device.color)}
      </div>
      <Spacing top="spacing1x" bottom="spacing3x" className={["color-options"]}>
        <div className="color-option" />
        <div className="color-option" />
        <div className="color-option" />
        <div className="color-option" />
      </Spacing>
      {renderLabel("Storage")}
      <Spacing top="spacing1x" className={["storage-options"]}>
        <button className="storage-option">{renderLabel("128GB")}</button>
        <button className="storage-option">{renderLabel("256GB")}</button>
        <button className="storage-option">{renderLabel("512GB")}</button>
      </Spacing>
    </>
  );

  const bottomSection = (
    <>
      {renderLabel("Outright price")}
      <Spacing top="spacing1x" />
      <TextStyle alias="HeadingD">{device.price.toString()}</TextStyle>
      <Spacing top="spacing1x" bottom="spacing2x" className={["stock-info"]}>
        <Lozenge label="In Stock" variant="PositiveEmphasis" />
        <Spacing left="spacing1x" />
        {renderText(device.stockAvailability)}
      </Spacing>
      <TextStyle alias="FinePrint">
        Device repayment options from $25.00 per month
      </TextStyle>
      <Spacing bottom="spacing3x" />
      {renderLabel("Enter quantity")}
      <Spacing top="spacing2x" className={["action-row"]}>
        <QuantityButton device={device} />
        <Spacing left="spacing3x" />
        <ActionButton
          developmentUrl={ableDevelopmentUrl}
          element="button"
          label="Add"
          variant="HighEmphasis"
        />
      </Spacing>
    </>
  );

  return (
    <Column cols={12} bsm={6} bmd={4} blg={3}>
      <Spacing bottom="spacing5x">
        <Surface
          variant="SurfaceFlat"
          cornerRadius="cornerRadiusSoft"
          background="materialBasePrimary"
        >
          <div className="surface-container">
            {topSection}
            <Spacing top="spacing3x" bottom="spacing3x">
              <Divider />
            </Spacing>
            {midSection}
            <Spacing top="spacing3x" bottom="spacing3x">
              <Divider />
            </Spacing>
            {bottomSection}
          </div>
        </Surface>
      </Spacing>
    </Column>
  );
}
