import {
  ActionButton,
  Help56,
  ModalContent,
  Spacing,
  TextStyle,
} from "@able/react";
import { NEW_SIM_CONFIG_TABLE } from "@pages/new-plan-order/constant";
import Selections from "@pages/new-plan-order/steps/configure-plan-barring-add-ons/new-plans-by-category/components/selections";
import { ableDevelopmentUrl } from "@utils/helper";

const BodyContent = ({ planDetails }) => {
  return (
    <Spacing top="spacing5x" bottom="spacing5x">
      <>
        <TextStyle alias="HeadingD">
          {NEW_SIM_CONFIG_TABLE.VIEW_DETAILS.ADDONS}
        </TextStyle>
        <Selections plan={planDetails._original} field="addOns" />
      </>
      <Spacing top="spacing2x">
        <TextStyle alias="HeadingD">
          {NEW_SIM_CONFIG_TABLE.VIEW_DETAILS.NETWORK_FEATURES}
        </TextStyle>
        <Selections plan={planDetails._original} field="barrings" />
      </Spacing>
    </Spacing>
  );
};

export default function ViewDetailsModal(props) {
  const { modalIsOpen, toggleModal, selectedPlanDetails } = props;

  return (
    <ModalContent
      developmentUrl={ableDevelopmentUrl}
      title={selectedPlanDetails.planName}
      variant={"Comfortable"}
      picto={<Help56 />}
      onClose={() => null}
      bodyContent={<BodyContent planDetails={selectedPlanDetails} />}
      footerContent={
        <ActionButton
          label={NEW_SIM_CONFIG_TABLE.VIEW_DETAILS.OK_BTN}
          variant="MediumEmphasis"
          element="button"
          events={{ onClick: () => toggleModal() }}
        />
      }
      isShowing={modalIsOpen}
      setHideDialog={toggleModal}
    />
  );
}
