import { IconButton } from "@able/react";
import { CART_DEVICES_TABLE } from "@pages/cart/constant";
import { ableDevelopmentUrl } from "@utils/helper";

const ActionColumn = ({ cellInfo, setDevicesToBeRemove, toggleModal }) => {
  return (
    <div className={`action-wrapper`}>
      <IconButton
        icon="Calendar"
        screenReaderContent={CART_DEVICES_TABLE.REMOVE_DEVICE}
        variant="Destructive"
        developmentUrl={ableDevelopmentUrl}
        size="Big"
        events={{
          onClick: (e) => {
            e.stopPropagation();
            const toDelete = new Set();
            if (cellInfo.original) {
              toDelete.add(cellInfo.original.id);
            } else {
              cellInfo.subRows.forEach((device) => {
                toDelete.add(device.id);
              });
            }
            setDevicesToBeRemove(toDelete);
            toggleModal();
          },
        }}
      />
    </div>
  );
};

export default ActionColumn;
