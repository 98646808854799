import "./style.scss";
import { TextField } from "@able/react";
import { ableDevelopmentUrl, REGULAR_EXPRESSIONS } from "@utils/helper";
import { useAppDispatch, useAppSelector } from "@state/hooks";
import { updateQBSPlansArrUsingIndex } from "@state/cart-slice";
import { NEW_SIM_CONFIG_TABLE } from "@pages/new-plan-order/constant";
import { useState } from "react";

export default function AddUsername({ planId, setId }) {
  const dispatch = useAppDispatch();
  const plansInfo = useAppSelector((state) => state.cart.normalizedPlansQBS);
  const planIndex = plansInfo.findIndex((plan) => plan.id === planId);
  const [fieldValue, setFieldValue] = useState(
    plansInfo[planIndex].setIds[setId].username
  );
  const [fieldInvalid, setFieldInvalid] = useState(
    plansInfo[planIndex].setIds[setId].invalid_username
  );

  const validateUsername = (e) => {
    const validUsername = REGULAR_EXPRESSIONS.USERNAME.test(fieldValue);
    const payload = {
      planIndexes: [planIndex],
      setIds: [setId],
      fields: {
        username: fieldValue,
        invalid_username: !validUsername,
      },
      isAnyFieldInSetInvalid: !validUsername,
    };
    dispatch(updateQBSPlansArrUsingIndex(payload));
  };

  const updateUsername = (e) => {
    setFieldValue(e.target.value);
    setFieldInvalid(false);
  };

  return (
    <TextField
      key={"username" + setId}
      id={"username" + setId}
      name={"username" + setId}
      className="username"
      developmentUrl={ableDevelopmentUrl}
      invalid={fieldInvalid}
      invalidInputText={NEW_SIM_CONFIG_TABLE.INVALID_USERNAME}
      value={fieldValue}
      events={{
        onBlur: (e) => {
          validateUsername(e);
        },
        onChange: (e) => {
          updateUsername(e);
        },
      }}
    />
  );
}
