import { Spacing, TextStyle } from "@able/react";
import ReviewCartPlansTable from "@pages/cart/plans-table-view";
import { CHECKOUT } from "@pages/checkout/constant";
import ReviewCartDevicesTable from "@pages/cart/devices-table-view";
import { useAppSelector } from "@state/hooks";
import ReviewCartChargesTable from "@pages/cart/charges-table-view";

export const YourItems = () => {
  const { normalizedDevicesAndAccesories, normalizedPlansQBS } = useAppSelector(
    (state) => state.cart
  );
  const isDevicesFlow = normalizedDevicesAndAccesories.length > 0;
  const isPlansFlow = normalizedPlansQBS.length > 0;

  return (
    <>
      {isPlansFlow && (
        <>
          <TextStyle alias="HeadingC" element="h3">
            {CHECKOUT.PLANS}
          </TextStyle>
          <Spacing top="spacing2x">
            <ReviewCartPlansTable />
          </Spacing>
          <Spacing top="spacing4x">
            <ReviewCartChargesTable />
          </Spacing>
        </>
      )}
      {isDevicesFlow && (
        <>
          <TextStyle alias="HeadingC" element="h3">
            {CHECKOUT.DEVICES}
          </TextStyle>
          <Spacing top="spacing2x">
            <ReviewCartDevicesTable />
          </Spacing>
          <Spacing top="spacing4x">
            <ReviewCartChargesTable />
          </Spacing>
        </>
      )}
    </>
  );
};
