import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOffer } from "./types";
import { resetAction } from "./reset";

export type ICartState = {
  selectedOffers: IOffer[];
};

const initialState: ICartState = {
  selectedOffers: [],
};

export const selectPlansSlice = createSlice({
  name: "select-plans",
  initialState,
  reducers: {
    updateMultipleOffers: (state, action) => {
      const selectedOffers = action.payload;
      state.selectedOffers.push(...selectedOffers);
      return state;
    },
    updateSelectedOffers: (
      state,
      action: PayloadAction<{ offer: IOffer; newQuantity: number }>
    ) => {
      const { offer, newQuantity } = action.payload;

      const index = state.selectedOffers.findIndex(
        (selectedOffer) => selectedOffer.id === offer.id
      );

      if (index === -1) {
        state.selectedOffers.push({ ...offer, quantity: newQuantity });
      } else {
        if (newQuantity === 0) {
          state.selectedOffers = state.selectedOffers.filter(
            (selectedOffer) => selectedOffer.id !== offer.id
          );
        } else {
          state.selectedOffers[index].quantity = newQuantity;
        }
      }

      return state;
    },
  },
  extraReducers(builder) {
    builder.addCase(resetAction, (state, action) => {
      return { ...initialState };
    });
  },
});

export const { updateMultipleOffers, updateSelectedOffers } =
  selectPlansSlice.actions;

export default selectPlansSlice.reducer;
