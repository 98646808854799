import "./style.scss";
import { Column, TextStyle, Spacing, Grid, Surface } from "@able/react";
import QuantityButton from "../../components/quantity-button";
import { NEW_PLAN_ORDER } from "@pages/new-plan-order/constant";
import { IOffer } from "@state/types";
import Inclusions from "../../components/inclusions";
import DataCost from "../../components/data-cost";

const ListSurface = ({ offer }: { offer: IOffer }) => {
  const { sharedDataPool, inclusions } = offer;

  return (
    <Spacing bottom="spacing3x">
      <Column vxs={12} bsm={12} bmd={12} blg={12} className="list-view-column">
        <Surface
          variant="SurfaceFlat"
          cornerRadius="cornerRadiusSoft"
          className="surface-container"
          background="materialBasePrimary"
        >
          <div
            className="surface-inner-container"
            data-testid="list-surface"
            id={`listViewSurface${offer.id}`}
            aria-label={offer.offerName}
          >
            <Grid>
              <Column vxs={12} bsm={9} bmd={7} blg={8}>
                <TextStyle alias="HeadingC" alignment="Left" element="span">
                  {offer.offerName}
                </TextStyle>
                <Spacing top="spacing3x">
                  <Grid>
                    <Column vxs={12} bsm={6} bmd={6} blg={6}>
                      <Inclusions inclusions={inclusions} />
                    </Column>
                    <Column vxs={12} bsm={6} bmd={6} blg={6}>
                      <Spacing bottom="spacing1x">
                        <TextStyle alias="Label">
                          {NEW_PLAN_ORDER.PLAN_SHARING}
                        </TextStyle>
                      </Spacing>
                      <Spacing top="spacing1x">
                        <TextStyle alias="Base">{sharedDataPool}</TextStyle>
                      </Spacing>
                    </Column>
                  </Grid>
                </Spacing>
              </Column>
              <Column
                vxs={12}
                bsm={3}
                bmd={5}
                blg={4}
                className="quantity-wrapper"
              >
                <Spacing bottom="spacing2x">
                  <DataCost offer={offer} view="list" />
                </Spacing>
                <Spacing top="spacing1x">
                  <QuantityButton offer={offer} />
                </Spacing>
              </Column>
            </Grid>
          </div>
        </Surface>
      </Column>
    </Spacing>
  );
};
export default ListSurface;
